import {
    AlertsState,
    AlertsActionTypes,
    ALERTS_LOAD_BEGIN,
    ALERTS_LOAD_ERROR,
    ALERTS_LOAD_SUCCESS,
    ALERTS_COMPLETE_BEGIN,
    ALERTS_COMPLETE_SUCCESS,
    ALERTS_COMPLETE_ERROR,
    ALERTS_REMEDIATE_SUCCESS,
    ALERTS_REMEDIATE_ERROR,
    FAIL_OPEN_CLOSE_ALERTS_LOAD_SUCCESS,
} from './types';

export const alertsInitialState: AlertsState = {
    processing: false,
    processingMessage: null,
    error: null,
    alerts: [],
    mode: undefined,
    canRemediateAll: false,
    remediated: false,
    isHostOutlook: false,
    themeColor: '',
    applicationSettings: null,
};

export const alertsReducer = (state: AlertsState = alertsInitialState, action: AlertsActionTypes): AlertsState => {
    switch (action.type) {
        case ALERTS_LOAD_BEGIN:
            return {
                ...state,
                processing: true,
                processingMessage: action.message,
                error: null,
                alerts: [],
                canRemediateAll: false,
            };
        case ALERTS_LOAD_SUCCESS:
            return {
                ...state,
                processing: false,
                processingMessage: null,
                error: null,
                mode: action.mode,
                alerts: [...action.alerts],
                canRemediateAll: action.canRemediateAll,
                isHostOutlook: action.isHostOutlook,
                themeColor: action.themeColor,
                applicationSettings: action.appSettings,
            };
        case FAIL_OPEN_CLOSE_ALERTS_LOAD_SUCCESS:
            return {
                ...state,
                themeColor: action.themeColor,
                isHostOutlook: action.isHostOutlook,
            };
        case ALERTS_LOAD_ERROR:
            return {
                ...state,
                processing: false,
                processingMessage: null,
                error: action.error,
                alerts: [],
                canRemediateAll: false,
            };
        case ALERTS_COMPLETE_BEGIN:
            return {
                ...state,
                error: null,
            };
        case ALERTS_COMPLETE_SUCCESS:
            return {
                ...state,
            };
        case ALERTS_COMPLETE_ERROR:
        case ALERTS_REMEDIATE_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case ALERTS_REMEDIATE_SUCCESS:
            return {
                ...state,
                remediated: true,
            };
        default:
            return state;
    }
};
