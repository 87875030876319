import React from 'react';
import { Stack, DefaultButton, PrimaryButton, TooltipHost, DirectionalHint } from '@fluentui/react';
import { AlertsCommandBarPropsOutlook, stackTokens } from './AlertsCommandBarProps';

const AlertsCommandBarOutlook = (props: AlertsCommandBarPropsOutlook) => {
    const { mode, canRemediateAll, completeAlerts, remediateAll, remediated, enableOk, appSettings } = props;
    const validationUI = appSettings?.PoliciesValidationUi;
    return (
        <div data-testid="alertscommandbaroutlook" style={{ marginBottom: '2px' }}>
            <Stack horizontal tokens={stackTokens}>
                {mode === 'WARN' && enableOk && (
                    <TooltipHost
                        content={validationUI?.DialogSendAnywayButtonTooltipText ?? 'Send Anyway'}
                        directionalHint={DirectionalHint.topCenter}
                    >
                        <DefaultButton
                            text={validationUI?.DialogSendAnywayButtonText ?? 'Send Anyway'}
                            onClick={() => completeAlerts(remediated ? 'DialogOkItemChanged' : 'DialogOkIdCached')}
                        />
                    </TooltipHost>
                )}
                <TooltipHost
                    content={validationUI?.DialogReturnButtonTooltipText ?? 'Return to message'}
                    directionalHint={DirectionalHint.topCenter}
                >
                    <PrimaryButton
                        text={validationUI?.DialogReturnButtonText ?? 'Return to message'}
                        onClick={() => completeAlerts('DialogCancel')}
                    />
                </TooltipHost>
                {canRemediateAll && (
                    <TooltipHost
                        content={validationUI?.DialogRemediateAllButtonTooltipText ?? 'Remediate All'}
                        directionalHint={DirectionalHint.topCenter}
                    >
                        <DefaultButton
                            text={validationUI?.DialogRemediateAllButtonText ?? 'Remediate All'}
                            onClick={() => remediateAll('DialogRemediateAll')}
                        />
                    </TooltipHost>
                )}
            </Stack>
        </div>
    );
};

export default AlertsCommandBarOutlook;
