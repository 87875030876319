import ClassificationPaneViewModel from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/ClassificationPaneViewModel';

export const READ_PANE_LOAD_BEGIN = 'READ_PANE_LOAD_BEGIN';
export const READ_PANE_LOAD_UPDATE_COUNT = 'READ_PANE_LOAD_UPDATE_COUNT';
export const READ_PANE_LOAD_SUCCESS = 'READ_PANE_LOAD_SUCCESS';
export const READ_PANE_LOAD_ERROR = 'READ_PANE_LOAD_ERROR';

export type ReadPaneLoadBeginAction = {
    type: typeof READ_PANE_LOAD_BEGIN;
};

export type ReadPaneLoadUpdateCountAction = {
    type: typeof READ_PANE_LOAD_UPDATE_COUNT;
    count: number;
};

export type ReadPaneLoadSuccessAction = {
    type: typeof READ_PANE_LOAD_SUCCESS;
    classificationPaneViewModel: ClassificationPaneViewModel;
};

export type ReadPaneLoadErrorAction = {
    type: typeof READ_PANE_LOAD_ERROR;
    error: string;
};

export type ReadPaneState = {
    isLoading: boolean;
    loadingFieldCount: number;
    error: string | null;
    classificationPaneViewModel: ClassificationPaneViewModel | null;
};

export type ReadPaneActionTypes =
    | ReadPaneLoadBeginAction
    | ReadPaneLoadUpdateCountAction
    | ReadPaneLoadSuccessAction
    | ReadPaneLoadErrorAction;
