import ActionHandlerFactory from 'titus-ts/dist/js/Util/Actions/ActionHandlerFactory';
import { ActionTypes } from 'titus-ts/dist/js/Util/Actions/ActionTypes';
import ActionHandler from 'titus-ts/dist/js/Util/Actions/ActionHandler';
import OfficeBodyTaggingHandler from './OfficeBodyTaggingHandler';
import { OfficeApi } from '../OfficeApi';
import OfficeSubjectLabelHandler from './OfficeSubjectLabelHandler';
import OfficeCustomXHeaderHandler from './OfficeCustomXHeaderHandler';
import RemediateRecipientAlerts from './RemediateRecipientAlerts';
import FieldCodeService from 'titus-ts/dist/js/Util/Actions/FieldCodeService';
import OfficeApplyHeaderFooterHandler from './OfficeApplyHeaderFooterHandler';
import OfficeCustomDocPropertyHandler from './OfficeCustomDocPropertyHandler';

export default class OfficeActionHandlerFactory implements ActionHandlerFactory {
    private officeApi: OfficeApi;
    private fieldCodeService: FieldCodeService;

    public constructor(officeApi: OfficeApi, fieldCodeService: FieldCodeService) {
        this.officeApi = officeApi;
        this.fieldCodeService = fieldCodeService;
    }

    public crateActionHandler = (actionType: ActionTypes): ActionHandler => {
        switch (actionType) {
            case 'BodyTag':
                return new OfficeBodyTaggingHandler(this.officeApi, this.fieldCodeService);
            case 'HTMLHeaderFooter':
                return new OfficeApplyHeaderFooterHandler(this.officeApi, this.fieldCodeService);
            case 'SubjectLabel':
                return new OfficeSubjectLabelHandler(this.officeApi, this.fieldCodeService);
            case 'CustomXHeader':
                return new OfficeCustomXHeaderHandler(this.officeApi, this.fieldCodeService);
            case 'RecipientAlert':
                return new RemediateRecipientAlerts(this.officeApi);
            case 'SetCustomProperties':
                return new OfficeCustomDocPropertyHandler(this.officeApi, this.fieldCodeService);
            default:
                throw new Error('Invalid action type');
        }
    };
}
