import React, { useState } from 'react';
import { AlertViewModel } from '../../store/alerts/types';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { DIALOG_REMEDIATE_TYPE } from '../../services/dialogService';
import { AnimationStyles, mergeStyles } from '@fluentui/react/lib/Styling';
import HtmlSanitizer from '../../office/validation/HtmlSanitizer';

type AlertProps = {
    alertViewModel: AlertViewModel;
    remediateAlerts: (actions: Action[], remediatetype: DIALOG_REMEDIATE_TYPE) => Promise<void>;
};

const Alert = (props: AlertProps) => {
    const { alertViewModel, remediateAlerts } = props;
    const [isExpanded, setIsExpanded] = useState(alertViewModel.isExpanded);
    const [isRemediable, setIsRemediable] = useState(alertViewModel.isRemediable);
    const sanitizedHtmlDescription = HtmlSanitizer.SanitizeHtml(alertViewModel.description ?? '');

    return (
        <div style={{ marginTop: '8px', marginBottom: '8px' }}>
            <div
                style={{
                    display: 'flex',
                    userSelect: 'none',
                    cursor: 'pointer',
                }}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div style={{ cursor: 'pointer', width: '16px', marginTop: '2px', marginRight: '8px' }}>
                    {isExpanded && <Icon iconName="ChevronDown" />}
                    {!isExpanded && <Icon iconName="ChevronRight" />}
                </div>
                <div style={{ fontSize: FontSizes.size16, color: NeutralColors.black, flex: 1 }}>
                    {alertViewModel.title}
                </div>
            </div>

            {isExpanded && (
                <div
                    className={mergeStyles(AnimationStyles.scaleUpIn100, {
                        fontSize: FontSizes.size14,
                        color: NeutralColors.black,
                        marginLeft: '24px',
                        marginTop: '8px',
                        marginBottom: '16px',
                    })}
                >
                    {<div dangerouslySetInnerHTML={{ __html: sanitizedHtmlDescription ?? '' }}></div>}
                </div>
            )}
            {isRemediable && (
                <div style={{ marginLeft: '24px', marginTop: '8px' }}>
                    <DefaultButton
                        text="Remove Recipients"
                        onClick={() => {
                            remediateAlerts(alertViewModel.alerts, 'DialogRemediate');
                            setIsRemediable(!isRemediable);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default Alert;
