import ClassificationPaneViewModel from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/ClassificationPaneViewModel';
import { ApplicationSettings } from '../../../node_modules/titus-ts/dist/js/Settings/ApplicationSettings';

export const SELECT_CLASSIFICATION_PROCESSING_BEGIN = 'SELECT_CLASSIFICATION_PROCESSING_BEGIN';
export const SELECT_CLASSIFICATION_LOAD_UPDATE_APPSETTINGS = 'SELECT_CLASSIFICATION_LOAD_UPDATE_APPSETTINGS';
export const SELECT_CLASSIFICATION_LOAD_SUGGEST_PREAMBLE = 'SELECT_CLASSIFICATION_LOAD_SUGGEST_PREAMBLE';
export const SELECT_CLASSIFICATION_LOAD_SUCCESS = 'SELECT_CLASSIFICATION_LOAD_SUCCESS';
export const SELECT_CLASSIFICATION_LOAD_ERROR = 'SELECT_CLASSIFICATION_LOAD_ERROR';
export const SELECT_CLASSIFICATION_CLEAR_ERROR = 'SELECT_CLASSIFICATION_CLEAR_ERROR';
export const SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_BEGIN = 'SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_BEGIN';
export const SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_SUCCESS = 'SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_SUCCESS';
export const SELECT_CLASSIFICATION_APPLY_METADATA_SUCCESS = 'SELECT_CLASSIFICATION_APPLY_METADATA_SUCCESS';
export const SELECT_CLASSIFICATION_APPLY_METADATA_ERROR = 'SELECT_CLASSIFICATION_APPLY_METADATA_ERROR';
export const SELECT_CLASSIFICATION_GENERAL_ERROR = 'SELECT_CLASSIFICATION_GENERAL_ERROR';

export type SelectClassificationProcessingBeginAction = {
    type: typeof SELECT_CLASSIFICATION_PROCESSING_BEGIN;
    message: string;
};

export type SelectClassificationLoadUpdateAppSettingsAction = {
    type: typeof SELECT_CLASSIFICATION_LOAD_UPDATE_APPSETTINGS;
    appSettings: ApplicationSettings;
};

export type SelectClassificationLoadSuggestPreambleAction = {
    type: typeof SELECT_CLASSIFICATION_LOAD_SUGGEST_PREAMBLE;
    preamble: string;
    themeColor: string;
};

export type SelectClassificationLoadSuccessAction = {
    type: typeof SELECT_CLASSIFICATION_LOAD_SUCCESS;
    classificationPaneViewModel: ClassificationPaneViewModel;
    themeColor: string;
};

export type SelectClassificationLoadErrorAction = {
    type: typeof SELECT_CLASSIFICATION_LOAD_ERROR;
    error: string;
};

export type SelectClassificationClearErrorAction = {
    type: typeof SELECT_CLASSIFICATION_CLEAR_ERROR;
};

export type SelectClassificationUpdateViewmodelBeginAction = {
    type: typeof SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_BEGIN;
};

export type SelectClassificationUpdateViewmodelSuccessAction = {
    type: typeof SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_SUCCESS;
    classificationPaneViewModel: ClassificationPaneViewModel;
};

export type SelectClassificationApplyMetadataSuccess = {
    type: typeof SELECT_CLASSIFICATION_APPLY_METADATA_SUCCESS;
};

export type SelectClassificationApplyMetadataError = {
    type: typeof SELECT_CLASSIFICATION_APPLY_METADATA_ERROR;
    error: string;
};

export type SelectClassificationGeneralErrorAction = {
    type: typeof SELECT_CLASSIFICATION_GENERAL_ERROR;
    error: string;
};

export type SelectClassificationState = {
    classificationPaneViewModel: ClassificationPaneViewModel | null;
    applicationSettings: ApplicationSettings | null;
    processing: boolean;
    processingMessage: string | null;
    error: string | null;
    suggestPreamble: string | null;
    themeColor: string;
};

export type SelectClassificationActionTypes =
    | SelectClassificationProcessingBeginAction
    | SelectClassificationLoadUpdateAppSettingsAction
    | SelectClassificationLoadSuggestPreambleAction
    | SelectClassificationLoadSuccessAction
    | SelectClassificationLoadErrorAction
    | SelectClassificationClearErrorAction
    | SelectClassificationUpdateViewmodelBeginAction
    | SelectClassificationUpdateViewmodelSuccessAction
    | SelectClassificationApplyMetadataSuccess
    | SelectClassificationApplyMetadataError
    | SelectClassificationGeneralErrorAction;

export const SELECT_CLASSIFICATION_MODE_PARAM = 'mode';

export type SELECT_CLASSIFICATION_FORCE = 'force';
export type SELECT_CLASSIFICATION_SUGGEST = 'suggest';

export type SELECT_CLASSIFICATION_MODE = SELECT_CLASSIFICATION_FORCE | SELECT_CLASSIFICATION_SUGGEST | null | undefined;
