import {
    SelectClassificationActionTypes,
    SELECT_CLASSIFICATION_PROCESSING_BEGIN,
    SELECT_CLASSIFICATION_LOAD_SUCCESS,
    SELECT_CLASSIFICATION_LOAD_ERROR,
    SELECT_CLASSIFICATION_CLEAR_ERROR,
    SELECT_CLASSIFICATION_LOAD_UPDATE_APPSETTINGS,
    SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_BEGIN,
    SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_SUCCESS,
    SELECT_CLASSIFICATION_APPLY_METADATA_SUCCESS,
    SELECT_CLASSIFICATION_APPLY_METADATA_ERROR,
    SELECT_CLASSIFICATION_MODE,
    SELECT_CLASSIFICATION_GENERAL_ERROR,
    SELECT_CLASSIFICATION_LOAD_SUGGEST_PREAMBLE,
} from './types';
import ClassificationPaneViewModel from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/ClassificationPaneViewModel';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { AnyAction } from 'redux';
import { ApplicationSettings } from 'titus-ts/dist/js/Settings/ApplicationSettings';
import { MetadataObject } from 'titus-ts/dist/js/Util/Metadata/MetadataObject';
import serviceContainer, { ApplicationMode } from '../../services/serviceContainer';
import { DialogMessages } from 'titus-ts/dist/js/Util/Policy/PolicyEvaluationBaseState';
import { LocalStorageService } from '../../services/storage/localStorageService';

export const selectClassificationProcessingBegin = (message: string): SelectClassificationActionTypes => {
    return {
        type: SELECT_CLASSIFICATION_PROCESSING_BEGIN,
        message: message,
    };
};

export const selectClassificationLoadSuccess = (
    classificationPaneViewModel: ClassificationPaneViewModel,
    themeColor: string,
): SelectClassificationActionTypes => {
    return {
        type: SELECT_CLASSIFICATION_LOAD_SUCCESS,
        classificationPaneViewModel: classificationPaneViewModel,
        themeColor: themeColor,
    };
};

export const selectClassificationLoadError = (error: string) => {
    return {
        type: SELECT_CLASSIFICATION_LOAD_ERROR,
        error: error,
    };
};

export const selectClassificationClearError = () => {
    return {
        type: SELECT_CLASSIFICATION_CLEAR_ERROR,
    };
};

export const selectClassificationLoadUpdateAppSettings = (appSettings: ApplicationSettings, themeColor: string) => {
    return {
        type: SELECT_CLASSIFICATION_LOAD_UPDATE_APPSETTINGS,
        appSettings: appSettings,
        themeColor: themeColor,
    };
};

export const selectClassificationLoadSuggestPreamble = (preamble: string, themeColor: string) => {
    return {
        type: SELECT_CLASSIFICATION_LOAD_SUGGEST_PREAMBLE,
        preamble: preamble,
        themeColor: themeColor,
    };
};

export const selectClassificationUpdateViewmodelBegin = (themeColor: string) => {
    return {
        type: SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_BEGIN,
        themeColor: themeColor,
    };
};

export const selectClassificationUpdateViewmodelSuccess = (
    classificationPaneViewModel: ClassificationPaneViewModel,
    themeColor: string,
) => {
    return {
        type: SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_SUCCESS,
        classificationPaneViewModel: classificationPaneViewModel,
        themeColor: themeColor,
    };
};

export const selectClassificationApplyMetadataSuccess = () => {
    return {
        type: SELECT_CLASSIFICATION_APPLY_METADATA_SUCCESS,
    };
};

export const selectClassificationApplyMetadataError = (error: string) => {
    return {
        type: SELECT_CLASSIFICATION_APPLY_METADATA_ERROR,
        error: error,
    };
};

export const selectClassificationGeneralError = (error: string) => {
    return {
        type: SELECT_CLASSIFICATION_GENERAL_ERROR,
        error: error,
    };
};

export const selectClassificationLoad =
    (mode: SELECT_CLASSIFICATION_MODE): ThunkAction<Promise<void>, AppState, undefined, AnyAction> =>
    async (dispatch) => {
        try {
            dispatch(selectClassificationProcessingBegin(''));
            const applicationMode: ApplicationMode = mode ? 'DIALOG_MODE' : 'EDIT_MODE';
            await serviceContainer.initialize(applicationMode);
            const { applicationSettingsService, renderingService, officeApi } = serviceContainer.getAllServices();
            const appSettings = await applicationSettingsService.getApplicationSettings();

            if (appSettings?.OnlineAddinSettings?.LoadingText) {
                dispatch(selectClassificationProcessingBegin(appSettings.OnlineAddinSettings.LoadingText));
            } else {
                dispatch(selectClassificationProcessingBegin('Loading...'));
            }

            const themeColor = officeApi.getPrimayBackgroundColor();
            dispatch(selectClassificationLoadUpdateAppSettings(appSettings, themeColor));

            if (mode === 'suggest') {
                const suggestPreamble = LocalStorageService.load<string>('POLICY_SUGGEST_PREAMBLE');
                dispatch(selectClassificationLoadSuggestPreamble(suggestPreamble, themeColor));
            }
            if (applicationMode === 'EDIT_MODE') {
                officeApi.onItemChanged(() => {
                    window.location.reload();
                });
            }
            const viewModel = await renderingService.getCurrentRenderingData();
            dispatch(selectClassificationLoadSuccess(viewModel, themeColor));
        } catch (err) {
            dispatch(selectClassificationLoadError((err as Error).message));
        }
    };

export const clearError = (): ThunkAction<void, AppState, undefined, AnyAction> => (dispatch) => {
    dispatch(selectClassificationClearError());
};

export const selectClassificationUpdateViewmodel =
    (
        changedFieldName: string,
        changedFieldValue: string | string[],
    ): ThunkAction<Promise<void>, AppState, undefined, AnyAction> =>
    async (dispatch) => {
        try {
            const { renderingService, officeApi } = serviceContainer.getAllServices();
            const themeColor = officeApi.getPrimayBackgroundColor();
            dispatch(selectClassificationUpdateViewmodelBegin(themeColor));
            const viewModel = await renderingService.transitionRendering(changedFieldName, changedFieldValue);
            dispatch(selectClassificationUpdateViewmodelSuccess(viewModel, themeColor));
        } catch (err) {
            dispatch(selectClassificationGeneralError((err as Error).message));
        }
    };

export const applyMetadata =
    (metadata: MetadataObject): ThunkAction<Promise<void>, AppState, undefined, AnyAction> =>
    async (dispatch) => {
        try {
            dispatch(selectClassificationProcessingBegin(''));
            const {
                metadataService,
                officeApi,
                policyService,
                renderingService,
                schemaProvider,
                applicationSettingsService,
            } = serviceContainer.getAllServices();

            const appSettings = await applicationSettingsService.getApplicationSettings();
            if (appSettings?.OnlineAddinSettings?.SavingText) {
                dispatch(selectClassificationProcessingBegin(appSettings.OnlineAddinSettings.SavingText));
            } else {
                dispatch(selectClassificationProcessingBegin('Saving...'));
            }
            schemaProvider.select(metadata);
            await policyService.evaluatePolicy();
            const viewModel = await renderingService.getCurrentRenderingData();
            dispatch(selectClassificationUpdateViewmodelSuccess(viewModel, ''));
            const newMetadata = schemaProvider.getMetadata();
            await metadataService.writeMetadata(newMetadata);
            await officeApi.saveAsync();
            dispatch(selectClassificationApplyMetadataSuccess());
        } catch (err) {
            dispatch(selectClassificationApplyMetadataError(err as string));
        }
    };

export const completeDialog =
    (message: DialogMessages, metadata: MetadataObject): ThunkAction<void, AppState, undefined, AnyAction> =>
    (dispatch) => {
        try {
            dispatch(selectClassificationClearError());
            const { officeApi } = serviceContainer.getAllServices();
            const jsonMessage = JSON.stringify({ type: message, metadata: metadata });
            officeApi.messageParent(jsonMessage);
        } catch (err) {
            dispatch(selectClassificationGeneralError(err as string));
        }
    };
