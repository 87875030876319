import React from 'react';
import { ApplicationSettings } from '../../../node_modules/titus-ts/dist/js/Settings/ApplicationSettings';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';
import { SELECT_CLASSIFICATION_MODE } from '../../store/select-classification/types';

type PreambleProps = {
    appSettings: ApplicationSettings | null;
    mode: SELECT_CLASSIFICATION_MODE;
    suggestPreamble: string | null;
    schemaFieldsExist: boolean;
};

const Preamble = (props: PreambleProps) => {
    const { appSettings, mode, suggestPreamble, schemaFieldsExist } = props;

    return (
        <div>
            <div
                style={{
                    margin: '32px 8px 16px 8px',
                    borderBottom: schemaFieldsExist ? '1px solid' : '',
                    borderBottomColor: NeutralColors.gray80,
                    fontSize: FontSizes.size14,
                }}
            >
                {mode === 'suggest' && suggestPreamble}
                {mode !== 'suggest' &&
                    schemaFieldsExist &&
                    appSettings &&
                    appSettings.MetadataSelectionUi &&
                    appSettings.MetadataSelectionUi.DialogPreamble}
            </div>
        </div>
    );
};

export default Preamble;
