import { HostType } from './HostType';
import { PlatformType } from './PlatformType';
import DocumentTypeEnum from 'titus-ts/dist/js/Office/DocumentTypeEnum';
import { NeutralColors } from '@uifabric/fluent-theme';

export class AddinApi {
    private excelThemeColor = 'rgb(33, 115, 70)';
    private wordThemeColor = 'rgba(24,90,189,255)';
    private outlookThemeColor = '#0078d4';
    private powerPointThemeColor = '#0078d4';
    private themeColor = NeutralColors.gray20;

    public getthemeColor = () => {
        return this.themeColor;
    };

    public getHost = (): HostType => {
        let hostType: HostType;
        const host = Office.context.host;
        if (host === Office.HostType.Word) {
            hostType = 'Word';
            this.themeColor = this.wordThemeColor;
        } else if (host === Office.HostType.Excel) {
            hostType = 'Excel';
            this.themeColor = this.excelThemeColor;
        } else if (host === Office.HostType.Outlook) {
            hostType = 'Outlook';
            this.themeColor = this.outlookThemeColor;
        } else if (host === Office.HostType.PowerPoint) {
            hostType = 'PowerPoint';
            this.themeColor = this.powerPointThemeColor;
        } else throw new Error('Unsupported environment');

        return hostType;
    };

    public getPlatformType = (): PlatformType | undefined => {
        let platformType: PlatformType | undefined;
        if (Office.context.diagnostics) {
            if (Office.context.diagnostics.platform === Office.PlatformType.OfficeOnline) {
                platformType = 'OfficeOnline';
            } else if (Office.context.diagnostics.platform === Office.PlatformType.PC) {
                platformType = 'PC';
            } else if (Office.context.diagnostics.platform === Office.PlatformType.Mac) {
                platformType = 'Mac';
            } else if (Office.context.diagnostics.platform === Office.PlatformType.Universal) {
                platformType = 'Universal';
            } else if (Office.context.diagnostics.platform === Office.PlatformType.iOS) {
                platformType = 'iOS';
            } else if (Office.context.diagnostics.platform === Office.PlatformType.Android) {
                platformType = 'Android';
            }
        }
        return platformType;
    };

    public showDialogAsync = (url: string): Promise<Office.Dialog> => {
        return new Promise<Office.Dialog>((resolve, reject) => {
            Office.context.ui.displayDialogAsync(url, { displayInIframe: true, width: 25, height: 50 }, (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve(result.value);
                } else {
                    reject('Unable to show dialog.');
                }
            });
        });
    };

    public getDocumentType = (): DocumentTypeEnum => {
        if (this.getHost() === 'Word') {
            return DocumentTypeEnum.Document;
        } else if (this.getHost() === 'Excel') {
            return DocumentTypeEnum.Spreadsheet;
        } else if (this.getHost() === 'PowerPoint') {
            return DocumentTypeEnum.Presentation;
        }
        return DocumentTypeEnum.Unknown;
    };

    public getFileName = (): Promise<string> => {
        return new Promise(async (resolve) => {
            const filePath = await this.getFilePath();
            const fileName = filePath.substr(filePath.lastIndexOf('\\') + 1);
            resolve(fileName);
        });
    };

    public getFilePath = (): Promise<string> => {
        return new Promise((resolve) => {
            Office.context.document.getFilePropertiesAsync(function (asyncResult) {
                const fileUrl = asyncResult.value.url;
                if (fileUrl) {
                    resolve(fileUrl);
                } else {
                    resolve('');
                }
            });
        });
    };
}
