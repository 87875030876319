import ItemValidationFactory from 'titus-ts/dist/js/Util/Policy/ItemValidatorFactory';
import ItemValidator from 'titus-ts/dist/js/Util/Policy/ItemValidator';
import { OfficeApi } from '../OfficeApi';
import EmailValidator from './EmailValidator';

export default class OfficeValidationFactory implements ItemValidationFactory {
    private officeApi: OfficeApi;

    public constructor(officeApi: OfficeApi) {
        this.officeApi = officeApi;
    }

    public getItemValidator = (): ItemValidator => {
        return new EmailValidator(this.officeApi);
    };
}
