import { readPaneReducer } from './read-pane/reducers';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { selectClassificationReducer } from './select-classification/reducers';
import { alertsReducer } from './alerts/reducers';

const rootReducer = combineReducers({
    readPaneReducer: readPaneReducer,
    selectClassificationReducer: selectClassificationReducer,
    alertsReducer: alertsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore(initialState: AppState) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
}
