import { OfficeApi } from '../office/OfficeApi';
import { PlatformType } from '../office/PlatformType';
import { ProductNames } from 'titus-ts/dist/js/MetadataRenderer/Schema/ProductNames';
import { EventType } from 'titus-ts/dist/js/Util/Policy/EventType';
import { ApplicationMode } from './serviceContainer';
import { localSettingsService } from './localSettingsService';

declare global {
    interface Document {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        documentMode?: any;
    }
}

export default class AppService {
    private officeApi: OfficeApi;

    public constructor(officeAPI?: OfficeApi) {
        this.officeApi = officeAPI || new OfficeApi();
    }

    public isPlatformEnabled = (): boolean => {
        const platformType: PlatformType | undefined = this.officeApi.getPlatformType();
        if (!platformType) return false;
        const host = this.officeApi.getHost();

        const enabledPlatformApplications = localSettingsService.getLocalSettings().enabledPlatformApplications;
        const enabledApplications = enabledPlatformApplications[platformType];
        if (!enabledApplications) return false;

        const isAppSupportedOnPlatform = enabledApplications.some((enabledAppName) => enabledAppName == host);
        const isBrowserSupported = this.isBrowserEnabled();

        const isEnabled = isAppSupportedOnPlatform && isBrowserSupported;
        return isEnabled;
    };

    public getProductName = (): ProductNames => {
        const host = this.officeApi.getHost();

        switch (host) {
            case 'Outlook': {
                return 'OutlookOnlineOfficeAddin';
            }
            case 'Word': {
                return 'WordOnlineOfficeAddin';
            }
            case 'Excel': {
                return 'ExcelOnlineOfficeAddin';
            }
            case 'PowerPoint': {
                return 'PowerPointOnlineOfficeAddin';
            }
            default: {
                throw new Error('Invalid host.');
            }
        }
    };

    public getEventName = (productName: ProductNames, applicationMode: ApplicationMode): EventType => {
        switch (productName) {
            case 'OutlookOnlineOfficeAddin': {
                switch (applicationMode) {
                    case 'ONSEND_MODE': {
                        return 'OnSend';
                    }
                    case 'DIALOG_MODE':
                    case 'EDIT_MODE':
                    case 'READ_MODE': {
                        return 'OnClassificationChange';
                    }
                    default: {
                        throw new Error('Invalid mode.');
                    }
                }
            }
            case 'WordOnlineOfficeAddin':
            case 'ExcelOnlineOfficeAddin':
            case 'PowerPointOnlineOfficeAddin': {
                return 'OnDocClassificationChange';
            }
            default: {
                throw new Error('Invalid Product.');
            }
        }
    };

    private isBrowserEnabled = (): boolean => {
        //Dummy window cast to surpress TypeScript warning
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isIE11 = !!(window as any).MSInputMethodContext && !!document.documentMode;
        return !isIE11;
    };
}

export const appService = new AppService();
