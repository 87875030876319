import DialogService from './dialogService';
import { TimeOutError } from '../office/errors/TimeOutError';

export class TimeOutService {
    private dialogService: DialogService;
    private timeoutHandle: NodeJS.Timeout | undefined;
    private timeoutDuration: number = 4 * 60 * 1000 + 30 * 1000;

    constructor(dialogService: DialogService) {
        this.dialogService = dialogService;
    }

    public subscribeTimeout = (reject: (reason?: TimeOutError) => void): void => {
        this.timeoutHandle = setTimeout(() => {
            this.dialogService.closeDialog();
            reject(new TimeOutError('User inactivity detected... Time out error'));
        }, this.timeoutDuration);
    };

    public cancelTimeout = (): void => {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
        }
    };
}
