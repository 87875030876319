import React from 'react';
import { ApplicationSettings } from 'titus-ts/dist/js/Settings/ApplicationSettings';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';

const stackTokens: IStackTokens = { childrenGap: 16 };

type DialogCommandBarProps = {
    onOk: () => void;
    onCancel: () => void;
    okEnabled: boolean;
    appSettings: ApplicationSettings;
};

const DialogCommandBar = (props: DialogCommandBarProps) => {
    const { onOk, onCancel, okEnabled, appSettings } = props;

    const okButtonText =
        appSettings.MetadataSelectionUi && appSettings.MetadataSelectionUi.DialogOkButtonText
            ? appSettings.MetadataSelectionUi.DialogOkButtonText
            : 'Ok';
    const cancelButtonText =
        appSettings.MetadataSelectionUi && appSettings.MetadataSelectionUi.DialogCancelButtonText
            ? appSettings.MetadataSelectionUi.DialogCancelButtonText
            : 'Cancel';

    return (
        <div data-testid="dialogcommandbar">
            <Stack horizontal tokens={stackTokens}>
                <PrimaryButton text={okButtonText} onClick={() => onOk()} disabled={!okEnabled} />
                <DefaultButton text={cancelButtonText} onClick={() => onCancel()} />
            </Stack>
        </div>
    );
};

export default DialogCommandBar;
