import { EventType } from 'titus-ts/dist/js/Util/Policy/EventType';
import { OfficeApi } from '../OfficeApi';
import { DocumentPolicyRequirementProps } from './PolicyRequirementsTypes';
import { PolicyServiceApi } from 'titus-ts/dist/js/Util/Network/PolicyServices/PolicyServiceApi';
import SchemaProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaProvider';
import { appService } from '../../services/appService';
import DocumentProperties from 'titus-ts/dist/js/Office/DocumentProperties';
import SchemaViewProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaViewProvider';
import OfficePolicyRequirementsProvider from './OfficePolicyRequirementsProvider';
import { MetadataObject } from 'titus-ts/dist/js/Util/Metadata/MetadataObject';

export class DocumentPolicyRequirementsProvider extends OfficePolicyRequirementsProvider {
    private schemaViewProvider: SchemaViewProvider;

    public constructor(
        officeApi: OfficeApi,
        schemaProvider: SchemaProvider,
        schemaViewProvider: SchemaViewProvider,
        policyServiceAPI: PolicyServiceApi,
    ) {
        super(officeApi, schemaProvider, policyServiceAPI);
        this.schemaViewProvider = schemaViewProvider;
    }

    public getRequiredPropertiesJson = async (eventType: EventType): Promise<string> => {
        await this.loadPolicyRequirements(this.policyServiceAPI);
        const documentProperties = await this.getDocumentProperties();
        const policyRequirementsProperties: DocumentPolicyRequirementProps = {
            Context: this.getContextProperties(eventType, appService, this.officeApi, this.productVersion),
            Environment: this.getEnviromentProperties(),
            Document: documentProperties,
        };
        return this.createPolicyEventRequiredFieldsJsonString(eventType, policyRequirementsProperties);
    };

    private getDocumentProperties = async (): Promise<DocumentProperties> => {
        const documentProperties: DocumentProperties = new DocumentProperties();
        const builtInProperties = await this.officeApi.getProperties();
        const schemaFieldsNames = this.schemaViewProvider.getSchemaFieldsNames();
        const customProperties = await this.officeApi.getCustomProperties(schemaFieldsNames);
        const customPropertiesString = this.convertToString(customProperties);
        const properties = [
            (documentProperties.Body = await this.officeApi.getBodyAsTextAsync()),
            (documentProperties.BuiltInProperties = {
                Author: builtInProperties.Author,
                Category: builtInProperties.Category,
                Comments: builtInProperties.Comments,
                CreationDate: builtInProperties.CreationDate,
                Keywords: builtInProperties.Keywords,
                LastAuthor: builtInProperties.LastAuthor,
                LastPrintDate: builtInProperties.LastPrintDate,
                LastSaveTime: builtInProperties.LastSaveTime,
                RevisionNumber: builtInProperties.RevisionNumber,
                Subject: builtInProperties.Subject,
                Template: builtInProperties.Template,
                Title: builtInProperties.Title,
            }),
            (documentProperties.CustomDocumentProperties = customPropertiesString),
            (documentProperties.DocumentType = this.officeApi.getDocumentType()),
            (documentProperties.Filename = await this.officeApi.getFileName()),
            (documentProperties.Footer = await this.officeApi.getFooterContent()),
            (documentProperties.Header = await this.officeApi.getHeaderContent()),
            (documentProperties.IsANewDocument = builtInProperties.LastSaveTime === undefined ? true : false),
            (documentProperties.IsSaved = builtInProperties.LastSaveTime !== undefined ? true : false),
            (documentProperties.LastSaved = builtInProperties.LastSaveTime),
            (documentProperties.Path = await this.officeApi.getFilePath()),
            (documentProperties.TemplateName = builtInProperties.Template),
        ];
        await Promise.all(properties);
        documentProperties.Metadata = this.mapMetadata(this.schemaProvider.getMetadata());
        documentProperties.OriginalMetadata = this.mapMetadata(this.schemaProvider.getOriginalMetadata());
        documentProperties.HasMetadata = documentProperties.Metadata ? true : false;
        return documentProperties;
    };

    private convertToString = (customProperties: MetadataObject | undefined): string => {
        let customPropertiesString = '';
        if (customProperties) {
            customPropertiesString = Object.entries(customProperties)
                .map((x) => x.join(': '))
                .join(', ');
        }
        return customPropertiesString;
    };
}
