import React, { useEffect } from 'react';
import { Shimmer } from '@fluentui/react/lib/Shimmer';
import FieldShimmer from './FieldShimmer';
import { AppState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { readPaneLoad } from '../../store/read-pane/actions';
import { connect } from 'react-redux';
import ReadField from './ReadField';
import ClassificationPaneViewModel from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/ClassificationPaneViewModel';
import ApplicationError from '../shared/ApplicationError';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';

type ReadPaneProps = {
    loadingFieldCount: number;
    isLoading: boolean;
    error: string | null;
    classificationPaneViewModel: ClassificationPaneViewModel | null;
};

type ReadPaneDispatchProps = {
    readPaneLoad: () => Promise<void>;
};

export const ReadPane = (props: ReadPaneProps & ReadPaneDispatchProps) => {
    const { loadingFieldCount, isLoading, error, readPaneLoad, classificationPaneViewModel } = props;
    useEffect(() => {
        readPaneLoad();
    }, [readPaneLoad]);

    return (
        <div style={{ margin: 16, borderTop: '1px solid', borderTopColor: NeutralColors.gray80 }}>
            <div style={{ margin: '32px 8px' }}>
                <Shimmer
                    customElementsGroup={<FieldShimmer fieldCount={loadingFieldCount} />}
                    isDataLoaded={!isLoading}
                >
                    {error && <ApplicationError error={error} />}
                    {classificationPaneViewModel &&
                        classificationPaneViewModel.fields.map(
                            (f, index) =>
                                f.selectedValue && (
                                    <ReadField
                                        key={'fld_' + index}
                                        name={f.displayText}
                                        fieldTooltip={f.tooltip}
                                        value={f.selectedValue
                                            .map((selectedValueName) => {
                                                const value = f.values.find((value) => value.name == selectedValueName);
                                                return value ? value.displayText : selectedValueName;
                                            })
                                            .join('; ')}
                                        valueTooltip={f.values
                                            .filter((value) => f.selectedValue?.includes(value.name))
                                            .map((selectedValue) => selectedValue.tooltip)
                                            .join('; ')}
                                    />
                                ),
                        )}
                </Shimmer>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState): ReadPaneProps => ({
    loadingFieldCount: state.readPaneReducer.loadingFieldCount,
    isLoading: state.readPaneReducer.isLoading,
    error: state.readPaneReducer.error,
    classificationPaneViewModel: state.readPaneReducer.classificationPaneViewModel,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, undefined, AnyAction>) => ({
    readPaneLoad: () => dispatch(readPaneLoad()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReadPane);
