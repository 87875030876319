import { default as defaultLocalSettings } from '../defaultLocalSettings.json';
import { PlatformType } from '../office/PlatformType';
import { HostType } from '../office/HostType';

class LocalSettingsService {
    private localSettings: LocalSettings;
    private defaultSettings: LocalSettings;
    private fetchedSettings: Partial<LocalSettings> | null = null;
    private loadPromise: Promise<void> | null = null;

    constructor() {
        this.localSettings = this.defaultSettings = defaultLocalSettings as LocalSettings;
    }

    async loadLocalSettings(forceRefresh = false) {
        if (forceRefresh) {
            this.loadPromise = null;
            this.fetchedSettings = null;
        }

        // If the loadPromise exists, return it instead of creating a new fetch request
        if (this.loadPromise) {
            return this.loadPromise;
        }

        this.loadPromise = (async () => {
            try {
                const response = await fetch(`/localSettings.json`);
                this.fetchedSettings = await response.json();
                this.localSettings = { ...this.defaultSettings, ...this.fetchedSettings };
            } catch (error) {
                console.error('Failed to load app settings:', error);
                // Reset loadPromise to null so future calls can attempt to load settings again
                this.loadPromise = null;
                throw error; // Rethrow the error to handle it in the calling code if necessary
            }
        })();

        return this.loadPromise;
    }

    // method to override settings in unit tests
    overrideLocalSettings(localSettingsOverride?: Partial<LocalSettings>) {
        this.localSettings = { ...this.defaultSettings, ...this.fetchedSettings, ...localSettingsOverride };
    }

    getLocalSettings() {
        if (!this.fetchedSettings) {
            console.warn('Settings were not loaded so default values will be used.');
        }
        return this.localSettings;
    }
}

export interface LocalSettings {
    hostUrl: string;
    clientId: string;
    useLocalHostUrl: boolean;
    enabledPlatformApplications: enabledPlatformApplication;
    officeJsLocation: string;
    officeJsSriHash?: string;
}

export type enabledPlatformApplication = {
    [key in PlatformType]?: HostType[] | undefined;
};

export const localSettingsService = new LocalSettingsService();
