import {
    AlertsActionTypes,
    ALERTS_LOAD_BEGIN,
    AlertViewModel,
    ALERT_MODE,
    ALERTS_LOAD_SUCCESS,
    ALERTS_LOAD_ERROR,
    ALERTS_COMPLETE_BEGIN,
    ALERTS_COMPLETE_SUCCESS,
    ALERTS_COMPLETE_ERROR,
    ALERTS_REMEDIATE_SUCCESS,
    ALERTS_REMEDIATE_ERROR,
    FAIL_OPEN_CLOSE_ALERTS_LOAD_SUCCESS,
} from './types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';
import { AnyAction } from 'redux';
import serviceContainer from '../../services/serviceContainer';
import { LocalStorageService } from '../../services/storage/localStorageService';
import { SerializableAction } from '../../services/storage/storageTypes';
import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { AlertFactory } from '../../services/alertFactory';
import { DIALOG_REMEDIATE_TYPE } from '../../services/dialogService';
import { DialogMessages } from 'titus-ts/dist/js/Util/Policy/PolicyEvaluationBaseState';
import FieldCodeService from 'titus-ts/dist/js/Util/Actions/FieldCodeService';
import { ApplicationSettings } from 'titus-ts/dist/js/Settings/ApplicationSettings';
import { AppSettingsService } from '../../services/appSettingsService';

export const alertsLoadBegin = (message: string): AlertsActionTypes => {
    return {
        type: ALERTS_LOAD_BEGIN,
        message: message,
    };
};

export const alertsLoadSuccess = (
    alerts: AlertViewModel[],
    mode: ALERT_MODE,
    canRemediateAll: boolean,
    isHostOutlook: boolean,
    themeColor: string,
    appSettings: ApplicationSettings | null,
): AlertsActionTypes => {
    return {
        type: ALERTS_LOAD_SUCCESS,
        alerts: alerts,
        mode: mode,
        canRemediateAll: canRemediateAll,
        isHostOutlook: isHostOutlook,
        themeColor: themeColor,
        appSettings: appSettings,
    };
};

export const failOpenCloseAlertsLoadSuccess = (themeColor: string, ishostOutlook: boolean): AlertsActionTypes => {
    return {
        type: FAIL_OPEN_CLOSE_ALERTS_LOAD_SUCCESS,
        isHostOutlook: ishostOutlook,
        themeColor: themeColor,
    };
};

export const alertsLoadError = (error: string): AlertsActionTypes => {
    return {
        type: ALERTS_LOAD_ERROR,
        error: error,
    };
};

export const alertsCompleteBegin = (): AlertsActionTypes => {
    return {
        type: ALERTS_COMPLETE_BEGIN,
    };
};

export const alertsCompleteSuccess = (): AlertsActionTypes => {
    return {
        type: ALERTS_COMPLETE_SUCCESS,
    };
};

export const alertsCompleteError = (error: string): AlertsActionTypes => {
    return {
        type: ALERTS_COMPLETE_ERROR,
        error: error,
    };
};

export const remediateSuccess = (): AlertsActionTypes => {
    return {
        type: ALERTS_REMEDIATE_SUCCESS,
    };
};

export const remediateError = (error: string): AlertsActionTypes => {
    return {
        type: ALERTS_REMEDIATE_ERROR,
        error: error,
    };
};

export const loadAlerts = (): ThunkAction<Promise<void>, AppState, undefined, AnyAction> => async (dispatch) => {
    try {
        dispatch(alertsLoadBegin(''));
        await serviceContainer.initialize('DIALOG_MODE');
        const serializedActions = LocalStorageService.load<SerializableAction[]>('POLICY_ALERTS');
        const alerts: Action[] = AlertFactory.deserializeActions(serializedActions);
        const fieldCodeService: FieldCodeService = serviceContainer.getService('FieldCodeService');
        const vms = AlertFactory.getAlertViewModels(alerts, fieldCodeService);
        const denyAction = alerts.find((alert) => alert.type === 'Deny');
        const mode: ALERT_MODE = denyAction ? 'PREVENT' : 'WARN';
        const canRemediateAll = vms.find((vm) => vm.type === 'RecipientAlert');
        const { officeApi } = serviceContainer.getPreloadServices();
        const ishostOutlook = officeApi.getHost() === 'Outlook' ? true : false;
        const themeColor = officeApi.getPrimayBackgroundColor();
        await serviceContainer.registerAppSettings();
        const applicationSettingsService = serviceContainer.getService<AppSettingsService>('ApplicationSettings');
        const appSettings = await applicationSettingsService.getApplicationSettings();
        if (appSettings?.OnlineAddinSettings?.LoadingText) {
            dispatch(alertsLoadBegin(appSettings.OnlineAddinSettings.LoadingText));
        } else {
            dispatch(alertsLoadBegin('Loading...'));
        }
        dispatch(alertsLoadSuccess(vms, mode, !!canRemediateAll, ishostOutlook, themeColor, appSettings));
    } catch (err) {
        dispatch(alertsLoadError(err as string));
    }
};

export const loadFailOpenCloseAlert =
    (): ThunkAction<Promise<void>, AppState, undefined, AnyAction> => async (dispatch) => {
        try {
            dispatch(alertsLoadBegin('Loading...'));
            const { officeApi } = serviceContainer.getPreloadServices();
            const ishostOutlook = officeApi.getHost() === 'Outlook' ? true : false;
            const themeColor = officeApi.getPrimayBackgroundColor();
            dispatch(failOpenCloseAlertsLoadSuccess(themeColor, ishostOutlook));
        } catch (err) {
            dispatch(alertsLoadError(err as string));
        }
    };

export const completeAlerts =
    (message: DialogMessages): ThunkAction<Promise<void>, AppState, undefined, AnyAction> =>
    async (dispatch) => {
        try {
            dispatch(alertsCompleteBegin());
            const { officeApi } = serviceContainer.getPreloadServices();
            officeApi.messageParent(JSON.stringify({ type: message }));
            dispatch(alertsCompleteSuccess());
        } catch (err) {
            dispatch(alertsCompleteError(err as string));
        }
    };

export const remediateAlerts =
    (
        actions: Action[],
        remediatetype: DIALOG_REMEDIATE_TYPE,
    ): ThunkAction<Promise<void>, AppState, undefined, AnyAction> =>
    async (dispatch) => {
        try {
            const { officeApi } = serviceContainer.getPreloadServices();
            const actionIds: string[] = [];
            actions.forEach((action) => {
                if (actionIds.indexOf(action.id) === -1) {
                    actionIds.push(action.id);
                }
            });
            officeApi.messageParent(JSON.stringify({ type: remediatetype, actionIds: actionIds }));
            dispatch(remediateSuccess());
        } catch (err) {
            dispatch(remediateError(err as string));
        }
    };
