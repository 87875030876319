import { OfficeApi } from '../office/OfficeApi';
import MetadataService from './metadataService';
import { OutlookMetadataService } from './outlookMetadataService';
import { DocumentMetadataService } from './documentMetadataService';
import SchemaFieldsNamesProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaFieldsNamesProvider';
import { GuidService } from './guidService';

export default class MetadataServiceFactory {
    private officeApi: OfficeApi;
    private schemaField: string[];
    private guidService: GuidService;

    constructor(officeApi: OfficeApi, schemaFieldsNamesProvider: SchemaFieldsNamesProvider, guidService: GuidService) {
        this.officeApi = officeApi;
        this.schemaField = schemaFieldsNamesProvider.getSchemaFieldsNames();
        this.guidService = guidService;
    }

    public getMetadataServiceInstance = (): MetadataService => {
        let metadataService: MetadataService;
        switch (this.officeApi.getHost()) {
            case 'Outlook':
                metadataService = new OutlookMetadataService(this.officeApi);
                break;
            case 'Excel':
            case 'Word':
            case 'PowerPoint':
                metadataService = new DocumentMetadataService(this.officeApi, this.schemaField, this.guidService);
                break;
        }
        return metadataService;
    };
}
