import { v4 as uuidv4 } from 'uuid';
import { OfficeApi } from '../office/OfficeApi';
import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';

export class GuidService {
    private officeApi: OfficeApi;
    private readonly GUID_KEY: string = 'TitusGUID';

    public constructor(officeApi: OfficeApi) {
        this.officeApi = officeApi;
    }

    public getTitusGuid = async (): Promise<string | undefined> => {
        const customProperties = await this.officeApi.getCustomProperties([this.GUID_KEY]);
        if (customProperties && customProperties[this.GUID_KEY]) {
            return customProperties[this.GUID_KEY] as string;
        }
        return undefined;
    };

    public writeTitusGuid = async (): Promise<void> => {
        const currentGuid = await this.getTitusGuid();
        if (!currentGuid) {
            const guid = this.getNewGuid();
            await this.officeApi.setCustomProperties(guid, []);
        }
    };

    private getNewGuid = (): DictionaryInterface<string> => {
        const guidDictionary: DictionaryInterface<string> = {};
        const guid = uuidv4();
        guidDictionary[this.GUID_KEY] = guid;
        return guidDictionary;
    };
}
