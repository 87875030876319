import { ApplicationSettings } from 'titus-ts/dist/js/Settings/ApplicationSettings';
import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { ActionTypes } from 'titus-ts/dist/js/Util/Actions/ActionTypes';

export const ALERTS_LOAD_BEGIN = 'ALERTS_LOAD_BEGIN';
export const ALERTS_LOAD_SUCCESS = 'ALERTS_LOAD_SUCCESS';
export const FAIL_OPEN_CLOSE_ALERTS_LOAD_SUCCESS = 'FAIL_OPEN_CLOSE_ALERTS_LOAD_SUCCESS';
export const ALERTS_LOAD_ERROR = 'ALERTS_LOAD_ERROR';
export const ALERTS_COMPLETE_BEGIN = 'ALERTS_COMPLETE_BEGIN';
export const ALERTS_COMPLETE_SUCCESS = 'ALERTS_COMPLETE_SUCCESS';
export const ALERTS_COMPLETE_ERROR = 'ALERTS_COMPLETE_ERROR';
export const ALERTS_REMEDIATE_SUCCESS = 'ALERTS_REMEDIATE_SUCCESS';
export const ALERTS_REMEDIATE_ERROR = 'ALERTS_REMEDIATE_ERROR';

export type ALERT_MODE_PREVENT = 'PREVENT';
export type ALERT_MODE_WARN = 'WARN';
export type ALERT_MODE = ALERT_MODE_PREVENT | ALERT_MODE_WARN;

export const ALERTS_OK_PARAM = 'enableOk';

export type AlertsLoadBeginAction = {
    type: typeof ALERTS_LOAD_BEGIN;
    message: string;
};

export type AlertsLoadSuccessAction = {
    type: typeof ALERTS_LOAD_SUCCESS;
    alerts: AlertViewModel[];
    mode: ALERT_MODE;
    canRemediateAll: boolean;
    isHostOutlook: boolean;
    themeColor: string;
    appSettings: ApplicationSettings | null;
};

export type FailOpenCloseAlertsLoadSuccessAction = {
    type: typeof FAIL_OPEN_CLOSE_ALERTS_LOAD_SUCCESS;
    themeColor: string;
    isHostOutlook: boolean;
};

export type AlertsLoadErrorAction = {
    type: typeof ALERTS_LOAD_ERROR;
    error: string;
};

export type AlertsCompleteBeginAction = {
    type: typeof ALERTS_COMPLETE_BEGIN;
};

export type AlertsCompleteSuccessAction = {
    type: typeof ALERTS_COMPLETE_SUCCESS;
};

export type AlertsCompleteErrorAction = {
    type: typeof ALERTS_COMPLETE_ERROR;
    error: string;
};

export type AlertsRemediateSuccessAction = {
    type: typeof ALERTS_REMEDIATE_SUCCESS;
};

export type AlertsRemediateErrorAction = {
    type: typeof ALERTS_REMEDIATE_ERROR;
    error: string;
};

export type AlertsActionTypes =
    | AlertsLoadBeginAction
    | AlertsLoadSuccessAction
    | FailOpenCloseAlertsLoadSuccessAction
    | AlertsLoadErrorAction
    | AlertsCompleteBeginAction
    | AlertsCompleteSuccessAction
    | AlertsCompleteErrorAction
    | AlertsRemediateSuccessAction
    | AlertsRemediateErrorAction;

export type AlertsState = {
    processing: boolean;
    processingMessage: string | null;
    error: string | null;
    alerts: AlertViewModel[];
    mode: ALERT_MODE | undefined;
    canRemediateAll: boolean;
    remediated: boolean;
    isHostOutlook: boolean;
    themeColor: string;
    applicationSettings: ApplicationSettings | null;
};

export type AlertViewModel = {
    isExpanded: boolean;
    isRemediable: boolean;
    title: string | undefined;
    type: ActionTypes;
    description: string | undefined;
    alerts: Action[];
};
