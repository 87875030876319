import React, { useState } from 'react';
import Field from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/Field';
import { DefaultButton, DirectionalHint, Stack, TooltipHost } from '@fluentui/react';

type OneClickValuePickerProps = {
    field: Field;
    onChange: (fieldName: string, oneClick: string | string[]) => void;
};

type OneClickValue = {
    schemaValueName: string;
    displayText: string;
};

const itemsPerRow = 4;

export const ValuePickerOneClick = (props: OneClickValuePickerProps) => {
    const { field, onChange } = props;
    const [selectedButton, setSelectedButton] = useState(field.selectedValue ? field.selectedValue[0] : '');

    if (!field.oneClick || !field.oneClick.valuesPositions) {
        return <div></div>;
    }

    //sort valid one click values in to desired rows
    const valueRows: (OneClickValue | undefined | null)[][] = [];
    for (const cell of Object.entries(field.oneClick.valuesPositions)) {
        const num = parseInt(cell[0]);
        if (!isNaN(num)) {
            if (field.values.find((v) => v.name === cell[1].schemaValueName)) {
                const row = Math.floor(num / itemsPerRow);
                if (!valueRows[row]) {
                    valueRows[row] = [];
                }
                valueRows[row].push(cell[1]);
            }
        }
    }

    const handleButtonClick = (schemaValue: string) => {
        if (selectedButton != schemaValue) {
            setSelectedButton(schemaValue);
            onChange(field.name, schemaValue);
        } else {
            setSelectedButton('');
            onChange(field.name, '');
        }
    };

    const getIconProps = (schemaValueName: string) => {
        const schemaValue = field.values.find((value) => value.name === schemaValueName);
        if (schemaValue && schemaValue.colorCode) {
            const iconProps = {
                iconName: 'CheckboxFill',
                style: {
                    color: schemaValue.colorCode,
                    fontSize: '10px',
                    margin: '0px',
                    paddingLeft: '2px',
                },
                'data-testid': 'ic-' + schemaValueName,
            };
            return iconProps;
        }
        return undefined;
    };

    const getTooltip = (schemaValueName: string, fallBackText: string) => {
        const schemaValue = field.values.find((value) => value.name === schemaValueName);
        if (schemaValue && schemaValue.tooltip) {
            return schemaValue.tooltip;
        }
        return fallBackText;
    };

    return (
        <div data-testid="oneclick_valuepicker">
            <Stack>
                {valueRows.map((row, rowIndex) => (
                    <div data-testId={'ocRow-' + rowIndex} key={rowIndex} style={{ whiteSpace: 'nowrap' }}>
                        {row.map((value) =>
                            value ? (
                                <TooltipHost
                                    key={value.schemaValueName}
                                    content={getTooltip(value.schemaValueName, value.displayText)}
                                    calloutProps={{ gapSpace: 0 }}
                                    directionalHint={DirectionalHint.topCenter}
                                >
                                    <DefaultButton
                                        className={selectedButton === value.schemaValueName ? 'oc-select' : ''}
                                        onClick={() => handleButtonClick(value.schemaValueName)}
                                        data-testid={'oc-' + value.schemaValueName}
                                        styles={{
                                            root: {
                                                margin: '4px 2px 0 0',
                                                width: `calc(100% / ${itemsPerRow})`,
                                                minWidth: '10px',
                                                padding: '2px',
                                                overflow: 'hidden',
                                                backgroundColor:
                                                    selectedButton === value.schemaValueName ? 'lightgrey' : 'white',
                                            },
                                            textContainer: { overflow: 'hidden' },
                                            label: {
                                                textAlign: 'left',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            },
                                        }}
                                        iconProps={getIconProps(value.schemaValueName)}
                                        text={value.displayText || ' '}
                                        //no icon alignment so blank space pushes icon to the right when text is empty.
                                    />
                                </TooltipHost>
                            ) : (
                                <></>
                            ),
                        )}
                    </div>
                ))}
            </Stack>
        </div>
    );
};

export default ValuePickerOneClick;
