import PolicyEvaluationService from 'titus-ts/dist/js/Util/Policy/PolicyEvaluationService';
import PolicyEvaluationResult from 'titus-ts/dist/js/Util/Actions/PolicyEvaluationResult';
import DialogService from './dialogService';
import { STATE_PARAMS } from 'titus-ts/dist/js/Util/Policy/PolicyEvaluationBaseState';
import { TimeOutService } from './timeOutService';

export class PolicyService {
    private timeOutService: TimeOutService;
    private policyEvaluationService: PolicyEvaluationService;
    private dialogService: DialogService;

    public constructor(
        timeOutService: TimeOutService,
        policyEvaluationService: PolicyEvaluationService,
        dialogService: DialogService,
    ) {
        this.timeOutService = timeOutService;
        this.policyEvaluationService = policyEvaluationService;
        this.dialogService = dialogService;
    }

    public evaluatePolicy = async (): Promise<PolicyEvaluationResult> => {
        return new Promise<PolicyEvaluationResult>(async (resolve, reject) => {
            try {
                let result: PolicyEvaluationResult | undefined;
                let stateParams: Map<STATE_PARAMS, string> | undefined;

                if (this.policyEvaluationService.getEventType() === 'OnSend') {
                    this.timeOutService.subscribeTimeout(reject);
                }
                this.policyEvaluationService.resetState();

                while (!result || (result.status !== 'COMPLETED' && result.status !== 'BLOCKED')) {
                    result = await this.policyEvaluationService.evaluate(stateParams);
                    stateParams = await this.getUserInput(result);
                }
                resolve(result);
            } catch (err) {
                reject(err);
            } finally {
                this.timeOutService.cancelTimeout();
            }
        });
    };

    private getUserInput = async (result: PolicyEvaluationResult): Promise<Map<STATE_PARAMS, string> | undefined> => {
        let stateParams: Map<STATE_PARAMS, string> | undefined;
        if (result.status === 'REQUIRES_USER_ACTION') {
            const event = this.policyEvaluationService.getEventType();
            const dialogResult = await this.dialogService.showAlertsDialog(result.actions, event);
            stateParams = new Map<STATE_PARAMS, string>().set('USER_ACTION', dialogResult);
        } else if (result.status === 'INVALID_METADATA') {
            const dialogResult = await this.dialogService.showForceClassificationDialog();
            stateParams = new Map<STATE_PARAMS, string>().set('USER_ACTION', dialogResult);
        } else if (result.status !== 'BLOCKED' && result.status !== 'COMPLETED' && result.status !== 'IN_PROGRESS') {
            const dialogResult = await this.dialogService.showSuggestClassificationDialog(
                result.actions,
                result.status.actionId,
            );
            stateParams = new Map<STATE_PARAMS, string>()
                .set('USER_ACTION', dialogResult)
                .set('SUGGEST_HANDLED_ID', result.status.actionId);
        }
        return stateParams;
    };
}
