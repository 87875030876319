import { PolicyServiceApi } from 'titus-ts/dist/js/Util/Network/PolicyServices/PolicyServiceApi';
import SchemaViewProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaViewProvider';
import SchemaProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaProvider';
import { OfficeApi } from '../OfficeApi';
import PolicyRequirementsProvider from 'titus-ts/dist/js/Util/Network/PolicyServices/PolicyRequirementsProvider';
import { OutlookPolicyRequirementsProvider } from './OutlookPolicyRequirementsProvider';
import { DocumentPolicyRequirementsProvider } from './DocumentPolicyRequirementsProvider';

export default class PolicyRequirementsFactory {
    private officeApi: OfficeApi;
    private schemaProvider: SchemaProvider;
    private schemaViewProvider: SchemaViewProvider;
    private policyServiceAPI: PolicyServiceApi;

    constructor(
        officeApi: OfficeApi,
        schemaProvider: SchemaProvider,
        policyServiceAPI: PolicyServiceApi,
        schemaViewProvider: SchemaViewProvider,
    ) {
        this.officeApi = officeApi;
        this.schemaProvider = schemaProvider;
        this.policyServiceAPI = policyServiceAPI;
        this.schemaViewProvider = schemaViewProvider;
    }

    public getPolicyRequirementsProvider = (): PolicyRequirementsProvider => {
        let policyrequirementsProvider: PolicyRequirementsProvider;
        switch (this.officeApi.getHost()) {
            case 'Outlook':
                policyrequirementsProvider = new OutlookPolicyRequirementsProvider(
                    this.officeApi,
                    this.schemaProvider,
                    this.policyServiceAPI,
                );
                break;
            case 'Excel':
            case 'Word':
            case 'PowerPoint':
                policyrequirementsProvider = new DocumentPolicyRequirementsProvider(
                    this.officeApi,
                    this.schemaProvider,
                    this.schemaViewProvider,
                    this.policyServiceAPI,
                );
                break;
        }
        return policyrequirementsProvider;
    };
}
