import React from 'react';
import { AnimationStyles, mergeStyles } from '@fluentui/react/lib/Styling';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';
import PaneTitle from '../shared/PaneTitle';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';

const Disabled = () => {
    return (
        <div style={{ margin: '0px 8px' }}>
            <PaneTitle title="Disabled" themeColor={NeutralColors.gray100} />
            <div className={mergeStyles(AnimationStyles.scaleUpIn100, { fontSize: FontSizes.size14, margin: '8px' })}>
                TITUS Office Add-in does not support this environment. See the TITUS Office Add-in prerequisites for
                supported platforms.
            </div>
        </div>
    );
};

export default Disabled;
