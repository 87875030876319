import ItemValidator from 'titus-ts/dist/js/Util/Policy/ItemValidator';
import { OfficeApi } from '../OfficeApi';
import { Recipient } from 'titus-ts/dist/js/Mail/Recipient';
import { EventType } from 'titus-ts/dist/js/Util/Policy/EventType';

export default class EmailValidator implements ItemValidator {
    private officeApi: OfficeApi;

    constructor(officeApi: OfficeApi) {
        this.officeApi = officeApi;
    }

    public validate = async (event: EventType): Promise<boolean> => {
        let isValid = true;

        if (event === 'OnSend') {
            const allRecipients: Recipient[] = [];
            const recipientPromises = [
                allRecipients.push(...((await this.officeApi.getToAsync()) || [])),
                allRecipients.push(...((await this.officeApi.getCcAsync()) || [])),
                allRecipients.push(...((await this.officeApi.getBccAsync()) || [])),
            ];
            await Promise.all(recipientPromises);
            isValid = allRecipients.length > 0;
        }

        return isValid;
    };
}
