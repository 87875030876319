import React, { useState } from 'react';
import Field from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/Field';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Stack, IStackStyles } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';

// List frame container styles
const containerStackStyles: Partial<IStackStyles> = {
    root: {
        maxHeight: 200,
        border: '1px solid black',
        padding: 6,
        margin: 4,
        overflow: 'auto',
    },
};

// Used to add spacing between value checkboxes
const stackTokens = { childrenGap: 6 };

type CheckboxValuePickerProps = {
    field: Field;
    onChange: (fieldName: string, fieldValues: string | string[]) => void;
    customValuePlaceholderText?: string | undefined;
    filterPlaceholderText?: string | undefined;
};

const isUserDefinedValueValid = (
    newValue: string,
    maxValueLength = 255,
    invalidCharacters: string[] = [';', ',', '='],
): boolean => {
    if (newValue.length > maxValueLength) {
        return false;
    } else if (stringContainsCharacterFromList(newValue, invalidCharacters)) {
        return false;
    }
    return true;
};

const stringContainsCharacterFromList = (searchString: string, characters: string[]): boolean => {
    for (const character of characters) {
        if (searchString.includes(character)) {
            return true;
        }
    }

    return false;
};

const ValuePickerCheckbox = (props: CheckboxValuePickerProps) => {
    const [userDefinedValue, setUserDefinedValue] = useState('');
    const [filterText, setFilterText] = useState('');
    const { field, onChange, customValuePlaceholderText, filterPlaceholderText } = props;

    const _onChange = (event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean): void => {
        const changedItem = event?.target as HTMLInputElement;

        if (changedItem) {
            if (!field.selectedValue) field.selectedValue = [];
            const selectedValues = checked
                ? [...field.selectedValue, changedItem.name]
                : [...field.selectedValue?.filter((key) => key !== changedItem.name)];

            onChange(field.name, selectedValues);
        }
    };
    const _onUserDefinedValueKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key == 'Enter' || event.key == ';') {
            const newValue = event.currentTarget.value;

            if (isUserDefinedValueValid(newValue)) {
                onChange(field.name, field.selectedValue ? [...field.selectedValue, newValue] : [newValue]);
                setUserDefinedValue('');
            }
        }
    };
    const _onUserDefinedValueChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void => {
        if (!newValue || isUserDefinedValueValid(newValue)) {
            setUserDefinedValue(newValue ? newValue : '');
        }
    };

    const _onFilterTextChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void => {
        newValue = newValue == undefined ? '' : newValue;
        setFilterText(newValue);
    };

    return (
        <div data-testid="checkbox_valuepicker">
            {!field?.restrictToValueList ? (
                <TextField
                    name={field.name + '_additionalEntry'}
                    placeholder={customValuePlaceholderText || 'Enter field value'}
                    value={userDefinedValue}
                    onKeyUp={_onUserDefinedValueKeyUp}
                    onChange={_onUserDefinedValueChange}
                />
            ) : null}
            {field?.restrictToValueList && field?.isFilterEnabled ? (
                <TextField
                    name={field.name + '_filter'}
                    placeholder={filterPlaceholderText || 'Filter...'}
                    value={filterText}
                    onChange={_onFilterTextChange}
                />
            ) : null}

            <Stack styles={containerStackStyles} tokens={stackTokens}>
                {field.values
                    .filter(
                        (value) =>
                            value.name.toLowerCase().includes(filterText.toLowerCase()) ||
                            value.displayText.toLowerCase().includes(filterText.toLowerCase()),
                    )
                    .map((value, i) => (
                        <TooltipHost key={i} content={value.tooltip}>
                            <Checkbox
                                name={value.name}
                                onChange={_onChange}
                                checked={field.selectedValue?.includes(value.name) as boolean}
                                label={value.displayText}
                            />
                        </TooltipHost>
                    ))}
            </Stack>
        </div>
    );
};

export default ValuePickerCheckbox;
