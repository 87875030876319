import { StorageKeys } from './storageTypes';

export class SessionStorageService {
    public static save = <T>(item: T, key: StorageKeys): void => {
        const itemJson = JSON.stringify(item);
        sessionStorage.setItem(key, itemJson);
    };

    public static load = <T>(key: StorageKeys): T => {
        const itemJson = sessionStorage.getItem(key);
        if (itemJson) {
            const item = JSON.parse(itemJson) as T;
            return item;
        } else {
            throw new Error(`Requested key ${key} not found in local storage.`);
        }
    };
}
