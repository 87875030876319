import React from 'react';
import { MetadataObligation } from 'titus-ts/dist/js/Util/Metadata/MetadataValidationResult';
import ApplicationMessage, { WARNING, ERROR } from '../shared/ApplicationMessage';

type ObligationProps = {
    metadataObligation: MetadataObligation[] | undefined;
};

const Obligation = (props: ObligationProps) => {
    const { metadataObligation } = props;
    return (
        <div>
            {metadataObligation && (
                <div>
                    {metadataObligation.map((obligation, index) => (
                        <div key={'oblg_div' + index} style={{ marginTop: '4px' }}>
                            <ApplicationMessage
                                message={obligation.message}
                                type={obligation.outcome === 'Warn' ? WARNING : ERROR}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Obligation;
