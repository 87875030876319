import React from 'react';
import { ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react/lib/Shimmer';

const FieldValueShimmer = () => {
    const lineWidth = Math.floor(Math.random() * 30) + 30;
    const gapWidth = 100 - lineWidth - 5;
    return (
        <ShimmerElementsGroup
            width="100%"
            shimmerElements={[
                { type: ShimmerElementType.gap, width: '5%' },
                { type: ShimmerElementType.line, width: lineWidth + '%' },
                { type: ShimmerElementType.gap, width: gapWidth + '%' },
            ]}
        />
    );
};

export default FieldValueShimmer;
