import {
    ReadPaneState,
    ReadPaneActionTypes,
    READ_PANE_LOAD_BEGIN,
    READ_PANE_LOAD_SUCCESS,
    READ_PANE_LOAD_ERROR,
    READ_PANE_LOAD_UPDATE_COUNT,
} from './types';

export const readPaneInitialState: ReadPaneState = {
    isLoading: false,
    loadingFieldCount: 0,
    error: null,
    classificationPaneViewModel: null,
};

export const readPaneReducer = (
    state: ReadPaneState = readPaneInitialState,
    action: ReadPaneActionTypes,
): ReadPaneState => {
    switch (action.type) {
        case READ_PANE_LOAD_BEGIN: {
            return {
                ...state,
                isLoading: true,
                error: null,
                classificationPaneViewModel: null,
            };
        }
        case READ_PANE_LOAD_UPDATE_COUNT: {
            return {
                ...state,
                loadingFieldCount: action.count,
            };
        }
        case READ_PANE_LOAD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                classificationPaneViewModel: { ...action.classificationPaneViewModel },
            };
        }
        case READ_PANE_LOAD_ERROR: {
            return {
                ...state,
                isLoading: false,
                loadingFieldCount: 0,
                classificationPaneViewModel: null,
                error: action.error,
            };
        }
        default: {
            return state;
        }
    }
};
