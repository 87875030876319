import ActionHandler from 'titus-ts/dist/js/Util/Actions/ActionHandler';
import { OfficeApi } from '../OfficeApi';
import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { Recipient } from 'titus-ts/dist/js/Mail/Recipient';

export default class RemediateRecipientAlerts implements ActionHandler {
    private officeApi: OfficeApi;
    private readonly maxRecipients: number = 100;
    private readonly RECIPIENT_ADDRESS = 'RecipientAddress';

    constructor(officeApi: OfficeApi) {
        this.officeApi = officeApi;
    }

    public handle = async (action: Action[]): Promise<void> => {
        const alertRecipients = this.getAlertRecipients(action);
        if (alertRecipients.length === 0) return;

        const removeRecipients = [
            this.removeRecipients(
                alertRecipients,
                this.officeApi.getToAsync,
                this.officeApi.setToAsync,
                this.officeApi.addToAsync,
            ),
            this.removeRecipients(
                alertRecipients,
                this.officeApi.getCcAsync,
                this.officeApi.setCcAsync,
                this.officeApi.addCcAsync,
            ),
            this.removeRecipients(
                alertRecipients,
                this.officeApi.getBccAsync,
                this.officeApi.setBccAsync,
                this.officeApi.addBccAsync,
            ),
        ];
        await Promise.all(removeRecipients);
    };

    private getAlertRecipients = (actions: Action[]): string[] => {
        const recipients: string[] = [];
        actions.forEach((action) => {
            const recipient = action.params.get(this.RECIPIENT_ADDRESS);
            if (recipient) {
                recipients.push(recipient);
            }
        });
        return recipients;
    };

    private removeRecipients = async (
        alertRecipients: string[],
        getAsync: () => Promise<Recipient[]>,
        setAsync: (emails: string[]) => Promise<void>,
        addAsync: (emails: string[]) => Promise<void>,
    ): Promise<void> => {
        const originalRecipients = await getAsync();
        const mappedRecipient = originalRecipients.map((recipient) => recipient.Address);
        const filteredRecipients = mappedRecipient.filter((val) => alertRecipients.indexOf(val) === -1);

        if (originalRecipients.length === filteredRecipients.length) {
            return;
        }

        if (filteredRecipients.length > this.maxRecipients) {
            await setAsync([]);
            let processedRecipients = 0;
            let recipientsToAdd = filteredRecipients.slice(0, this.maxRecipients);
            while (filteredRecipients.length !== processedRecipients) {
                await addAsync(recipientsToAdd);
                processedRecipients += recipientsToAdd.length;
                recipientsToAdd = filteredRecipients.slice(
                    processedRecipients,
                    processedRecipients + this.maxRecipients,
                );
            }
        } else {
            await setAsync(filteredRecipients);
        }
    };
}
