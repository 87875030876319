import React from 'react';
import { ApplicationSettings } from '../../../node_modules/titus-ts/dist/js/Settings/ApplicationSettings';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';

type RequiredSelectionWarningProps = {
    appSettings: ApplicationSettings | null;
};

const RequiredSelectionWarning = (props: RequiredSelectionWarningProps) => {
    const { appSettings } = props;
    return (
        <div data-testid="requiredselectionwarning">
            {appSettings && (
                <div
                    style={{
                        margin: '32px 8px',
                        borderTop: '1px solid',
                        borderTopColor: NeutralColors.gray80,
                        fontSize: FontSizes.size12,
                    }}
                >
                    {appSettings.MetadataSelectionUi &&
                        appSettings.MetadataSelectionUi.DialogRequiredSelectionWarningText}
                </div>
            )}
        </div>
    );
};

export default RequiredSelectionWarning;
