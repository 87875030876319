import { TitusAuth } from 'titus-auth';

export class AuthService {
    private titusAuth: TitusAuth;

    public constructor(titusAuth: TitusAuth) {
        this.titusAuth = titusAuth;
    }

    public getTitusToken = async (): Promise<string> => {
        const userSmtpAddress = Office?.context?.mailbox?.userProfile?.emailAddress;
        let titusToken: string | null;

        titusToken = this.fetchTitusToken(userSmtpAddress);

        // if we have no valid cached token try to get a new one
        if (!titusToken) {
            titusToken = await this.getNewTitusToken(userSmtpAddress);
        }

        return titusToken;
    };

    private getNewTitusToken = async (userSmtpAddress: string | undefined): Promise<string> => {
        try {
            const authClient = await this.titusAuth.getTitusAuthClientWithOfficeConfig(userSmtpAddress || '');
            const titusToken = await authClient.renewTitusJWTToken();

            if (userSmtpAddress) {
                this.cacheTitusToken(userSmtpAddress, titusToken);
            }

            return titusToken;
        } catch {
            throw new Error('Error retrieving Titus token.');
        }
    };

    private cacheTitusToken = (authenticatedUserSmtp: string, titusToken: string): void => {
        const tokenExpiry = new Date().getTime() + 30 * 60000;

        localStorage.setItem('authenticatedUserSmtp', authenticatedUserSmtp);
        localStorage.setItem('titusToken', titusToken);
        localStorage.setItem('titusTokenExpiration', tokenExpiry.toString());
    };

    private fetchTitusToken = (userSmtpAddress: string | undefined): string | null => {
        const storedTitusTokenExpiration = parseInt(localStorage.getItem('titusTokenExpiration') || '-1');
        const storedTitusToken = localStorage.getItem('titusToken');
        const storedAuthenticatedUser = localStorage.getItem('authenticatedUserSmtp');

        // if we have a token stored, its for this user and it hasn't expired
        if (
            storedTitusToken &&
            storedTitusTokenExpiration > new Date().getTime() &&
            (!userSmtpAddress || userSmtpAddress == storedAuthenticatedUser)
        ) {
            return storedTitusToken;
        }

        return null;
    };
}
