import { ALERT_MODE } from '../../store/alerts/types';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import { DialogMessages } from 'titus-ts/dist/js/Util/Policy/PolicyEvaluationBaseState';
import { DIALOG_REMEDIATE_TYPE } from '../../services/dialogService';
import { ApplicationSettings } from 'titus-ts/dist/js/Settings/ApplicationSettings';

export const stackTokens: IStackTokens = { childrenGap: 16 };

export type AlertsCommandBarPropsOutlook = {
    mode: ALERT_MODE | undefined;
    canRemediateAll: boolean;
    remediated: boolean;
    enableOk: boolean;
    appSettings: ApplicationSettings | null;
    completeAlerts: (message: DialogMessages) => Promise<void>;
    remediateAll: (remediatetype: DIALOG_REMEDIATE_TYPE) => void;
};

export type AlertsCommandBarPropsDocument = {
    completeAlerts: (message: DialogMessages) => Promise<void>;
    okButtonText?: string | undefined;
};
