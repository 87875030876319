import React from 'react';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';

type ApplicationErrorProps = {
    error: string;
};

const ApplicationError = ({ error }: ApplicationErrorProps) => {
    return <div style={{ fontSize: FontSizes.size14 }}>{error}</div>;
};

export default ApplicationError;
