import Attachment from 'titus-ts/dist/js/Mail/Attachment';

export class AttachmentOffice extends Attachment {
    public constructor(name: string, sizeKB: number) {
        super(name, sizeKB);
    }

    public equals(other: AttachmentOffice): boolean {
        return this.FileName === other.FileName && this.SizeKB === other.SizeKB;
    }

    public clone(): AttachmentOffice {
        return new AttachmentOffice(this.FileName, this.SizeKB);
    }
}
