import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { AlertViewModel } from '../store/alerts/types';
import { SerializableAction } from './storage/storageTypes';
import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import FieldCodeService from 'titus-ts/dist/js/Util/Actions/FieldCodeService';

export const groupBy = <T>(array: Array<T>, keyGetter: (item: T) => string) => {
    const result = new Map<string, T[]>();
    array.forEach((item) => {
        const key = keyGetter(item);
        const grouped = result.get(key);
        if (grouped) {
            grouped.push(item);
        } else {
            result.set(key, [item]);
        }
    });

    return result;
};

export class AlertFactory {
    public static getAlertViewModels = (alerts: Action[], fieldCodeService?: FieldCodeService): AlertViewModel[] => {
        const result: AlertViewModel[] = [];
        alerts.forEach((alert) => {
            if (alert.type !== 'RecipientAlert' && alert.type !== 'Deny') {
                let title = alert.params.get('Message');
                let description = alert.params.get('Description');

                if (fieldCodeService) {
                    title = title ? fieldCodeService.replaceFieldCodes(title) : undefined;
                    description = description ? fieldCodeService.replaceFieldCodes(description) : undefined;
                }
                const vm: AlertViewModel = {
                    title: title,
                    description: description,
                    isExpanded: alert.params.get('ExpandDescription') === 'true',
                    isRemediable: false,
                    type: alert.type,
                    alerts: [alert],
                };
                result.push(vm);
            }
        });
        const recipientAlerts = alerts.filter((alert) => alert.type === 'RecipientAlert');
        if (recipientAlerts && recipientAlerts.length > 0) {
            const grouped = groupBy(recipientAlerts, (alert) => alert.id);
            grouped.forEach((value) => {
                let title = value[0].params.get('Message');
                let description = value[0].params.get('Description');

                if (fieldCodeService) {
                    title = title ? fieldCodeService.replaceFieldCodes(title) : undefined;
                    description = description ? fieldCodeService.replaceFieldCodes(description) : undefined;
                }
                const recipientVm: AlertViewModel = {
                    title: title,
                    description: description,
                    isExpanded: value[0].params.get('ExpandDescription') === 'true',
                    isRemediable: true,
                    type: value[0].type,
                    alerts: [],
                };

                value.forEach((item) => {
                    recipientVm.description += `\n${item.params.get('RecipientAddress')}` || '';
                    recipientVm.alerts.push(item);
                });

                result.push(recipientVm);
            });
        }

        return result;
    };

    private static mapToObject = (map: Map<string, string>) => {
        const obj = {} as DictionaryInterface<string>;
        map.forEach((value, key) => {
            obj[key] = value;
        });

        return obj;
    };

    public static serializeActions = (actions: Action[]): SerializableAction[] => {
        const serializedActions: SerializableAction[] = actions.map((action) => {
            return {
                ...action,
                params: AlertFactory.mapToObject(action.params),
            };
        });

        return serializedActions;
    };

    public static deserializeActions = (serializedActions: SerializableAction[]): Action[] => {
        const alerts: Action[] = serializedActions.map((action) => {
            return {
                ...action,
                params: new Map(Object.entries(action.params)),
            };
        });

        return alerts;
    };
}
