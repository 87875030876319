import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { OfficeApi } from '../OfficeApi';
import SubjectLabelHandler from 'titus-ts/dist/js/Util/Actions/SubjectLabelHandler';
import ActionHandler from 'titus-ts/dist/js/Util/Actions/ActionHandler';
import FieldCodeService from 'titus-ts/dist/js/Util/Actions/FieldCodeService';

export default class OfficeSubjectLabelHandler implements ActionHandler {
    private officeApi: OfficeApi;
    private subjectLabelHandler: SubjectLabelHandler;
    private fieldCodeService: FieldCodeService;
    private readonly subjectLabelHeader = 'x-tituslabs-subjectlabels';

    constructor(officeApi: OfficeApi, fieldCodeService: FieldCodeService, subjectLabelHandler?: SubjectLabelHandler) {
        this.officeApi = officeApi;
        this.fieldCodeService = fieldCodeService;
        this.subjectLabelHandler = subjectLabelHandler || new SubjectLabelHandler();
    }

    public handle = async (actions: Action[]): Promise<void> => {
        const currentSubject = await this.officeApi.getSubjectAsync();
        const originalLabels = await this.readOriginalLabels();
        const updatedActions = this.fieldCodeService.process(actions, 'SubjectLabel');
        const subjectWithLabels = await this.subjectLabelHandler.getSubjectLabels(
            updatedActions,
            currentSubject,
            originalLabels,
        );
        if (currentSubject !== subjectWithLabels) {
            await this.officeApi.setSubjectAsync(subjectWithLabels);
        }
        await this.writeOriginalLabels();
    };

    private async readOriginalLabels(): Promise<string> {
        let originalLabels = '';
        const conversationId = this.officeApi.getConversationId();
        if (conversationId) {
            const subjectLabelHeaders = await this.officeApi.getInternetHeadersFromConversationAsync(conversationId, [
                this.subjectLabelHeader,
            ]);
            if (this.subjectLabelHeader in subjectLabelHeaders) {
                originalLabels = subjectLabelHeaders[this.subjectLabelHeader];
            }
        }
        return originalLabels;
    }

    private async writeOriginalLabels(): Promise<void> {
        const subjectLabels = this.subjectLabelHandler.getSubjectHeaders();
        const subjectLabelHeader = { [this.subjectLabelHeader]: subjectLabels };
        await this.officeApi.setInternetHeadersAsync(subjectLabelHeader);
    }
}
