import {
    SelectClassificationState,
    SelectClassificationActionTypes,
    SELECT_CLASSIFICATION_PROCESSING_BEGIN,
    SELECT_CLASSIFICATION_LOAD_SUCCESS,
    SELECT_CLASSIFICATION_LOAD_ERROR,
    SELECT_CLASSIFICATION_CLEAR_ERROR,
    SELECT_CLASSIFICATION_LOAD_UPDATE_APPSETTINGS,
    SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_SUCCESS,
    SELECT_CLASSIFICATION_APPLY_METADATA_SUCCESS,
    SELECT_CLASSIFICATION_APPLY_METADATA_ERROR,
    SELECT_CLASSIFICATION_GENERAL_ERROR,
    SELECT_CLASSIFICATION_LOAD_SUGGEST_PREAMBLE,
} from './types';

export const selectClassinitialState: SelectClassificationState = {
    processing: false,
    processingMessage: null,
    classificationPaneViewModel: null,
    applicationSettings: null,
    error: null,
    suggestPreamble: null,
    themeColor: '',
};

export const selectClassificationReducer = (
    state: SelectClassificationState = selectClassinitialState,
    action: SelectClassificationActionTypes,
): SelectClassificationState => {
    switch (action.type) {
        case SELECT_CLASSIFICATION_PROCESSING_BEGIN:
            return {
                ...state,
                processing: true,
                processingMessage: action.message,
                error: null,
                suggestPreamble: null,
            };
        case SELECT_CLASSIFICATION_LOAD_SUCCESS:
            return {
                ...state,
                processing: false,
                processingMessage: null,
                error: null,
                classificationPaneViewModel: action.classificationPaneViewModel,
                themeColor: action.themeColor,
            };
        case SELECT_CLASSIFICATION_LOAD_ERROR:
            return {
                ...state,
                error: action.error,
                processing: false,
                processingMessage: null,
            };
        case SELECT_CLASSIFICATION_LOAD_UPDATE_APPSETTINGS:
            return {
                ...state,
                applicationSettings: action.appSettings,
                themeColor: state.themeColor,
            };
        case SELECT_CLASSIFICATION_LOAD_SUGGEST_PREAMBLE:
            return {
                ...state,
                suggestPreamble: action.preamble,
                themeColor: state.themeColor,
            };
        case SELECT_CLASSIFICATION_CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        case SELECT_CLASSIFICATION_UPDATE_VIEWMODEL_SUCCESS:
            return {
                ...state,
                classificationPaneViewModel: action.classificationPaneViewModel,
                themeColor: state.themeColor,
            };
        case SELECT_CLASSIFICATION_APPLY_METADATA_SUCCESS:
            return {
                ...state,
                processing: false,
                processingMessage: null,
                error: null,
            };
        case SELECT_CLASSIFICATION_APPLY_METADATA_ERROR:
            return {
                ...state,
                processing: false,
                processingMessage: null,
                error: action.error,
            };
        case SELECT_CLASSIFICATION_GENERAL_ERROR:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
