import ActionHandler from 'titus-ts/dist/js/Util/Actions/ActionHandler';
import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { OfficeApi } from '../OfficeApi';
import FieldCodeService from 'titus-ts/dist/js/Util/Actions/FieldCodeService';
import ApplyHeaderFooterHandler from 'titus-ts/dist/js/Util/Actions/ApplyHeaderFooterHandler';

export default class OfficeApplyHeaderFooterHandler implements ActionHandler {
    private officeApi: OfficeApi;
    private fieldCodeService: FieldCodeService;
    private applyHeaderFooterHandler: ApplyHeaderFooterHandler;

    constructor(
        officeApi: OfficeApi,
        fieldCodeService: FieldCodeService,
        applyHeaderFooterHandler?: ApplyHeaderFooterHandler,
    ) {
        this.officeApi = officeApi;
        this.fieldCodeService = fieldCodeService;
        this.applyHeaderFooterHandler = applyHeaderFooterHandler || new ApplyHeaderFooterHandler();
    }

    public handle = async (actions: Action[]): Promise<void> => {
        const updatedActions = this.fieldCodeService.process(actions, 'HTMLHeaderFooter');
        const { header, footer } = this.applyHeaderFooterHandler.getHeaderFooter(updatedActions);
        const titusPrefix = 'Titus';
        if (header) {
            await this.officeApi.setHeaderAndFooterAsync('header', header, titusPrefix);
        }
        if (footer) {
            await this.officeApi.setHeaderAndFooterAsync('footer', footer, titusPrefix);
        }
    };
}
