import { EventType } from 'titus-ts/dist/js/Util/Policy/EventType';
import { OfficeApi } from '../OfficeApi';
import { EmailUser } from 'titus-ts/dist/js/Mail/Recipient';
import MailboxItem from 'titus-ts/dist/js/Mail/MailboxItem';
import { OutlookPolicyRequirementProps } from './PolicyRequirementsTypes';
import Message from 'titus-ts/dist/js/Mail/Message';
import { PolicyServiceApi } from 'titus-ts/dist/js/Util/Network/PolicyServices/PolicyServiceApi';
import SchemaProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaProvider';
import { appService } from '../../services/appService';
import OfficePolicyRequirementsProvider from './OfficePolicyRequirementsProvider';

export class OutlookPolicyRequirementsProvider extends OfficePolicyRequirementsProvider {
    private mailboxItem: MailboxItem;
    private domParser: DOMParser;

    public constructor(
        officeApi: OfficeApi,
        schemaProvider: SchemaProvider,
        policyServiceAPI: PolicyServiceApi,
        domParser?: DOMParser,
    ) {
        super(officeApi, schemaProvider, policyServiceAPI);
        this.mailboxItem = new MailboxItem();
        this.domParser = domParser ? domParser : new DOMParser();
    }

    public getRequiredPropertiesJson = async (eventType: EventType): Promise<string> => {
        await this.loadPolicyRequirements(this.policyServiceAPI);
        const messageProperties = await this.getMessageProperties();
        const policyRequirementsProperties: OutlookPolicyRequirementProps = {
            Context: this.getContextProperties(
                eventType,
                appService,
                this.officeApi,
                this.productVersion,
                messageProperties.Sender,
            ),
            Environment: this.getEnviromentProperties(messageProperties.Sender),
            Message: messageProperties,
            MailUser: messageProperties.Sender,
        };

        return this.createPolicyEventRequiredFieldsJsonString(eventType, policyRequirementsProperties);
    };

    private getMessageProperties = async (): Promise<Message> => {
        const messageProperties: Message = new Message();
        const outlookProperties = [
            messageProperties.Recipient.push(...((await this.officeApi.getToAsync()) || [])),
            messageProperties.Recipient.push(...((await this.officeApi.getCcAsync()) || [])),
            messageProperties.Recipient.push(...((await this.officeApi.getBccAsync()) || [])),
            (messageProperties.Subject = await this.officeApi.getSubjectAsync()),
            (messageProperties.Attachment = await this.officeApi.getAttachments()),
            (messageProperties.BodyType = await this.officeApi.getBodyTypeAsync()),
            (messageProperties.Body = await this.officeApi.getBodyAsHtmlAsync()),
            (messageProperties.OriginalSender = await this.officeApi.getOriginalSender()),
        ];
        await Promise.all(outlookProperties);
        messageProperties.Body = this.extractEmailBody(messageProperties.Body);
        messageProperties.RecipientCount = messageProperties.Recipient.length;
        const senderAddress = this.officeApi.getSenderAddress();
        const senderDisplayName = this.officeApi.getSenderDisplayName();
        if (senderAddress && senderDisplayName) {
            messageProperties.Sender = new EmailUser(senderAddress, senderDisplayName);
        }
        if (messageProperties.Subject) {
            messageProperties.SubjectLength = messageProperties.Subject.length;
            messageProperties.IsForward = this.mailboxItem.forwardRegex.test(messageProperties.Subject);
            messageProperties.IsReply = this.mailboxItem.replyRegex.test(messageProperties.Subject);
            messageProperties.IsReplyOrForward = messageProperties.IsReply || messageProperties.IsForward;
        }
        if (messageProperties.Body) {
            messageProperties.BodyLength = messageProperties.Body.length;
        }
        if (messageProperties.Attachment) {
            messageProperties.Attachment.forEach((attachment) => {
                messageProperties.AttachmentTotalSizeKB += attachment.SizeKB;
            });
            messageProperties.AttachmentCount = messageProperties.Attachment.length;
        }
        messageProperties.Metadata = this.mapMetadata(this.schemaProvider.getMetadata());
        messageProperties.OriginalMetadata = this.mapMetadata(this.schemaProvider.getOriginalMetadata());
        messageProperties.HasMetadata = messageProperties.Metadata ? true : false;
        return messageProperties;
    };

    private extractEmailBody = (html: string | undefined): string => {
        let textContent = '';
        if (html) {
            const result = html.replace(/<\/div>/gm, '</div>\n').replace(/<br>/gm, '<br>\n');
            const parsedHtml = this.domParser.parseFromString(result, 'text/html');
            textContent = parsedHtml.body.textContent || '';
        }
        return textContent;
    };
}
