import { OfficeApi } from './../office/OfficeApi';
import AppService from './appService';
import { Container, ApplicationServices } from './serviceContainer';
import MetadataService from './metadataService';
import SchemaProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaProvider';
import { FailOpenCloseService } from './failOpenCloseService';
import { TimeOutError } from '../office/errors/TimeOutError';

export default class OfficeEventService {
    private appService: AppService;
    private serviceContainer: Container;

    constructor(appService: AppService, serviceContainer: Container) {
        this.appService = appService;
        this.serviceContainer = serviceContainer;
    }

    public evaluateOnSend = async (): Promise<boolean> => {
        let isOnSendAllowed = false;
        const officeApi = this.serviceContainer.getService<OfficeApi>('OfficeApi');
        const isItemTypeSupported = officeApi.isItemTypeSupported();
        const isPlatformEnabled = this.appService.isPlatformEnabled();
        if (isItemTypeSupported && isPlatformEnabled) {
            try {
                const { policyService, metadataService, schemaProvider } = await this.initializeServices();

                const evalResult = await policyService.evaluatePolicy();
                if (evalResult.status === 'COMPLETED') {
                    await this.saveEmailMetadata(schemaProvider, metadataService, officeApi);
                }

                isOnSendAllowed = evalResult.status === 'COMPLETED';
            } catch (err) {
                isOnSendAllowed = await this.handleError(err);
            }
        } else {
            isOnSendAllowed = true;
        }

        return isOnSendAllowed;
    };

    private initializeServices = async (): Promise<ApplicationServices> => {
        await this.serviceContainer.initialize('ONSEND_MODE');
        return this.serviceContainer.getAllServices();
    };

    private writeEmailMetadata = async (
        schemaProvider: SchemaProvider,
        metadataService: MetadataService,
    ): Promise<void> => {
        const metadataToWrite = schemaProvider.getMetadata();
        metadataService.writeMetadata(metadataToWrite);
    };

    private async saveEmailMetadata(
        schemaProvider: SchemaProvider,
        metadataService: MetadataService,
        officeApi: OfficeApi,
    ) {
        await this.writeEmailMetadata(schemaProvider, metadataService);
        await officeApi.saveAsync();
    }

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleError = async (error: any): Promise<boolean> => {
        try {
            let isOnSendAllowed = false;
            if (!(error instanceof TimeOutError)) {
                const failOpenCloseService =
                    this.serviceContainer.getService<FailOpenCloseService>('FailOpenCloseService');
                isOnSendAllowed = await failOpenCloseService.triggerFailOpenClose();
            }
            return isOnSendAllowed;
        } catch (err) {
            if (err instanceof TimeOutError) {
                return false;
            } else {
                return true;
            }
        }
    };
}
