/* eslint-disable */ 
import { AttachmentOffice } from './AttachmentOffice';
import { OutlookApi } from './OutlookApi';
import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import Logger from 'titus-ts/dist/js/Common/Util/Logger';
import { WebSettings } from 'titus-ts/dist/js/Common/Settings/ApplicationSettingsInterface';
import Xml from 'titus-ts/dist/js/Common/Util/Xml';
import { EmailUser } from 'titus-ts/dist/js/Mail/Recipient';

export interface IEwsProcessOptions {
    error(error: Office.AsyncResult<any>): void;
    success(result: Office.AsyncResult<any>): void;
}
export type IValueChangedCallback = (id: string) => void;

export interface IEwsGetMessageHeadersOptions {
    error(error: Office.AsyncResult<any>): void;
    success(data: DictionaryInterface<string>): void;
}

export interface IGetHeadersByConversationOptions {
    success(xHeaders: any): void;
    error(state: any): void;
}

export interface IFindItemByInternetMessageIdOptions {
    success(): void;
    error(state: any): void;
}

export class EwsRequestGenerator {
    private domParser: any;
    private outlookApi?: OutlookApi;
    private logger: Logger;

    constructor(domParser?: any, outlookApi?: OutlookApi) {
        this.domParser = domParser ? domParser : new DOMParser();
        this.outlookApi = outlookApi;

        this.logger = new Logger(WebSettings && WebSettings.isDebug);
    }

    public async setInternetHeaders(itemId: string, headers: DictionaryInterface<string>): Promise<any> {
        this.logger.debug(`[EwsHelper] setInternetHeaders start...`);
        const changeKey = await this.getChangeKey(itemId);
        this.logger.debug(`[EwsHelper] setInternetHeaders -> retrieved change key: ${changeKey}`);
        const setHeadersRequest = this.createSetHeadersRequest(itemId, changeKey, headers);
        this.logger.debug(`[EwsHelper] setInternetHeaders ->created set header request`);
        await this.processAsyncUntilSuccessPromise('UpdateItem', setHeadersRequest);
        this.logger.debug(`[EwsHelper] setInternetHeaders -> Completed process async until success`);
    }

    public async getInternetHeaders(itemId: string, headersToRequest: string[]): Promise<DictionaryInterface<string>> {
        const getHeadersRequest = this.createEwsGetInternetMessageHeadersAndExtendedPropertiesRequest(
            itemId,
            headersToRequest,
        );
        const ewsGetHeadersResponse = await this.processAsyncUntilSuccessPromise('GetItem', getHeadersRequest);
        const headers = this.parseHeaderResponse(ewsGetHeadersResponse, headersToRequest);
        return headers;
    }

    public async getChangeKey(itemId: string): Promise<string> {
        this.logger.debug(`[EwsHelper] getChangeKey start...`);
        const getChangeKeyRequest = this.createChangeKeyRequest(itemId);
        this.logger.debug(`[EwsHelper] getChangeKey calling processAsyncUntilSuccessPromise...`);
        const ewsGetChangeKeyResponse = await this.processAsyncUntilSuccessPromise('GetItem', getChangeKeyRequest);
        this.logger.debug(`[EwsHelper] getChangeKey completed processAsyncUntilSuccessPromise...`);
        const changeKey = this.parseEwsResponse<string>(ewsGetChangeKeyResponse, 'ChangeKey');
        this.logger.debug(`[EwsHelper] getChangeKey completed... ${itemId}`);
        return changeKey;
    }

    private createSetHeadersRequest(itemId: string, changeKey: string, headers: DictionaryInterface<string>): string {
        let result = `
            <UpdateItem MessageDisposition="SaveOnly" ConflictResolution="AlwaysOverwrite"  SuppressReadReceipts="true"
                        xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">
                <ItemChanges>
                    <t:ItemChange>
                       <t:ItemId Id="${itemId}" ChangeKey="${changeKey}" /> 
                       <t:Updates>`;

        Object.keys(headers).forEach(headerName => {
            const headerValue = headers[headerName];
            result += `     <t:SetItemField>
                                <t:ExtendedFieldURI DistinguishedPropertySetId="InternetHeaders"
                                    PropertyName="${Xml.toXmlAttributeValue(headerName)}"
                                    PropertyType="String" />
                                <t:Message>
                                    <t:ExtendedProperty>
                                        <t:ExtendedFieldURI DistinguishedPropertySetId="InternetHeaders"
                                            PropertyName="${Xml.toXmlAttributeValue(headerName)}"
                                            PropertyType="String" />
                                        <t:Value>${Xml.toXmlContent(headerValue)}</t:Value>
                                    </t:ExtendedProperty>
                                </t:Message>
                            </t:SetItemField>`;
        });
        result += `     </t:Updates>
                    </t:ItemChange>
                </ItemChanges>
            </UpdateItem>`;

        return result;
    }

    private createChangeKeyRequest(itemId: string): string {
        const xmlString = `<GetItem xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">
            <ItemShape>
                <t:BaseShape>IdOnly</t:BaseShape>
                <t:AdditionalProperties>
                    <t:FieldURI FieldURI="item:ItemId"/>
                </t:AdditionalProperties>
            </ItemShape>
            <ItemIds>
                <t:ItemId Id="${itemId}"/>
            </ItemIds>
        </GetItem>`;

        return xmlString;
    }

    private parseEwsResponse<T>(ewsResponse: any, itemName: string): T {
        const typesNs = 'http://schemas.microsoft.com/exchange/services/2006/types';
        const xmlDoc = this.domParser.parseFromString(ewsResponse.value, 'text/xml');
        const item = xmlDoc.documentElement.getElementsByTagNameNS(typesNs, 'ItemId')[0].getAttribute(itemName);

        return item;
    }

    public parseHeaderResponse(ewsResponse: any, headersToRequest: string[]): DictionaryInterface<string> {
        const result: DictionaryInterface<string> = {};

        const xmlDoc = this.domParser.parseFromString(ewsResponse.value, 'text/xml');
        const headerElementsHeaders = this.parseHeaderElements(xmlDoc);
        const extenderPropertiesHeaders = this.parseHeaderExtendedPropertiesElements(xmlDoc);
        headersToRequest.forEach(headerName => {
            if (headerName in headerElementsHeaders) {
                result[headerName] = headerElementsHeaders[headerName];
            } else if (headerName in extenderPropertiesHeaders) {
                result[headerName] = extenderPropertiesHeaders[headerName];
            }
        });

        return result;
    }

    private parseHeaderElements(xmlDoc: any): DictionaryInterface<string> {
        const headers = xmlDoc.getElementsByTagNameNS(
            'http://schemas.microsoft.com/exchange/services/2006/types',
            'InternetMessageHeader',
        );
        const result: DictionaryInterface<string> = {};
        for (let i = 0; i < headers.length; i += 1) {
            const headerName = headers[i].getAttribute('HeaderName');
            result[headerName] = headers[i].textContent;
        }
        return result;
    }

    private parseHeaderExtendedPropertiesElements(xmlDoc: any): DictionaryInterface<string> {
        const properties = xmlDoc.getElementsByTagNameNS(
            'http://schemas.microsoft.com/exchange/services/2006/types',
            'ExtendedProperty',
        );
        const result: DictionaryInterface<string> = {};

        for (let i = 0; i < properties.length; i += 1) {
            const propInfo = properties[i].getElementsByTagNameNS(
                'http://schemas.microsoft.com/exchange/services/2006/types',
                'ExtendedFieldURI',
            );
            if (propInfo.length > 0) {
                const propertyName = propInfo[0].getAttribute('PropertyName');
                const value = properties[i].getElementsByTagNameNS(
                    'http://schemas.microsoft.com/exchange/services/2006/types',
                    'Value',
                );
                if (value.length > 0) {
                    result[propertyName] = value[0].textContent;
                }
            }
        }
        return result;
    }

    /**
     * Creates a soap envelope that contains the specified inner xml.
     * @param soapBodyInner The EWS request xml to wrap.
     * @returns a soap request xml string.
     */
    private getSoapEnvelope(soapBodyInner: string | null): string {
        // tslint:disable-next-line:no-unnecessary-local-variable
        const result = `<?xml version="1.0" encoding="utf-8"?>
                        <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                                    xmlns:xsd="http://www.w3.org/2001/XMLSchema"
                                    xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"
                                    xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages"
                                    xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
                        <soap:Header>
                            <t:RequestServerVersion Version="Exchange2013_SP1" soap:mustUnderstand="0"/>
                        </soap:Header>
                        <soap:Body>
                            ${soapBodyInner}
                        </soap:Body>
                    </soap:Envelope>`;

        return result;
    }

    public processAsyncUntilSuccess(action: string, ewsSoapRequest: string, options: IEwsProcessOptions): void {
        const messagesNs =
        // tslint:disable-next-line:no-http-string
        'http://schemas.microsoft.com/exchange/services/2006/messages';
        const envelope = this.getSoapEnvelope(ewsSoapRequest);
        const log = new Logger(WebSettings && WebSettings.isDebug);
        let retryCount = 0;
        const initStartTime = new Date().getTime();

        log.debug(`[EwsHelper] processAsyncUntilSuccess() starting...`);

        const callback = (ewsResult: any) => {
            if (ewsResult.status === Office.AsyncResultStatus.Succeeded) {
                const parser = new DOMParser();
                //const xmlDoc = $.parseXML(ewsResult.value);
                const xmlDoc = parser.parseFromString(ewsResult.value, 'text/xml');
                try {
                    const status = xmlDoc.documentElement
                        .getElementsByTagNameNS(messagesNs, `${action}ResponseMessage`)[0]
                        .getAttribute('ResponseClass');
                    if (status === 'Success') {
                        log.debug(
                            `[EwsHelper] processAsyncUntilSuccess() after ${retryCount +
                                1} attempts and Success; Duration = ${new Date().getTime() - initStartTime}ms;`,
                        );
                        options.success(ewsResult);
                    } else {
                        retryCount += 1;
                        this.sleep(1000).then(() => {
                            Office.context.mailbox.makeEwsRequestAsync(envelope, callback);
                        });
                    }
                } catch (e) {
                    retryCount += 1;
                    this.sleep(1000).then(() => {
                        Office.context.mailbox.makeEwsRequestAsync(envelope, callback);
                    });
                }
            } else {
                retryCount += 1;
                this.sleep(1000).then(() => {
                    Office.context.mailbox.makeEwsRequestAsync(envelope, callback);
                });
            }
        };
        Office.context.mailbox.makeEwsRequestAsync(envelope, callback);
    }

    public processAsyncUntilSuccessPromise(action: string, ewsSoapRequest: string): Promise<any> {
        this.logger.debug(`[EwsHelper] processAsyncUntilSuccessPromise -> Started EWS promise request`);
        return new Promise<any>((resolve, reject) => {
            this.processAsyncUntilSuccess(action, ewsSoapRequest, { success: (ewsResult) =>  resolve(ewsResult), error: (err) => reject(err) });
        });
    }

    private sleep(ms: any) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private parseXHeadersInternal(result: DictionaryInterface<string>, xmlDoc: any) {
        const headers = xmlDoc.getElementsByTagNameNS(
            // tslint:disable-next-line:no-http-string
            'http://schemas.microsoft.com/exchange/services/2006/types',
            'InternetMessageHeader',
        );

        for (let i = 0; i < headers.length; i += 1) {
            const headerName = headers[i].getAttribute('HeaderName');
            result[headerName] = headers[i].textContent;
        }
    }

    /**
     * Return a GetItem EWS operation request for the InternetMessageHeaders and listed ExtendedProperties of the item
     * @param itemId the id of the item being requested
     * @param properties the extended properties to request
     */
    public createEwsGetInternetMessageHeadersAndExtendedPropertiesRequest(
        itemId: string,
        properties: string[],
    ): string {
        let result =
            '<GetItem xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">' +
            '<ItemShape>' +
            '<t:BaseShape>IdOnly</t:BaseShape>' +
            '<t:AdditionalProperties>' +
            '<t:FieldURI FieldURI="item:InternetMessageHeaders"/>';
        for (let i = 0; i < properties.length; i += 1) {
            result += this.createExtendedPropertyElement(properties[i]);
        }

        result +=
            '</t:AdditionalProperties>' +
            '</ItemShape>' +
            '<ItemIds><t:ItemId Id="' +
            Xml.toXmlAttributeValue(itemId) +
            '"/></ItemIds>' +
            '</GetItem>';

        return result;
    }

    public createEwsGetConversationItemsRequest(conversationId: string): string {
        //NB: the search order is Ascending -- because the subsequent parsing sets the header to the last conversation encountered
        //and we want that titus x-header to be used. Note that this is moot when subject labelling is used because changing the
        //subject create a brand new conversation and so if there are titus x-headers for each item in the conversation, they will ALL be the same.
        // tslint:disable-next-line:no-unnecessary-local-variable
        const result = `<GetConversationItems xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">
                <ItemShape>
                    <t:BaseShape>IdOnly</t:BaseShape>
                    <t:AdditionalProperties>
                        <t:FieldURI FieldURI="item:InternetMessageHeaders"/>
                        <t:FieldURI FieldURI="message:From"/>
                    </t:AdditionalProperties>
                </ItemShape>
                <FoldersToIgnore>
                    <t:DistinguishedFolderId Id="deleteditems" />
                </FoldersToIgnore>
                <SortOrder>DateOrderAscending</SortOrder>
                <Conversations>
                    <t:Conversation>
                        <t:ConversationId Id="${Xml.toXmlAttributeValue(conversationId)}"/>
                    </t:Conversation>
                </Conversations>
            </GetConversationItems>`;

        return result;
    }

    public parseParentInternetMessageIdFromConversationResponseXML(targetItemId: string, xmlDoc: any): string{
        const typesNS = 'http://schemas.microsoft.com/exchange/services/2006/types';
        const conversationNodes = xmlDoc.getElementsByTagNameNS(typesNS, 'ConversationNode');
        let parentInternetMessageId = '';
        let parentMessageIdFound = false;

        // Find the <t:ParentInternetMessageId> of the conversation containing the target item
        for (let convIndex = 0; convIndex < conversationNodes.length; convIndex += 1) {
            const convNode = conversationNodes[convIndex];
            const itemIdsInConversation = convNode.getElementsByTagNameNS(typesNS, 'ItemId');

            for (let itemIdIndex = 0; itemIdIndex < itemIdsInConversation.length; itemIdIndex += 1) {
                const idAttr = itemIdsInConversation[itemIdIndex].getAttribute('Id');

                if (idAttr === targetItemId) {
                    const parentIdElement = convNode.getElementsByTagNameNS(typesNS, 'ParentInternetMessageId');
                    if (parentIdElement != null && parentIdElement.length > 0) {
                        parentInternetMessageId = parentIdElement[0].textContent;
                        parentMessageIdFound = true;
                        break;
                    }
                }
            }
            if (parentMessageIdFound) {
                break;
            }
        }
        return parentInternetMessageId;
    }

    public parseHeadersFromConversationResponseWithItemId(targetItemId: string, response: string, headersToRequest?:string[]): DictionaryInterface<string> {
        const internetHeaders: DictionaryInterface<string> = {};
        const typesNS = 'http://schemas.microsoft.com/exchange/services/2006/types';

        const xmlDoc = this.domParser.parseFromString(response, 'text/xml');
        const conversationNodes = xmlDoc.getElementsByTagNameNS(typesNS, 'ConversationNode');
        const parentInternetMessageId = this.parseParentInternetMessageIdFromConversationResponseXML(targetItemId, xmlDoc);
        let parentMessageFound = false;

        if (parentInternetMessageId) {
            // Find the conversation node with <t:InternetMessageId> = <t:ParentInternetMessageId> from above
            for (let convIndex = 0; convIndex < conversationNodes.length; convIndex += 1) {
                const convNode = conversationNodes[convIndex];

                const internetMessageIdElement = convNode.getElementsByTagNameNS(typesNS, 'InternetMessageId');
                if (
                    internetMessageIdElement != null &&
                    internetMessageIdElement.length > 0 &&
                    internetMessageIdElement[0].textContent === parentInternetMessageId
                ) {
                    parentMessageFound = true;
                    this.parseXHeadersInternal(internetHeaders, convNode);
                }
            }
        }

        // Fallback if we can't find the parent.
        if (!parentMessageFound) {
            this.parseXHeadersInternal(internetHeaders, xmlDoc);
        }

        if (headersToRequest) {
            const result: DictionaryInterface<string> = {};
            
            headersToRequest.forEach(requestedHeader => {
                const headerValue = internetHeaders[requestedHeader];
                if (headerValue)
                    result[requestedHeader] = headerValue;
            });

            return result;
        }

        return internetHeaders
    }

    public parseParentItemIdFromConversationResponse(itemId: string, response: string):string {
        const xmlDoc = this.domParser.parseFromString(response, 'text/xml');
        const typesNs = 'http://schemas.microsoft.com/exchange/services/2006/types'

        const conversationNodes = xmlDoc.getElementsByTagNameNS(typesNs, 'ConversationNode');
        let parentInternetMessageId = this.parseParentInternetMessageIdFromConversationResponseXML(itemId, xmlDoc);
        let parentItemId = '';

        if (parentInternetMessageId) {
            for (let convIndex = 0; convIndex < conversationNodes.length; convIndex += 1) {
                const convNode = conversationNodes[convIndex];

                const internetMessageIdElement = convNode.getElementsByTagNameNS(typesNs, 'InternetMessageId');
                if (
                    internetMessageIdElement != null &&
                    internetMessageIdElement.length > 0 &&
                    internetMessageIdElement[0].textContent === parentInternetMessageId
                ) {
                    const itemIdElement = convNode.getElementsByTagNameNS(typesNs, 'ItemId')[0];
                    parentItemId = itemIdElement.getAttribute('Id');
                }
            }
        }
        return parentItemId;
    }

    public createEwsGetAttachmentsRequest(ewsId: string) {
        // tslint:disable-next-line:no-unnecessary-local-variable
        const xmlString = `<GetItem xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">
                    <ItemShape>
                    	<t:BaseShape>IdOnly</t:BaseShape>
                        <t:AdditionalProperties>
                            <t:FieldURI FieldURI="item:Attachments"/>
                        </t:AdditionalProperties>
                    </ItemShape>
                    <ItemIds>
                        <t:ItemId Id="${Xml.toXmlAttributeValue(ewsId)}"/>
                    </ItemIds>
                </GetItem>`;

        return xmlString;
    }

    public getAttachmentsAsync(itemId: string): Promise<AttachmentOffice[]> {
        return new Promise<AttachmentOffice[]>((resolve, reject) => {
            this.processAsyncUntilSuccess('GetItem', this.createEwsGetAttachmentsRequest(itemId), {
                success: ewsResult => {
                    resolve(this.parseAttachments(ewsResult));
                },
                error: state => {
                    reject(state);
                },
            });
        });
    }

    public parseAttachments(response: Office.AsyncResult<any>): AttachmentOffice[] {
        // https://msdn.microsoft.com/EN-US/library/office/dn726695(v=exchg.150).aspx#sectionSection1
        const xmlDoc = this.domParser.parseFromString(response.value, 'text/xml');
        const typesNs = 'http://schemas.microsoft.com/exchange/services/2006/types';
        const attachmentNode = xmlDoc.documentElement.getElementsByTagNameNS(typesNs, 'Attachments');

        const result = [];
        if (attachmentNode.length > 0 && attachmentNode[0].childNodes.length > 0) {
            for (let i = 0; i < attachmentNode[0].childNodes.length; i += 1) {
                const attachment = attachmentNode[0].childNodes[i];
                const nameNode = attachment.getElementsByTagNameNS(typesNs, 'Name');
                const sizeNode = attachment.getElementsByTagNameNS(typesNs, 'Size');
                let sizeKb = 0;
                if (sizeNode.length > 0) {
                    // Math.floor used to emulate C# integer division used by TMC
                    sizeKb = Math.floor(parseInt(sizeNode[0].textContent, 10) / 1024);
                    if (isNaN(sizeKb)) {
                        sizeKb = 0;
                    }
                }
                result.push(new AttachmentOffice(nameNode[0].textContent, sizeKb));
            }
        }

        return result;
    }

    /**
     * Creates an extended property element
     * @param propertyName The name of the extended property to request. E.g. the name of an x-header to load from a draft message
     * @param distinguishedPropertySetId The EWS property set ID - e.g. InternetHeaders or PublicStrings
     * @returns a string with the XML element
     */
    private createExtendedPropertyElement(
        propertyName: any,
        distinguishedPropertySetId = 'InternetHeaders',
        propertyType = 'String',
    ): string {
        return `<t:ExtendedFieldURI DistinguishedPropertySetId="${distinguishedPropertySetId}" PropertyName="${propertyName}" PropertyType="${propertyType}" />`;
    }

    public async getOriginalSender(conversationId: string): Promise<EmailUser> {
        let request;
        let ewsResult;
        let originalSenderProperties = new EmailUser('', '');
        if (conversationId) {
            request = this.createEwsGetConversationItemsRequest(conversationId);
            ewsResult = await this.processAsyncUntilSuccessPromise('GetConversationItems', request);
            originalSenderProperties = this.getMessageFromEmail(ewsResult.value);
        }
        return originalSenderProperties;
    }

    public async getMessageHeadersFromConversation(conversationId: string, headersToRequest: string[], itemId?: string): Promise<any> {
        let result: DictionaryInterface<string> = {};
        if (conversationId) {
            const request = this.createEwsGetConversationItemsRequest(conversationId);
            const ewsResult = await this.processAsyncUntilSuccessPromise('GetConversationItems', request);
            
            if (itemId)
                result = this.parseHeadersFromConversationResponseWithItemId(itemId, ewsResult.value, headersToRequest);
            
            let FoundResult = false
            for (const header of headersToRequest) {
                if(result[header]) {
                    FoundResult = true;
                    break;
                }
            }
            
            //if we have not found the headers, we could be working with a reply/forward draft from our sent items.
            //try to get parent ItemId and request the headers and extended props from that.
            if(!FoundResult && itemId) {
                const parentItemId = this.parseParentItemIdFromConversationResponse(itemId, ewsResult.value);
                if(parentItemId) {          
                    result = await this.getInternetHeaders(parentItemId, headersToRequest);
                    for (const header of headersToRequest) {
                        if(result[header]) {
                            FoundResult = true;
                            break;
                        }
                    }
                }                 
            }

            // if we dont get any result from the inReplyTo item fall back to most recent in conversation
            if(!FoundResult)
            result = this.getMessageHeaders(ewsResult.value, headersToRequest);
            
        }
        return result;
    }

    private getMessageHeaders(response: string, headersToRequest: string[]): DictionaryInterface<string> {
        const result:DictionaryInterface<string> = {};
        const headerNodes = this.parseXmlForMostRecentHeader(response, 'InternetMessageHeader');
        if(headerNodes && headerNodes.length > 0) {
            for (let i = 0; i < headerNodes.length; i += 1) {
                const headerName = headerNodes[i].getAttribute('HeaderName');
                if (headerName && headersToRequest.includes(headerName)) {
                    result[headerName] = headerNodes[i].textContent;
                }
            }
        }
        return result;
    }

    private getMessageFromEmail(response: string): EmailUser {
        const typesNS = 'http://schemas.microsoft.com/exchange/services/2006/types';
        let emailUser = new EmailUser('', '');
        const from = this.parseXmlForMostRecentHeader(response, 'From');
        if(from && from.length > 0){
            const outerHtml = this.domParser.parseFromString(from[0].outerHTML, 'text/xml');
            const emailAddress = outerHtml.getElementsByTagNameNS(typesNS, 'EmailAddress')[0].textContent;
            const emailName = outerHtml.getElementsByTagNameNS(typesNS, 'Name')[0].textContent;
            emailUser = new EmailUser(emailAddress, emailName);
        }
        return emailUser;
    }

    private parseXmlForMostRecentHeader(response: string, element: string): any {
        const typesNS = 'http://schemas.microsoft.com/exchange/services/2006/types';
        const xmlDoc = this.domParser.parseFromString(response, 'text/xml');
        let result: any = {};
        let internetMessageHeaders = xmlDoc.getElementsByTagName("t:InternetMessageHeaders");
        if (internetMessageHeaders.length > 0){
            let mostRecentHeaders = internetMessageHeaders[internetMessageHeaders.length-1];
            result = mostRecentHeaders.getElementsByTagNameNS(typesNS, element);
        }
        return result;
    } 
}
