import React from 'react';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';

type PaneTitleProps = {
    title: string;
    themeColor: string;
};

const PaneTitle = (props: PaneTitleProps) => {
    const { title, themeColor } = props;
    return (
        <div
            id="panel"
            style={{
                backgroundColor: themeColor,
                color: NeutralColors.white,
                fontSize: FontSizes.size16,
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '16px',
                fontWeight: 600,
                marginLeft: '-8px',
                marginRight: '-8px',
            }}
        >
            {title}
        </div>
    );
};

export default PaneTitle;
