export default class PolicyRequirementFilter {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private policyRequirementsSettings: any | undefined;

    public constructor(policyEventRequirements: unknown) {
        this.policyRequirementsSettings = policyEventRequirements;
    }

    public filterObject(object: unknown): Record<string, unknown> {
        // Duplicate the object
        const objectCopyString = JSON.stringify(object);
        const objectCopy = JSON.parse(objectCopyString);
        this.filterJson(objectCopy);
        return objectCopy;
    }

    private isPropertyRequired(property: string): boolean {
        let isRequired = true;
        if (this.getRequiredPropertiesString().indexOf(property) === -1) {
            isRequired = false;
        }
        return isRequired;
    }

    private getRequiredPropertiesString(): string {
        return JSON.stringify(this.policyRequirementsSettings['requiredProperties']);
    }

    private filterJson(object: unknown, propertyPrefix?: string): void {
        const editedObject = object;

        if (this.isNestedObject(editedObject)) {
            if (editedObject instanceof Array) {
                const arrayLength = editedObject.length;
                for (let i = 0; i < arrayLength; i += 1) {
                    const value = editedObject[i];
                    this.filterJson(value, propertyPrefix);
                }
            } else {
                const keys: string[] = Object.keys(editedObject as Record<string, unknown>);
                const { length } = keys;
                for (let index = 0; index < length; index += 1) {
                    const key = keys[index];
                    let propertyName = '';
                    if (propertyPrefix) {
                        if (key.indexOf('$') !== 0) {
                            propertyName = `${propertyPrefix}.${key}`;
                        }
                    } else {
                        propertyName = key;
                    }
                    if (!this.isPropertyRequired(propertyName)) {
                        delete (editedObject as Record<string, unknown>)[key];
                    } else if (this.isNestedObject((editedObject as Record<string, unknown>)[key])) {
                        this.filterJson((editedObject as Record<string, unknown>)[key], propertyName);
                    }
                }
            }
        }
    }

    private isNestedObject(object: unknown): boolean {
        return !(
            typeof object === 'string' ||
            object instanceof String ||
            typeof object === 'boolean' ||
            object instanceof Boolean ||
            typeof object === 'number' ||
            object instanceof Number
        );
    }
}
