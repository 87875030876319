import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { OfficeApi } from './OfficeApi';
import TraceLogger from './TraceLogger';

export default class ApplicationInsightsTraceLogger implements TraceLogger {
    private officeApi: OfficeApi;

    private timeoutDuration: number = 3 * 1000;

    private logs: { time: string; message: string }[];

    public constructor(officeApi: OfficeApi) {
        this.officeApi = officeApi;

        this.logs = [];
    }

    public addTrace = (trace: string): void => {
        const now = new Date();
        this.logs.push({
            time: now.toUTCString(),
            message: trace,
        });
    };

    public sendTrace = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const timeoutHandle = this.subscribeTimeout(reject);

            const platform = this.officeApi.getPlatformType();
            const user = this.officeApi.getSenderAddress();
            const message = {
                platform: platform,
                user: user,
                logs: this.logs,
            };
            window.appInsights.trackTrace({ message: JSON.stringify(message) });
            window.appInsights.flush(false);
            clearTimeout(timeoutHandle);
            resolve();
        });
    };

    private subscribeTimeout = (reject: (reason?: string) => void): NodeJS.Timeout => {
        const timeoutHandle = setTimeout(() => {
            reject(`Failed to send application insights logs in ${this.timeoutDuration / 1000} seconds`);
        }, this.timeoutDuration);

        return timeoutHandle;
    };
}

declare global {
    interface Window {
        appInsights: ApplicationInsights;
    }
}
