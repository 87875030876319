import React, { useEffect } from 'react';
import { ALERTS_OK_PARAM } from '../../store/alerts/types';
import PaneTitle from '../shared/PaneTitle';
import { AppState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import AlertsCommandBarOutlook from './AlertsCommandBarOutlook';
import { DialogMessages } from 'titus-ts/dist/js/Util/Policy/PolicyEvaluationBaseState';
import { AnimationStyles, mergeStyles } from '@fluentui/react/lib/Styling';
import { useQuery } from '../shared/UrlUtilities';
import { Icon } from '@fluentui/react';
import {
    loadFailOpenCloseAlert as loadAlertsAction,
    completeAlerts as completeAlertsAction,
} from '../../store/alerts/actions';
import { ApplicationSettings } from 'titus-ts/dist/js/Settings/ApplicationSettings';

type AlertsDispatchProps = {
    loadAlerts: () => Promise<void>;
    completeAlerts: (message: DialogMessages) => Promise<void>;
};

type AlertsProps = {
    themeColor: string;
    appSettings: ApplicationSettings | null;
};

export const FailOpenCloseAlert = (props: AlertsProps & AlertsDispatchProps) => {
    const { loadAlerts, completeAlerts, themeColor, appSettings } = props;

    useEffect(() => {
        loadAlerts();
    }, [loadAlerts]);
    const query = useQuery();
    const enableOk = query.get(ALERTS_OK_PARAM) === 'true';
    const mode = query.get('mode') === 'WARN' ? 'WARN' : 'PREVENT';
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    height: 'calc(100% - 120px)',
                    overflowX: 'hidden',
                }}
            >
                <PaneTitle title="Classification Service Error" themeColor={themeColor} />
                <div style={{ display: 'flex', marginTop: '8px', marginBottom: '8px' }}>
                    <div style={{ width: '32px', marginTop: '8px' }}>
                        <Icon iconName="Error" style={{ fontSize: '24px', color: 'red' }} />
                    </div>
                    <div style={{ flex: 1, marginTop: '8px' }}>
                        Classification cannot be applied due to an error. The authentication for the Titus
                        Classification Service may not have been successful. Please contact your administrator for more
                        detail.
                    </div>
                </div>
            </div>

            <div
                className={mergeStyles(AnimationStyles.fadeIn100, {
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    height: '50px',
                    display: 'inherit',
                    justifyContent: 'flex-end',
                })}
            >
                <AlertsCommandBarOutlook
                    mode={mode}
                    canRemediateAll={false}
                    completeAlerts={completeAlerts}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    remediateAll={() => {
                        // RemediateAll not present in this dialog
                    }}
                    remediated={false}
                    enableOk={enableOk}
                    appSettings={appSettings}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState): AlertsProps => ({
    themeColor: state.alertsReducer.themeColor,
    appSettings: state.alertsReducer.applicationSettings,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, undefined, AnyAction>) => ({
    loadAlerts: () => dispatch(loadAlertsAction()),
    completeAlerts: (message: DialogMessages) => dispatch(completeAlertsAction(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FailOpenCloseAlert);
