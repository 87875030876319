import React, { useState } from 'react';
import Field from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/Field';
import { ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import { ILabelStyles } from '@fluentui/react/lib/Label';

type TextboxValuePickerProps = {
    field: Field;
    onChange: (fieldName: string, fieldValue: string) => void;
};

const isValueValid = (
    newValue: string,
    maxValueLength = 255,
    invalidCharacters: string[] = [';', ',', '='],
): boolean => {
    if (newValue.length > maxValueLength) {
        return false;
    } else if (stringContainsCharacterFromList(newValue, invalidCharacters)) {
        return false;
    }
    return true;
};

const stringContainsCharacterFromList = (searchString: string, characters: string[]): boolean => {
    for (const character of characters) {
        if (searchString.includes(character)) {
            return true;
        }
    }

    return false;
};

const labelStyles: Partial<ILabelStyles> = {
    root: {
        fontWeight: 'normal',
    },
};

const textFieldStyles: Partial<ITextFieldStyles> = {
    subComponentStyles: {
        label: labelStyles,
    },
};

//Render Function
const ValuePickerTextbox = (props: TextboxValuePickerProps) => {
    const { field, onChange } = props;
    const initialFieldValue = field.selectedValue && field.selectedValue.length > 0 ? field.selectedValue[0] : '';
    const [fieldValue, setFieldValue] = useState(initialFieldValue);

    const _onChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        newValue = newValue ? (newValue as string) : '';

        if (isValueValid(newValue)) {
            setFieldValue(newValue);
        }
    };
    const _onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        const newValue = event.currentTarget.value ? (event.currentTarget.value as string) : '';

        if (isValueValid(newValue)) {
            onChange(field.name, newValue ? (newValue as string) : '');
        }
    };

    return (
        <div data-testid="textbox_valuepicker">
            <TextField value={fieldValue} styles={textFieldStyles} onChange={_onChange} onBlur={_onBlur} />
        </div>
    );
};

export default ValuePickerTextbox;
