import { localSettingsService } from './localSettingsService';

export default class ServiceConstantsProvider {
    public static getClientId(): string {
        return localSettingsService.getLocalSettings().clientId;
    }

    public static getHostName = (): string => {
        let hostUrl: string;
        if (localSettingsService.getLocalSettings().useLocalHostUrl) {
            hostUrl = ServiceConstantsProvider.getOriginBaseUrl().replace('oa-', '');
        } else {
            hostUrl = localSettingsService.getLocalSettings().hostUrl;
        }

        return hostUrl;
    };

    public static getAuthUrl = (): string => {
        return ServiceConstantsProvider.getOriginBaseUrl() + '/auth.html';
    };

    public static getAlertsWithDisabledOkUrl = (): string => {
        return ServiceConstantsProvider.getOriginBaseUrl() + '/alerts?enableOk=false';
    };

    public static getAlertsWithEnabledOkUrl = (): string => {
        return ServiceConstantsProvider.getOriginBaseUrl() + '/alerts?enableOk=true';
    };

    public static getFailOpenCloseAlertWithEnabledOkUrl = (): string => {
        return ServiceConstantsProvider.getOriginBaseUrl() + '/failOpenCloseAlert?enableOk=true&&mode=';
    };

    public static getForceClassificationUrl = (): string => {
        return ServiceConstantsProvider.getOriginBaseUrl() + '/select-classification?mode=force';
    };

    public static getSuggestClassificationUrl = (): string => {
        return ServiceConstantsProvider.getOriginBaseUrl() + '/select-classification?mode=suggest';
    };

    public static getLicensingValidationUrl = (productName: string): string => {
        return `${ServiceConstantsProvider.getHostName()}/tenantinformation/api/TitusLicense/authorize?product=${productName}`;
    };

    private static getOriginBaseUrl = (): string => {
        return window.location.origin;
    };
}
