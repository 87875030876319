import BodyTagHandler, { BodyTagPositionFilter } from 'titus-ts/dist/js/Util/Actions/BodyTagHandler';
import ActionHandler from 'titus-ts/dist/js/Util/Actions/ActionHandler';
import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { OfficeApi } from '../OfficeApi';
import FieldCodeService from 'titus-ts/dist/js/Util/Actions/FieldCodeService';

export default class OfficeBodyTaggingHandler implements ActionHandler {
    private officeApi: OfficeApi;
    private bodyTagHandler: BodyTagHandler;
    private fieldCodeService: FieldCodeService;

    constructor(officeApi: OfficeApi, fieldCodeService: FieldCodeService, bodyTagHandler?: BodyTagHandler) {
        this.officeApi = officeApi;
        this.fieldCodeService = fieldCodeService;
        this.bodyTagHandler = bodyTagHandler || new BodyTagHandler();
    }

    public handle = async (actions: Action[]): Promise<void> => {
        const updatedActions = this.fieldCodeService.process(actions, 'BodyTag');
        const platform = this.officeApi.getPlatformType();
        const bodyTypeResponse = await this.officeApi.getBodyTypeAsync();
        const bodyType = bodyTypeResponse === 'html' ? 'html' : 'text';
        let existingBody = '';
        const positionFilter: BodyTagPositionFilter = {
            before: [],
            after: [],
        };
        this.bodyTagHandler.getModifiedBody(existingBody, updatedActions, bodyType, positionFilter);

        if (bodyType === 'html') {
            existingBody = await this.officeApi.getBodyAsHtmlAsync();
            //Both Outlook Desktop and Web client differently mark replied message start. This is to find that beggining and insert tag in front if needed.
            if (platform === 'PC') {
                positionFilter.after.push(`<div style='border:none;border-left:solid blue 1.5pt;padding:`);
                positionFilter.after.push(`<div style='border:none;border-top:solid #e1e1e1 1.0pt;padding:`);
                positionFilter.after.push(`<div style='border:none;border-top:solid #E1E1E1 1.0pt;padding:`);
            } else {
                positionFilter.after.push(`<div id="appendonsend">`);
                positionFilter.after.push(`<hr tabindex="-1" style="display:inline-block; width:98%">
                <div id="divrplyfwdmsg" dir="ltr">`);
                positionFilter.after.push(`<div id="divRplyFwdMsg"`);
            }
        } else {
            existingBody = await this.officeApi.getBodyAsTextAsync();
        }

        const modifiedBody = this.bodyTagHandler.getModifiedBody(
            existingBody,
            updatedActions,
            bodyType === 'html' ? 'html' : 'text',
            positionFilter,
        );
        const maxSetBodyLength = 1000000; // Maximum length accepted by Body.setAsync

        if (modifiedBody.length < maxSetBodyLength) {
            await this.officeApi.setBodyAsync(modifiedBody, bodyType);
        }
    };
}
