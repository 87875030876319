import React from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';

export const ERROR = 'Error';
export const WARNING = 'Warning';
export const MESSAGE = 'Message';

export type ApplicationMessageTypes = typeof ERROR | typeof WARNING | typeof MESSAGE;

type ApplicationMessageProps = {
    message: string;
    type: ApplicationMessageTypes;
    onDismiss?: () => void;
};

const ApplicationMessage = (props: ApplicationMessageProps) => {
    const { message, type, onDismiss } = props;
    let messageType = MessageBarType.info;
    if (type === ERROR) {
        messageType = MessageBarType.error;
    } else if (type === WARNING) {
        messageType = MessageBarType.warning;
    }

    return (
        <MessageBar messageBarType={messageType} isMultiline={true} onDismiss={onDismiss}>
            {message}
        </MessageBar>
    );
};

export default ApplicationMessage;
