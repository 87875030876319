import ActionHandler from 'titus-ts/dist/js/Util/Actions/ActionHandler';
import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { OfficeApi } from '../OfficeApi';
import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import FieldCodeService from 'titus-ts/dist/js/Util/Actions/FieldCodeService';

export default class OfficeCustomDocPropertyHandler implements ActionHandler {
    private officeApi: OfficeApi;
    private fieldCodeService: FieldCodeService;

    constructor(officeApi: OfficeApi, fieldCodeService: FieldCodeService) {
        this.officeApi = officeApi;
        this.fieldCodeService = fieldCodeService;
    }

    public handle = async (actions: Action[]): Promise<void> => {
        const docPropValues: DictionaryInterface<string | string[]> = {};
        const docPropNames: string[] = [];
        const updatedActions = this.fieldCodeService.process(actions, 'SetCustomProperties');

        updatedActions.forEach((action): void => {
            const propName = action.params.get('PropertyName');
            const propValue = action.params.get('PropertyValue');
            if (propName && propValue) {
                docPropNames.push(propName);
                docPropValues[propName] = propValue;
            }
        });
        await this.officeApi.setCustomProperties(docPropValues, docPropNames);
    };
}
