import React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { AlertsCommandBarPropsDocument, stackTokens } from './AlertsCommandBarProps';

const AlertsCommandBarDocument = (props: AlertsCommandBarPropsDocument) => {
    const { completeAlerts, okButtonText } = props;
    return (
        <div style={{ marginBottom: '2px' }}>
            <Stack horizontal tokens={stackTokens}>
                <PrimaryButton text={okButtonText || 'OK'} onClick={() => completeAlerts('DialogOk')} />
            </Stack>
        </div>
    );
};

export default AlertsCommandBarDocument;
