import React from 'react';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

type ReadFieldProps = {
    name: string;
    value: string;
    valueTooltip?: string;
    fieldTooltip?: string;
};

const ReadField = (props: ReadFieldProps) => {
    const { name, value, valueTooltip, fieldTooltip } = props;
    return (
        <div>
            <div style={{ fontSize: FontSizes.size14, color: NeutralColors.gray80 }}>
                <TooltipHost content={fieldTooltip}>{name}</TooltipHost>
            </div>

            <div style={{ fontSize: FontSizes.size14, marginLeft: '12px', marginBottom: '32px' }}>
                <TooltipHost content={valueTooltip}>{value}</TooltipHost>
            </div>
        </div>
    );
};

export default ReadField;
