import { StorageKeys } from './storageTypes';

// Per https://docs.microsoft.com/en-us/office/dev/add-ins/develop/dialog-api-in-office-add-ins
// we have to use local storage to pass/store information between office dialogs and main application
export class LocalStorageService {
    public static save = <T>(item: T, key: StorageKeys): void => {
        const itemJson = JSON.stringify(item);
        localStorage.setItem(key, itemJson);
    };

    public static load = <T>(key: StorageKeys): T => {
        const itemJson = localStorage.getItem(key);
        if (itemJson) {
            const item = JSON.parse(itemJson) as T;
            return item;
        } else {
            throw new Error(`Requested key ${key} not found in local storage.`);
        }
    };
}
