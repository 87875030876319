import DOMPurify from 'dompurify';

export default class HtmlSanitizer {
    private static customPolicy = {
        FORBID_TAGS: ['button', 'input', 'textarea', 'select', 'frame', 'form', 'applet', 'embed', 'object', 'script'],
        FORBID_ATTR: ['src'],
        ALLOWED_ATTR: ['class', 'href', 'style'],
        ADD_ATTR: ['target'],
    };
    private static allowedStyles = ['color', 'font-family', 'text-align'];
    //remove existing hooks and add a custom hook to sanitize the style attribute.
    private static RefreshHooks() {
        DOMPurify.removeAllHooks();
        DOMPurify.addHook('afterSanitizeAttributes', (node) => {
            //removes all properties from the style attribute not listed in allowedStyles
            const styleAttribute = node.getAttribute('style');
            if (styleAttribute) {
                const sanitizedStyle = styleAttribute
                    .split(';')
                    .filter((style) => {
                        const [styleProperty] = style.split(':');
                        return HtmlSanitizer.allowedStyles.includes(styleProperty.trim().toLocaleLowerCase());
                    })
                    .join(';');
                node.setAttribute('style', sanitizedStyle);
            }
        });
    }

    static SanitizeHtml(rawHtml: string): string {
        this.RefreshHooks();
        const santizedHtml = DOMPurify.sanitize(rawHtml, HtmlSanitizer.customPolicy);
        return santizedHtml;
    }
}
