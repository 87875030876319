import React from 'react';
import { ALERT_MODE } from '../../store/alerts/types';
import { Icon } from '@fluentui/react/lib/Icon';

type AlertsDialogDescriptionProps = {
    mode: ALERT_MODE | undefined;
    dialogSummaryText?: string;
};

const AlertsDialogDescription = (props: AlertsDialogDescriptionProps) => {
    const { mode, dialogSummaryText } = props;
    return (
        <div data-testid="alertsdialogdescription" style={{ display: 'flex', marginTop: '8px', marginBottom: '8px' }}>
            <div style={{ width: '32px', marginTop: '8px' }}>
                {mode === 'PREVENT' && <Icon iconName="Error" style={{ fontSize: '24px', color: 'red' }} />}
                {mode === 'WARN' && <Icon iconName="Warning" style={{ fontSize: '24px', color: 'orange' }} />}
            </div>
            <div style={{ flex: 1, marginTop: '8px' }}>
                {dialogSummaryText ??
                    'One or more alerts have been raised for this message. For more details, please select an item from the list below.'}
            </div>
        </div>
    );
};

export default AlertsDialogDescription;
