import { MetadataObject } from 'titus-ts/dist/js/Util/Metadata/MetadataObject';
import { OfficeApi } from '../office/OfficeApi';
import { EwsRequestGenerator } from '../office/EwsRequestGenerator';
import { OutlookApi } from '../office/OutlookApi';
import { AuthService } from './authService';
import { AppSettingsService } from './appSettingsService';
import APIFactory from 'titus-ts/dist/js/Util/Network/APIFactory';
import ServiceConstantsProvider from './serviceConstantsProvider';
import ConfigurationManager from 'titus-ts/dist/js/MetadataRenderer/Configuration/ConfigurationManager';
import SchemaViewCache from 'titus-ts/dist/js/MetadataRenderer/Configuration/SchemaViewCache';
import MetadataService from './metadataService';
import { LocalStorageService } from './storage/localStorageService';
import PolicyEvaluationService from 'titus-ts/dist/js/Util/Policy/PolicyEvaluationService';
import OfficeActionHandlerFactory from '../office/action-handlers/OfficeActionHandlerFactory';
import { PolicyService } from './policyService';
import ClassificationPresenter from 'titus-ts/dist/js/MetadataRenderer/MetadataPresenter/ClassificationPresenter';
import { RenderingService } from './renderingService';
import { ConfigurationServiceApi } from 'titus-ts/dist/js/Util/Network/CloudServices/ConfigurationServiceApi';
import { PolicyEvaluationApi } from 'titus-ts/dist/js/Util/Network/PolicyServices/PolicyEvaluationApi';
import { PolicyServiceApi } from 'titus-ts/dist/js/Util/Network/PolicyServices/PolicyServiceApi';
import SchemaProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaProvider';
import SchemaViewProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaViewProvider';
import DialogService from './dialogService';
import RemediationService from './remediationService';
import OfficeValidationFactory from '../office/validation/OfficeValidationFactory';
import FieldCodeService from 'titus-ts/dist/js/Util/Actions/FieldCodeService';
import { appService } from './appService';
import MetadataServiceFactory from './metadataServiceFactory';
import TraceLogger from '../office/TraceLogger';
import ApplicationInsightsTraceLogger from '../office/ApplicationInsightsTraceLogger';
import PolicyRequirementsFactory from '../office/policy/PolicyRequirementsFactory';
import PolicyRequirementsProvider from 'titus-ts/dist/js/Util/Network/PolicyServices/PolicyRequirementsProvider';
import { GuidService } from './guidService';
import { FailOpenCloseService } from './failOpenCloseService';
import { TimeOutService } from './timeOutService';
import { TitusAuth, TitusAuthFactory } from 'titus-auth';
import { localSettingsService } from './localSettingsService';

export class Container {
    // eslint-disable-next-line @typescript-eslint/ban-types
    private container: Map<string, Object> = new Map<string, Object>();

    public constructor(authService?: AuthService, officeApi?: OfficeApi, guidService?: GuidService) {
        const outlookApi = new OutlookApi();
        const ewsRequestGenerator = new EwsRequestGenerator();
        this.container.set('OutlookApi', outlookApi);
        this.container.set('EwsRequestGenerator', ewsRequestGenerator);
        if (officeApi) {
            this.container.set('OfficeApi', officeApi);
        } else {
            this.container.set('OfficeApi', new OfficeApi(outlookApi, ewsRequestGenerator));
        }

        const containerOfficeApi = this.getService<OfficeApi>('OfficeApi');

        const traceLogger = new ApplicationInsightsTraceLogger(containerOfficeApi);
        this.container.set('TraceLogger', traceLogger);

        this.container.set('GuidService', guidService || new GuidService(containerOfficeApi));

        const dialogService = new DialogService(containerOfficeApi);
        const timeOutService = new TimeOutService(dialogService);
        const failOpenCloseService = new FailOpenCloseService(timeOutService, dialogService);
        this.container.set('FailOpenCloseService', failOpenCloseService);

        if (authService) {
            this.container.set('Auth', authService);
        }
    }

    public initialize = async (applicationMode: ApplicationMode): Promise<void> => {
        const productName = appService.getProductName();
        const authService = this.initializeAuthService();
        const titusToken = await authService.getTitusToken();

        const officeApi = this.getService<OfficeApi>('OfficeApi');

        const configurationServiceApi = APIFactory.getConfigurationServiceApi(
            titusToken,
            ServiceConstantsProvider.getHostName(),
            productName,
            'correlationId',
        );
        const applicationSettingsService = new AppSettingsService(configurationServiceApi);
        const schemaViewCache = new SchemaViewCache(60);
        const configurationManager = new ConfigurationManager(schemaViewCache, configurationServiceApi);
        const schemaView = await configurationManager.getSchemaView(productName);
        const schemaViewProvider = new SchemaViewProvider(schemaView);
        const schemaTabsProvider = schemaViewProvider;
        const guidService = this.getService<GuidService>('GuidService');
        const metadataServiceFactory = new MetadataServiceFactory(officeApi, schemaViewProvider, guidService);
        const metadataService = metadataServiceFactory.getMetadataServiceInstance();
        let metadata: MetadataObject | undefined = undefined;
        let currentMetadata: MetadataObject | undefined = undefined;
        switch (applicationMode) {
            case 'EDIT_MODE':
                await guidService.writeTitusGuid();
                metadata = await metadataService.getOriginalMetadata();
                currentMetadata = await metadataService.getCurrentMetadata(true);
                break;
            case 'ONSEND_MODE':
                metadata = await metadataService.getOriginalMetadata();
                currentMetadata = await metadataService.getCurrentMetadata(true);
                break;
            case 'READ_MODE':
                metadata = await metadataService.getCurrentMetadata();
                break;
            case 'DIALOG_MODE':
            default:
                metadata = LocalStorageService.load<MetadataObject>('POLICY_DIALOG_ORIGINAL_METADATA');
                currentMetadata = LocalStorageService.load<MetadataObject>('POLICY_DIALOG_CURRENT_METADATA');
                break;
        }
        const schemaProvider = new SchemaProvider(schemaViewProvider, schemaTabsProvider, metadata);
        if (currentMetadata) {
            schemaProvider.select(currentMetadata);
        }
        const localStorageService = new LocalStorageService();
        const policyEvaluationApi = APIFactory.getPolicyEvaluationApi(
            titusToken,
            ServiceConstantsProvider.getHostName(),
            productName,
            'correlationId',
        );
        const policyServiceApi = APIFactory.getPolicyServiceApi(
            titusToken,
            ServiceConstantsProvider.getHostName(),
            productName,
            'correlationId',
        );
        const policyRequirementsFactory = new PolicyRequirementsFactory(
            officeApi,
            schemaProvider,
            policyServiceApi,
            schemaViewProvider,
        );
        const policyRequirementsProvider = policyRequirementsFactory.getPolicyRequirementsProvider();
        const officeValidationFactory = new OfficeValidationFactory(officeApi);
        const fieldCodeService = new FieldCodeService(schemaProvider);
        const officeActionHandlerFactory = new OfficeActionHandlerFactory(officeApi, fieldCodeService);
        const eventName = appService.getEventName(productName, applicationMode);
        const policyEvaluationService = new PolicyEvaluationService(
            eventName,
            policyEvaluationApi,
            schemaProvider,
            policyRequirementsProvider,
            officeActionHandlerFactory,
            officeValidationFactory,
        );
        const remediationService = new RemediationService(officeActionHandlerFactory);
        const dialogService = new DialogService(officeApi, remediationService, schemaProvider);
        const timeOutService = new TimeOutService(dialogService);
        const policyService = new PolicyService(timeOutService, policyEvaluationService, dialogService);
        const classificationPresenter = new ClassificationPresenter(schemaProvider);
        const renderingService = new RenderingService(classificationPresenter);
        const failOpenCloseService = new FailOpenCloseService(
            timeOutService,
            dialogService,
            applicationSettingsService,
        );

        this.container.set('OfficeApi', officeApi);
        this.container.set('ConfigurationServiceApi', configurationServiceApi);
        this.container.set('ApplicationSettings', applicationSettingsService);
        this.container.set('ConfigurationManager', configurationManager);
        this.container.set('Metadata', metadataService);
        this.container.set('LocalStorage', localStorageService);
        this.container.set('PolicyEvaluationApi', policyEvaluationApi);
        this.container.set('PolicyRequirementsProvider', policyRequirementsProvider);
        this.container.set('OfficeActionHandlerFactory', officeActionHandlerFactory);
        this.container.set('PolicyEvaluation', policyEvaluationService);
        this.container.set('PolicyServiceApi', policyServiceApi);
        this.container.set('Policy', policyService);
        this.container.set('ClassificationPresenter', classificationPresenter);
        this.container.set('RenderingService', renderingService);
        this.container.set('Dialog', dialogService);
        this.container.set('Remediation', remediationService);
        this.container.set('SchemaProvider', schemaProvider);
        this.container.set('FailOpenCloseService', failOpenCloseService);
        this.container.set('FieldCodeService', fieldCodeService);
    };

    public registerAppSettings = async (): Promise<void> => {
        const productName = appService.getProductName();
        const authService = this.initializeAuthService();
        const titusToken = await authService.getTitusToken();

        const configurationServiceApi = APIFactory.getConfigurationServiceApi(
            titusToken,
            ServiceConstantsProvider.getHostName(),
            productName,
            'correlationId',
        );
        const applicationSettingsService = new AppSettingsService(configurationServiceApi);

        this.container.set('ApplicationSettings', applicationSettingsService);
    };

    public getService<T>(key: SERVICE_TYPES): T {
        const service = this.container.get(key);
        if (service) {
            return service as T;
        } else {
            throw new Error('Requested service type is not registered within container');
        }
    }

    public registerService<T extends object>(key: SERVICE_TYPES, service: T): void {
        this.container.set(key, service);
    }

    public getAllServices(): ApplicationServices {
        return {
            officeApi: this.getService<OfficeApi>('OfficeApi'),
            ewsRequestGenerator: this.getService<EwsRequestGenerator>('EwsRequestGenerator'),
            outlookApi: this.getService<OutlookApi>('OutlookApi'),
            configurationServiceApi: this.getService<ConfigurationServiceApi>('ConfigurationServiceApi'),
            applicationSettingsService: this.getService<AppSettingsService>('ApplicationSettings'),
            configurationManager: this.getService<ConfigurationManager>('ConfigurationManager'),
            metadataService: this.getService<MetadataService>('Metadata'),
            localStorageService: this.getService<LocalStorageService>('LocalStorage'),
            policyEvaluationApi: this.getService<PolicyEvaluationApi>('PolicyEvaluationApi'),
            policyRequirementsProvider: this.getService<PolicyRequirementsProvider>('PolicyRequirementsProvider'),
            actionHandlerFactory: this.getService<OfficeActionHandlerFactory>('OfficeActionHandlerFactory'),
            policyEvaluation: this.getService<PolicyEvaluationService>('PolicyEvaluation'),
            policyServiceApi: this.getService<PolicyServiceApi>('PolicyServiceApi'),
            policyService: this.getService<PolicyService>('Policy'),
            classificationPresenter: this.getService<ClassificationPresenter>('ClassificationPresenter'),
            renderingService: this.getService<RenderingService>('RenderingService'),
            dialogService: this.getService<DialogService>('Dialog'),
            remediationService: this.getService<RemediationService>('Remediation'),
            schemaProvider: this.getService<SchemaProvider>('SchemaProvider'),
            traceLogger: this.getService<TraceLogger>('TraceLogger'),
            guidService: this.getService<GuidService>('GuidService'),
            failOpenCloseService: this.getService<FailOpenCloseService>('FailOpenCloseService'),
            fieldCodeService: this.getService<FieldCodeService>('FieldCodeService'),
        };
    }

    public getPreloadServices(): PreloadApplicationServices {
        const outlookApi = new OutlookApi();
        const ewsRequestGenerator = new EwsRequestGenerator();
        const officeApi = new OfficeApi(outlookApi, ewsRequestGenerator);
        const traceLogger = this.getService<TraceLogger>('TraceLogger');
        const schemaView = {
            schemaFields: [],
            description: '',
            name: '',
            tabConfiguration: {
                enabled: false,
                tabPositions: {},
            },
        };

        const schemaViewProvider = new SchemaViewProvider(schemaView);
        const guidService = new GuidService(officeApi);
        const metadataServiceFactory = new MetadataServiceFactory(officeApi, schemaViewProvider, guidService);
        const metadataService = metadataServiceFactory.getMetadataServiceInstance();

        this.container.set('OfficeApi', officeApi);
        this.container.set('EwsRequestGenerator', ewsRequestGenerator);
        this.container.set('OutlookApi', outlookApi);
        this.container.set('Metadata', metadataService);
        this.container.set('TraceLogger', traceLogger);
        return {
            officeApi: officeApi,
            ewsRequestGenerator: ewsRequestGenerator,
            outlookApi: outlookApi,
            metadataService: metadataService,
            traceLogger: traceLogger,
        };
    }

    private initializeAuthService(): AuthService {
        let authService: AuthService;
        try {
            authService = this.getService<AuthService>('Auth');
        } catch {
            const productName = appService.getProductName();
            const authFactory = new TitusAuthFactory({
                providersConfiguration: {
                    googleConfiguration: {
                        clientId: '',
                        scopes: [''],
                        redirectUri: '',
                    },
                    officeConfiguration: {
                        clientId: localSettingsService.getLocalSettings().clientId,
                        scopes: ['openid', 'profile', 'User.Read'],
                        redirectUri: ServiceConstantsProvider.getAuthUrl(),
                    },
                },
                landingPageUri: '',
                licensingValidationUrl: ServiceConstantsProvider.getLicensingValidationUrl(productName),
                isStoreUserInfo: false,
            });

            const titusAuth = new TitusAuth(authFactory);
            authService = new AuthService(titusAuth);
            this.container.set('Auth', authService);
        }

        return authService;
    }
}

export type ApplicationServices = {
    officeApi: OfficeApi;
    ewsRequestGenerator: EwsRequestGenerator;
    outlookApi: OutlookApi;
    configurationServiceApi: ConfigurationServiceApi;
    applicationSettingsService: AppSettingsService;
    configurationManager: ConfigurationManager;
    metadataService: MetadataService;
    localStorageService: LocalStorageService;
    policyEvaluationApi: PolicyEvaluationApi;
    policyRequirementsProvider: PolicyRequirementsProvider;
    actionHandlerFactory: OfficeActionHandlerFactory;
    policyEvaluation: PolicyEvaluationService;
    policyServiceApi: PolicyServiceApi;
    policyService: PolicyService;
    classificationPresenter: ClassificationPresenter;
    renderingService: RenderingService;
    dialogService: DialogService;
    remediationService: RemediationService;
    schemaProvider: SchemaProvider;
    traceLogger: TraceLogger;
    guidService: GuidService;
    failOpenCloseService: FailOpenCloseService;
    fieldCodeService: FieldCodeService;
};

export type PreloadApplicationServices = {
    officeApi: OfficeApi;
    ewsRequestGenerator: EwsRequestGenerator;
    outlookApi: OutlookApi;
    metadataService: MetadataService;
    traceLogger: TraceLogger;
};

export type OFFICE_API = 'OfficeApi';
export type OUTLOOK_API = 'OutlookApi';
export type EWS_REQUEST_GENERATOR = 'EwsRequestGenerator';
export type APPLICATION_SETTINGS = 'ApplicationSettings';
export type AUTH = 'Auth';
export type CONFIGURATION_MANAGER = 'ConfigurationManager';
export type METADATA = 'Metadata';
export type LOCAL_STORAGE = 'LocalStorage';
export type OFFICE_POLICY_REQUIREMENTS_PROVIDER = 'PolicyRequirementsProvider';
export type OFFICE_ACTION_HANDLER_FACTORY = 'OfficeActionHandlerFactory';
export type POLICY_EVALUATION = 'PolicyEvaluation';
export type POLICY_EVALUATION_API = 'PolicyEvaluationApi';
export type POLICY_SERVICE_API = 'PolicyServiceApi';
export type CLASSIFICATION_PRESENTER = 'ClassificationPresenter';
export type RENDERING_SERVICE = 'RenderingService';
export type CONFIGURATION_SERVICE_API = 'ConfigurationServiceApi';
export type POLICY = 'Policy';
export type DIALOG_SERVICE = 'Dialog';
export type REMEDIATION_SERVICE = 'Remediation';
export type SCHEMA_PROVIDER = 'SchemaProvider';
export type TRACE_LOGGER = 'TraceLogger';
export type GUID_SERVICE = 'GuidService';
export type FAIL_OPEN_CLOSE_SERVICE = 'FailOpenCloseService';
export type FIELD_CODE_SERVICE = 'FieldCodeService';

export type SERVICE_TYPES =
    | OFFICE_API
    | OUTLOOK_API
    | EWS_REQUEST_GENERATOR
    | APPLICATION_SETTINGS
    | AUTH
    | CONFIGURATION_MANAGER
    | METADATA
    | LOCAL_STORAGE
    | OFFICE_POLICY_REQUIREMENTS_PROVIDER
    | OFFICE_ACTION_HANDLER_FACTORY
    | POLICY_EVALUATION
    | POLICY_EVALUATION_API
    | POLICY_SERVICE_API
    | CLASSIFICATION_PRESENTER
    | RENDERING_SERVICE
    | CONFIGURATION_SERVICE_API
    | POLICY
    | DIALOG_SERVICE
    | REMEDIATION_SERVICE
    | SCHEMA_PROVIDER
    | TRACE_LOGGER
    | GUID_SERVICE
    | FAIL_OPEN_CLOSE_SERVICE
    | FIELD_CODE_SERVICE;

export type EDIT_MODE = 'EDIT_MODE';
export type READ_MODE = 'READ_MODE';
export type ONSEND_MODE = 'ONSEND_MODE';
export type DIALOG_MODE = 'DIALOG_MODE';

export type ApplicationMode = EDIT_MODE | READ_MODE | ONSEND_MODE | DIALOG_MODE;
const serviceContainer = new Container();
export default serviceContainer;
