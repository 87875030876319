import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router';
import ReadPane from './components/read-pane/ReadPane';
import SelectClassification from './components/select-classification/SelectClassification';
import Alerts from './components/alerts/Alerts';
import { appService } from './services/appService';
import Disabled from './components/disabled/Disabled';
import FailOpenCloseAlert from './components/alerts/FailOpenCloseAlert';

const App = () => {
    const isPlatformEnabled = appService.isPlatformEnabled();
    return (
        <div>
            <Routes>
                <Route path="/read" element={isPlatformEnabled ? <ReadPane /> : <Disabled />} />
                <Route
                    path="/select-classification"
                    element={isPlatformEnabled ? <SelectClassification /> : <Disabled />}
                />
                <Route path="/alerts" element={<Alerts />} />
                <Route path="/failOpenCloseAlert" element={<FailOpenCloseAlert />} />
            </Routes>
        </div>
    );
};

export default App;
