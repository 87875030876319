export class DynamicScriptService {
    //whitelist - only for officejs right now but we can expand.
    private static allowedScripts: string[] = [
        'https://appsforoffice.microsoft.com/lib/1/hosted/office.js',
        '/officejsfiles/office.js',
        'https://appsforoffice.gcch.cdn.office.net/appsforoffice/lib/1/hosted/office.js',
        'https://appsforoffice.dod.cdn.office.net/appsforoffice/lib/1/hosted/office.js',
    ];

    static isValidScriptUrl(scriptName: string): boolean {
        if (this.allowedScripts.includes(scriptName)) {
            return true;
        } else {
            const relativeUrl = new URL(scriptName);
            return this.allowedScripts.includes(relativeUrl.pathname) && relativeUrl.origin === window.location.origin;
        }
    }
    //load script from specified location (with optional SRI hash)
    static loadExternalResource(src: string, integrity: string | undefined): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.isValidScriptUrl(src)) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = src;

                // If an integrity value is provided, add the integrity and crossOrigin attributes
                if (integrity) {
                    script.integrity = integrity;
                    script.crossOrigin = 'anonymous'; // Required for SRI checking cross-origin resources
                }
                script.onload = () => resolve();
                script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
                document.head.appendChild(script);
            } else {
                reject(new Error(`Invalid script: ${src}`));
            }
        });
    }
}
