import ClassificationPaneViewModel from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/ClassificationPaneViewModel';
import {
    ReadPaneActionTypes,
    READ_PANE_LOAD_BEGIN,
    READ_PANE_LOAD_SUCCESS,
    READ_PANE_LOAD_ERROR,
    READ_PANE_LOAD_UPDATE_COUNT,
} from './types';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AppState } from '..';
import serviceContainer from '../../services/serviceContainer';

export const readPaneLoadBegin = (): ReadPaneActionTypes => {
    return {
        type: READ_PANE_LOAD_BEGIN,
    };
};

export const readPaneLoadUpdateCount = (count: number): ReadPaneActionTypes => {
    return {
        type: READ_PANE_LOAD_UPDATE_COUNT,
        count: count,
    };
};

export const readPaneLoadSuccess = (classificationPaneViewModel: ClassificationPaneViewModel): ReadPaneActionTypes => {
    return {
        type: READ_PANE_LOAD_SUCCESS,
        classificationPaneViewModel: classificationPaneViewModel,
    };
};

export const readPaneLoadError = (error: string): ReadPaneActionTypes => {
    return {
        type: READ_PANE_LOAD_ERROR,
        error: error,
    };
};

export const readPaneLoad = (): ThunkAction<Promise<void>, AppState, undefined, AnyAction> => async (dispatch) => {
    try {
        dispatch(readPaneLoadBegin());
        const { metadataService } = serviceContainer.getPreloadServices();
        const metadata = await metadataService.getCurrentMetadata();
        let propCount = 0;
        if (metadata) {
            propCount = Object.keys(metadata).length;
        }
        dispatch(readPaneLoadUpdateCount(propCount));
        await serviceContainer.initialize('READ_MODE');
        const { renderingService, officeApi } = serviceContainer.getAllServices();
        officeApi.onItemChanged(() => {
            window.location.reload();
        });
        const viewModel = await renderingService.getOriginalRenderingData();
        dispatch(readPaneLoadSuccess(viewModel));
    } catch (err) {
        dispatch(readPaneLoadError((err as Error).message));
    }
};
