import React from 'react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import ValuePickerDropdown from './valuePickers/Dropdown';
import ValuePickerCheckbox from './valuePickers/Checkbox';
import ValuePickerTextbox from './valuePickers/Textbox';
import ValuePickerDate from './valuePickers/Date';
import Field from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/Field';
import { MetadataSelectionUi, OnlineAddinSettings } from 'titus-ts/dist/js/Settings/ApplicationSettings';
import Obligation from './Obligation';
import { ValuePickerOneClick } from './valuePickers/OneClick';
import ValuePickerRadioButtons from './valuePickers/RadioButtons';

// eslint-disable-next-line prettier/prettier
const renderFieldDisplayText = ({ displayText, forceSelection }: { displayText: string; forceSelection: boolean; }) => {
    return (
        <div>
            <span className="FieldDisplayText" style={{ fontSize: '14px' }}>
                {displayText}
            </span>
            {forceSelection ? (
                <span className="FieldMandatoryText" style={{ color: 'red' }}>
                    {' *'}
                </span>
            ) : (
                <></>
            )}
        </div>
    );
};

const renderFieldErrors = ({
    field,
    classificationDialogUiSettings,
}: {
    field: Field;
    classificationDialogUiSettings: MetadataSelectionUi | undefined;
}) => {
    if (
        field.selectedValue &&
        field.maxSelected &&
        field.maxSelected != 0 &&
        field.selectedValue.length > field.maxSelected
    ) {
        // Show too many values error
        return (
            <div
                style={{
                    padding: '0 5px 0 0',
                    border: '1px solid black',
                    display: 'inline-block',
                    color: 'grey',
                    marginLeft: '4px',
                    fontSize: 'small',
                }}
            >
                <span
                    style={{
                        borderRadius: '0 2px 2px 0',
                        width: '10px',
                        backgroundColor: 'darkRed',
                        display: 'inline-block',
                        marginRight: '4px',
                        padding: '1px 4px 1px 2px',
                        fontWeight: 'bold',
                        color: 'white',
                        borderRight: '1px solid black',
                        textAlign: 'center',
                    }}
                >
                    !
                </span>
                <span
                    style={{
                        display: 'inline-block',
                    }}
                >
                    {classificationDialogUiSettings?.FieldValuesCountExceedsLimitMessage
                        ? classificationDialogUiSettings.FieldValuesCountExceedsLimitMessage
                        : 'Too many values selected'}
                </span>
            </div>
        );
    }

    return <></>;
};

const renderValuePicker = ({
    field,
    fieldType,
    onChange,
    onlineAddinSettings,
}: {
    field: Field;
    fieldType: string;
    onChange: (fieldName: string, fieldValues: string | string[]) => void;
    classificationDialogUiSettings: MetadataSelectionUi | undefined;
    onlineAddinSettings: OnlineAddinSettings | undefined;
}) => {
    switch (fieldType) {
        case 'Single':
            switch (field.classificationSelectorType) {
                case 'oneClick':
                    return <ValuePickerOneClick field={field} onChange={onChange} />;
                case 'optionButtons':
                    return <ValuePickerRadioButtons field={field} onChange={onChange} />;
                default:
                    return (
                        <ValuePickerDropdown
                            field={field}
                            onChange={onChange}
                            filterPlaceholderText={onlineAddinSettings?.FilterFieldText}
                            customValuePlaceholderText={onlineAddinSettings?.CustomFieldValueText}
                        />
                    );
            }
        case 'MultiSelect':
            return (
                <ValuePickerCheckbox
                    field={field}
                    onChange={onChange}
                    filterPlaceholderText={onlineAddinSettings?.FilterFieldText}
                    customValuePlaceholderText={onlineAddinSettings?.CustomFieldValueText}
                />
            );
        case 'TextEntry':
            return <ValuePickerTextbox field={field} onChange={onChange} />;
        case 'Date':
            return <ValuePickerDate field={field} onChange={onChange} />;
        default:
            // Unsupported field type
            return undefined;
    }
};

type MetadataFieldProps = {
    field: Field;
    onChange: (fieldName: string, fieldValues: string | string[]) => void;
    classificationDialogUiSettings: MetadataSelectionUi | undefined;
    onlineAddinSettings: OnlineAddinSettings | undefined;
};

const MetadataField = (props: MetadataFieldProps) => {
    const { tooltip, name, displayText, forceSelection, fieldType } = props.field;

    const valuePicker = renderValuePicker({ ...props, fieldType });

    if (!valuePicker) return <></>;

    return (
        <div id={name} style={{ paddingBottom: '5px' }}>
            <TooltipHost content={tooltip}>
                {renderFieldDisplayText({ displayText: displayText, forceSelection: forceSelection })}
            </TooltipHost>
            {valuePicker}
            <Obligation metadataObligation={props.field.obligations} />
            {renderFieldErrors({
                field: props.field,
                classificationDialogUiSettings: props.classificationDialogUiSettings,
            })}
        </div>
    );
};

export default MetadataField;
