import { ApplicationSettings } from 'titus-ts/dist/js/Settings/ApplicationSettings';
import { ConfigurationServiceApi } from 'titus-ts/dist/js/Util/Network/CloudServices/ConfigurationServiceApi';

export class AppSettingsService {
    private configurationServiceApi: ConfigurationServiceApi;
    private appSettings!: ApplicationSettings;

    public constructor(configurationServiceApi: ConfigurationServiceApi) {
        this.configurationServiceApi = configurationServiceApi;
    }

    public getApplicationSettings = async (): Promise<ApplicationSettings> => {
        if (!this.appSettings) {
            this.appSettings = await this.configurationServiceApi.getApplicationSettings();
        }

        return this.appSettings;
    };
}
