import { MetadataObject } from 'titus-ts/dist/js/Util/Metadata/MetadataObject';
import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import { OfficeApi } from '../office/OfficeApi';
import MetadataService from './metadataService';
import { SessionStorageService } from './storage/sessionStorageService';
import { GuidService } from './guidService';
import { SerializableOrignalMetadata } from './storage/storageTypes';

export class DocumentMetadataService implements MetadataService {
    private officeApi: OfficeApi;
    private schemaFieldsNames: string[] = [];
    private guidService: GuidService;

    public constructor(officeApi: OfficeApi, schemaFieldsNames: string[], guidService: GuidService) {
        this.officeApi = officeApi;
        this.schemaFieldsNames = schemaFieldsNames;
        this.guidService = guidService;
    }

    public getCurrentMetadata = async (): Promise<MetadataObject | undefined> => {
        const metadata = await this.officeApi.getCustomProperties(this.schemaFieldsNames);
        return metadata;
    };

    public getOriginalMetadata = async (): Promise<MetadataObject | undefined> => {
        let metadata: MetadataObject | undefined;
        let serializedOrignalMetadata: SerializableOrignalMetadata | undefined;
        const currentGuid = await this.guidService.getTitusGuid();

        try {
            serializedOrignalMetadata = SessionStorageService.load<SerializableOrignalMetadata>(
                'POLICY_OBLIGATIONS_ORIGINAL_METADATA',
            );
        } catch {
            console.log('Orignal Metadata does not exist in session storage');
            metadata = await this.saveMetadataToSessionStorage(currentGuid);
        }

        if (serializedOrignalMetadata && currentGuid) {
            metadata = serializedOrignalMetadata[currentGuid];
            if (!metadata) {
                metadata = await this.saveMetadataToSessionStorage(currentGuid);
            }
        }

        return metadata;
    };

    public writeMetadata = async (metadata: MetadataObject): Promise<void> => {
        const data = this.convertMetadataToDictionaryString(metadata);
        await this.officeApi.setCustomProperties(data, this.schemaFieldsNames);
    };

    private convertMetadataToDictionaryString = (metadata: MetadataObject): DictionaryInterface<string | string[]> => {
        const customProperties: DictionaryInterface<string | string[]> = {};
        for (const [key, value] of Object.entries(metadata)) {
            Object.defineProperty(customProperties, key, {
                value: value,
                writable: true,
            });
        }
        return customProperties;
    };

    private saveMetadataToSessionStorage = async (
        currentGuid: string | undefined,
    ): Promise<MetadataObject | undefined> => {
        const metadata = await this.getCurrentMetadata();
        if (metadata && currentGuid) {
            const orignalMetadata: SerializableOrignalMetadata = {};
            orignalMetadata[currentGuid] = metadata;
            SessionStorageService.save<SerializableOrignalMetadata>(
                orignalMetadata,
                'POLICY_OBLIGATIONS_ORIGINAL_METADATA',
            );
        }
        return metadata;
    };
}
