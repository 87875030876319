import React, { useState } from 'react';
import Field from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/Field';
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { ResponsiveMode } from '@fluentui/react/lib/ResponsiveMode';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { TextField } from '@fluentui/react/lib/TextField';

// Custom renderer for field values (options) within fluent UI dropdown
export const _onRenderOption = (option: IDropdownOption) => {
    const hasBackgroundColour = option.data && option.data.color;
    const hasToolTip = option.data && option.data.tooltip;
    const noTooltip = !option.data || !option.data.tooltip;
    return (
        <div
            style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {hasBackgroundColour && (
                <span
                    style={{
                        height: 10,
                        width: 10,
                        borderRadius: '50%',
                        backgroundColor: option.data.color,
                        display: 'inline-block',
                        marginRight: '4px',
                    }}
                />
            )}
            {hasToolTip && (
                <TooltipHost id={option.key + '_' + option.text} content={option.data.tooltip}>
                    <span>{option.text}</span>
                </TooltipHost>
            )}
            {noTooltip && <span>{option.text}</span>}
        </div>
    );
};

// Custom renderer for selected value (option) within fluent UI dropdown
export const _onRenderTitle = (options: IDropdownOption[]) => {
    const selectedOptions = options.filter((elem) => elem.data && elem.data.selected) as IDropdownOption[];
    const displayText = selectedOptions ? selectedOptions.map((elem) => elem.text).join('; ') : '';
    let option;
    if (selectedOptions && selectedOptions.length == 1) {
        option = options[0];
    }
    const hasBackgroundColour = option && option.data && option.data.color;
    const hasToolTip = option && option.data && option.data.tooltip;
    const noTooltip = !option || !option.data || !option.data.tooltip;

    return (
        <div
            style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {hasBackgroundColour && (
                <span
                    style={{
                        height: 10,
                        width: 10,
                        borderRadius: '50%',
                        backgroundColor: hasBackgroundColour ? hasBackgroundColour : undefined,
                        display: 'inline-block',
                        marginRight: '4px',
                    }}
                />
            )}
            {hasToolTip && (
                <TooltipHost content={hasToolTip}>
                    <span>{displayText}</span>
                </TooltipHost>
            )}
            {noTooltip && <span>{displayText}</span>}
        </div>
    );
};

// Custom styles for fluent UI dropdown
const dropdownStyles: Partial<IDropdownStyles> = {
    label: { fontWeight: 'normal' },
    dropdownItems: { whiteSpace: 'nowrap' },
};

type DropdownValuePickerProps = {
    field: Field;
    onChange: (fieldName: string, fieldValues: string | string[]) => void;
    customValuePlaceholderText?: string | undefined;
    filterPlaceholderText?: string | undefined;
};

const isUserDefinedValueValid = (
    newValue: string,
    maxValueLength = 255,
    invalidCharacters: string[] = [';', ',', '='],
): boolean => {
    if (newValue.length > maxValueLength) {
        return false;
    } else if (stringContainsCharacterFromList(newValue, invalidCharacters)) {
        return false;
    }
    return true;
};

const stringContainsCharacterFromList = (searchString: string, characters: string[]): boolean => {
    for (const character of characters) {
        if (searchString.includes(character)) {
            return true;
        }
    }

    return false;
};

const ValuePickerDropdown = (props: DropdownValuePickerProps) => {
    const [userDefinedValue, setUserDefinedValue] = useState('');
    const [filterText, setFilterText] = useState('');
    const { field, onChange, customValuePlaceholderText, filterPlaceholderText } = props;

    const options: IDropdownOption[] = field.values
        .filter(
            (value) =>
                value.name.toLowerCase().includes(filterText.toLowerCase()) ||
                value.displayText.toLowerCase().includes(filterText.toLowerCase()),
        )
        .map((val) => {
            return {
                key: val.name,
                text: val.displayText,
                data: {
                    color: val.colorCode,
                    tooltip: val.tooltip,
                    selected: field.selectedValue?.includes(val.name) as boolean,
                },
                //Title is used by MSFT as Tooltip, if not assigned any value item.text is used by default.
                title: '',
            };
        });

    const _onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (multiSelect) {
            if (item) {
                selectedKeys = item.selected
                    ? [...selectedKeys, item.key as string]
                    : [...selectedKeys.filter((key) => key !== item.key)];
            }
            onChange(field.name, selectedKeys ? selectedKeys : []);
        } else {
            onChange(field.name, item ? (item.key as string) : '');
        }
    };
    const _onUserDefinedValueKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key == 'Enter' || event.key == ';') {
            const newValue = event.currentTarget.value;

            if (isUserDefinedValueValid(newValue)) {
                onChange(field.name, newValue);
                setUserDefinedValue('');
            }
        }
    };
    const _onUserDefinedValueChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void => {
        if (!newValue || isUserDefinedValueValid(newValue)) {
            setUserDefinedValue(newValue ? newValue : '');
        }
    };

    const _onFilterTextChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void => {
        newValue = newValue == undefined ? '' : newValue;
        setFilterText(newValue);
    };

    let selectedKeys = options
        .filter((option) => option.data && option.data.selected && !option.itemType)
        .map(({ key }) => key as string);

    const multiSelect = field.maxSelected == undefined ? false : true;

    return (
        <div data-testid="dropdown_valuepicker">
            {!field?.restrictToValueList ? (
                <TextField
                    name={field.name + '_additionalEntry'}
                    placeholder={customValuePlaceholderText || 'Enter field value'}
                    value={userDefinedValue}
                    onKeyUp={_onUserDefinedValueKeyUp}
                    onChange={_onUserDefinedValueChange}
                />
            ) : null}

            {field?.restrictToValueList && field?.isFilterEnabled ? (
                <TextField
                    name={field.name + '_filter'}
                    placeholder={filterPlaceholderText || 'Filter...'}
                    value={filterText}
                    onChange={_onFilterTextChange}
                />
            ) : null}

            <Dropdown
                options={options}
                responsiveMode={ResponsiveMode.large}
                styles={dropdownStyles}
                onChange={_onChange}
                // Due to error in FabricUI Typescript definition file, we have to cast function as any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onRenderOption={_onRenderOption as any}
                // Due to error in FabricUI Typescript definition file, we have to cast function as any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onRenderTitle={_onRenderTitle as any}
                multiSelect={multiSelect}
                selectedKeys={multiSelect ? selectedKeys : undefined}
                selectedKey={!multiSelect ? selectedKeys[0] : undefined}
            />
        </div>
    );
};

export default ValuePickerDropdown;
