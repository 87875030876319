import React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

const stackTokens: IStackTokens = { childrenGap: 16 };

type PaneCommandBarProps = {
    onOk: () => void;
    okEnabled: boolean;
    okText?: string | undefined;
    okTooltip?: string | undefined;
};

const PaneCommandBar = (props: PaneCommandBarProps) => {
    const { onOk, okEnabled, okText, okTooltip } = props;
    return (
        <div data-testid="panecommandbar">
            <Stack horizontal tokens={stackTokens}>
                <TooltipHost content={okTooltip}>
                    <PrimaryButton text={okText || 'OK'} onClick={onOk} disabled={!okEnabled} />
                </TooltipHost>
            </Stack>
        </div>
    );
};

export default PaneCommandBar;
