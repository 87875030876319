import Action from 'titus-ts/dist/js/Util/Actions/Action';
import ActionHandlerFactory from 'titus-ts/dist/js/Util/Actions/ActionHandlerFactory';
import { groupBy } from './alertFactory';
import { ActionTypes } from 'titus-ts/dist/js/Util/Actions/ActionTypes';

export default class RemediationService {
    private actionHandlerFactory: ActionHandlerFactory;

    public constructor(actionHandlerFactory: ActionHandlerFactory) {
        this.actionHandlerFactory = actionHandlerFactory;
    }

    public remediate = async (actions: Action[]) => {
        const handlers: Promise<void>[] = [];
        const groupedActions = groupBy<Action>(actions, (action) => action.type);
        groupedActions.forEach(async (value, key) => {
            const actionType = key as ActionTypes;
            if (actionType === 'RecipientAlert') {
                const handler = this.actionHandlerFactory.crateActionHandler(key as ActionTypes);

                handlers.push(handler.handle(value));
            }
        });
        await Promise.all(handlers);
    };
}
