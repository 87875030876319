import { AttachmentOffice } from './AttachmentOffice';
import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import { Recipient } from 'titus-ts/dist/js/Mail/Recipient';
import { AddinApi } from './AddinApi';

export class OutlookApi extends AddinApi {
    constructor() {
        super();
        this.getRecipientsPromise.bind(this);
        this.getToAsync = this.getToAsync.bind(this);
        this.setToAsync = this.setToAsync.bind(this);
        this.addToAsync = this.addToAsync.bind(this);
        this.addCcAsync = this.addCcAsync.bind(this);
        this.getCcAsync = this.getCcAsync.bind(this);
        this.setCcAsync = this.setCcAsync.bind(this);
        this.getBccAsync = this.getBccAsync.bind(this);
        this.setBccAsync = this.setBccAsync.bind(this);
        this.addBccAsync = this.addBccAsync.bind(this);
    }

    public sendEwsRequestAsync = (envelope: string): Promise<Office.AsyncResult<string>> => {
        return new Promise<Office.AsyncResult<string>>((resolve, reject) => {
            Office.context.mailbox.makeEwsRequestAsync(envelope, (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve(result);
                } else {
                    reject(result.error.message);
                }
            });
        });
    };

    public saveAsync = (): Promise<string> => {
        return new Promise((resolve, reject) => {
            if (Office.context.mailbox.item) {
                Office.context.mailbox.item.saveAsync((result) => {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        resolve(result.value);
                    } else {
                        reject(result.error.message);
                    }
                });
            } else {
                reject('Item is undefined');
            }
        });
    };

    public setInternetHeaders = (headers: DictionaryInterface<string>): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (Office.context.mailbox.item) {
                Office.context.mailbox.item.internetHeaders.setAsync(headers, { asyncContext: null }, (result) => {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        resolve();
                    } else {
                        reject(result.error.message);
                    }
                });
            } else {
                reject('Item is undefined');
            }
        });
    };

    public getToAsync = (): Promise<Recipient[]> => {
        if (Office.context.mailbox.item) {
            return this.getRecipientsPromise(Office.context.mailbox.item.to, 'TO');
        } else {
            return Promise.reject('Items is undefined.');
        }
    };

    public getCcAsync = (): Promise<Recipient[]> => {
        if (Office.context.mailbox.item) {
            return this.getRecipientsPromise(Office.context.mailbox.item.cc, 'CC');
        } else {
            return Promise.reject('Items is undefined.');
        }
    };

    public getBccAsync = (): Promise<Recipient[]> => {
        if (Office.context.mailbox.item) {
            return this.getRecipientsPromise(Office.context.mailbox.item.bcc, 'BCC');
        } else {
            return Promise.reject('Items is undefined.');
        }
    };

    public setToAsync = (emails: string[]): Promise<void> => {
        if (Office.context.mailbox.item) {
            return this.setRecipientsPromise(Office.context.mailbox.item.to, emails);
        } else {
            return Promise.reject('Items is undefined.');
        }
    };

    public setCcAsync = (emails: string[]): Promise<void> => {
        if (Office.context.mailbox.item) {
            return this.setRecipientsPromise(Office.context.mailbox.item.cc, emails);
        } else {
            return Promise.reject('Items is undefined.');
        }
    };

    public setBccAsync = (emails: string[]): Promise<void> => {
        if (Office.context.mailbox.item) {
            return this.setRecipientsPromise(Office.context.mailbox.item.bcc, emails);
        } else {
            return Promise.reject('Items is undefined.');
        }
    };

    public addToAsync = (emails: string[]): Promise<void> => {
        if (Office.context.mailbox.item) {
            return this.addRecipientsPromise(Office.context.mailbox.item.to, emails);
        } else {
            return Promise.reject('Items is undefined.');
        }
    };

    public addCcAsync = (emails: string[]): Promise<void> => {
        if (Office.context.mailbox.item) {
            return this.addRecipientsPromise(Office.context.mailbox.item.cc, emails);
        } else {
            return Promise.reject('Items is undefined.');
        }
    };

    public addBccAsync = (emails: string[]): Promise<void> => {
        if (Office.context.mailbox.item) {
            return this.addRecipientsPromise(Office.context.mailbox.item.bcc, emails);
        } else {
            return Promise.reject('Items is undefined.');
        }
    };

    public isMailboxVersionSupported = (version: number): boolean => {
        return Office.context.requirements.isSetSupported('MailBox', version);
    };

    public getItemId = (): string => {
        if (Office.context.mailbox.item) {
            return Office.context.mailbox.item.itemId;
        } else {
            return '';
        }
    };

    public getConversationId = (): string => {
        if (Office.context.mailbox.item) {
            return Office.context.mailbox.item.conversationId;
        } else {
            return '';
        }
    };

    public getBodyAsTextAsync = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const onItemBodyRetrieved = (result: Office.AsyncResult<string>) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve(result.value);
                } else {
                    reject(`Failed getting message body as text... Error: ${result.error}`);
                }
            };

            Office.context.mailbox.item?.body.getAsync(Office.CoercionType.Text, onItemBodyRetrieved);
        });
    };

    public getBodyAsHtmlAsync = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const onItemBodyRetrieved = (result: Office.AsyncResult<string>) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve(result.value);
                } else {
                    reject(`Failed getting message body as html... Error: ${result.error}`);
                }
            };

            Office.context.mailbox.item?.body.getAsync(Office.CoercionType.Html, onItemBodyRetrieved);
        });
    };

    public getBodyTypeAsync = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const onBodyTypeRetrieved = (result: Office.AsyncResult<Office.CoercionType>) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve(result.value.toString());
                } else {
                    reject(`Failed getting message body as html... Error: ${result.error}`);
                }
            };

            Office.context.mailbox.item?.body.getTypeAsync({}, onBodyTypeRetrieved);
        });
    };

    public setBodyAsync = (newBody: string, bodyType: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const onSetBodyCallback = (result: Office.AsyncResult<void>) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve();
                } else {
                    reject(`Failed setting message body... Error: ${result.error}`);
                }
            };

            const type = bodyType === 'text' ? Office.CoercionType.Text : Office.CoercionType.Html;

            Office.context.mailbox.item?.body.setAsync(newBody, { coercionType: type }, onSetBodyCallback);
        });
    };

    public getSubjectAsync = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const onItemSubjectRetrieved = (result: Office.AsyncResult<string>) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve(result.value);
                } else {
                    reject(`Failed getting message subject... Error: ${result.error}`);
                }
            };

            Office.context.mailbox.item?.subject.getAsync(onItemSubjectRetrieved);
        });
    };

    public setSubjectAsync = (newSubject: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const onSetSubjectCallback = (result: Office.AsyncResult<void>) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve();
                } else {
                    reject(`Failed setting message subject... Error: ${result.error}`);
                }
            };

            Office.context.mailbox.item?.subject.setAsync(newSubject, onSetSubjectCallback);
        });
    };

    public getAttachments = (): AttachmentOffice[] => {
        const attachments = [];
        const item = Office.context.mailbox.item;
        if (item && item.attachments && item.attachments.length > 0) {
            for (let index = 0; index < item.attachments.length; index++) {
                const attachment = item.attachments[index];
                attachments.push(new AttachmentOffice(attachment.name, attachment.size));
            }
        }

        return attachments;
    };

    private getRecipientsPromise = (recipients: Office.Recipients, recipientType: string): Promise<Recipient[]> => {
        return new Promise<Recipient[]>((resolve, reject) => {
            recipients.getAsync({}, (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    const emails = this.mapEmailAddresses(result.value, recipientType);

                    resolve(emails);
                } else {
                    reject('Error getting recipients. Error: ' + result.error.message);
                }
            });
        });
    };

    private setRecipientsPromise = (recipients: Office.Recipients, emails: string[]): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            recipients.setAsync(emails, {}, (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve();
                } else {
                    reject('Error setting recipients. Error: ' + result.error.message);
                }
            });
        });
    };

    private addRecipientsPromise = (recipients: Office.Recipients, emails: string[]): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            recipients.addAsync(emails, {}, (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    resolve();
                } else {
                    reject('Error adding recipients. Error: ' + result.error.message);
                }
            });
        });
    };

    private mapEmailAddresses = (emails: Office.EmailAddressDetails[], recipientType: string): Recipient[] => {
        const result: Recipient[] = [];

        if (emails) {
            for (let index = 0; index < emails.length; index++) {
                const elem = emails[index];
                const recipient = new Recipient(elem.emailAddress, elem.displayName, recipientType);
                recipient.IsDistributionList =
                    elem.recipientType === Office.MailboxEnums.RecipientType.DistributionList;
                result.push(recipient);
            }
        }

        return result;
    };

    public getPlatform = (): string => {
        return Office.context.mailbox.diagnostics.hostName;
    };

    public getSenderAddress = (): string => {
        return Office.context.mailbox.userProfile.emailAddress;
    };

    public getSenderDisplayName = (): string => {
        return Office.context.mailbox.userProfile.displayName;
    };

    public messageParent = (message: string): void => {
        Office.context.ui.messageParent(message);
    };

    public onItemChanged = (callback: () => void): void => {
        Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged);
        Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, callback);
    };

    public isMailBoxItemTypeSupported = (): boolean => {
        return Office.context.mailbox.item?.itemType === Office.MailboxEnums.ItemType.Message;
    };
}
