import { OfficeApi } from '../office/OfficeApi';
import Action from 'titus-ts/dist/js/Util/Actions/Action';
import ServiceConstantsProvider from './serviceConstantsProvider';
import { LocalStorageService } from './storage/localStorageService';
import { SerializableAction } from './storage/storageTypes';
import { AlertFactory } from './alertFactory';
import {
    DialogMessages,
    DIALOG_OK,
    DIALOG_CANCEL,
    DIALOG_OK_ITEM_CHANGED,
    DIALOG_OK_ID_CACHED,
} from 'titus-ts/dist/js/Util/Policy/PolicyEvaluationBaseState';
import RemediationService from './remediationService';
import { MetadataObject } from 'titus-ts/dist/js/Util/Metadata/MetadataObject';
import { sleep } from '../office/Sleep';
import { EventType } from 'titus-ts/dist/js/Util/Policy/EventType';
import SchemaProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaProvider';
import { ALERT_MODE } from '../store/alerts/types';

export const DIALOG_REMEDIATE = 'DialogRemediate';
export const DIALOG_REMEDIATE_ALL = 'DialogRemediateAll';
export type DIALOG_REMEDIATE_TYPE = typeof DIALOG_REMEDIATE | typeof DIALOG_REMEDIATE_ALL;
export type OfficeDialogMessages = {
    type: DialogMessages | DIALOG_REMEDIATE_TYPE;
    actionIds?: string[];
    metadata?: MetadataObject;
};

export default class DialogService {
    private officeApi: OfficeApi;
    private remediationService: RemediationService | undefined;
    private schemaProvider: SchemaProvider | undefined;
    private dialog: Office.Dialog | undefined;
    private alerts: Action[] = [];
    private resolveCallback!: (
        value:
            | typeof DIALOG_OK
            | typeof DIALOG_CANCEL
            | typeof DIALOG_OK_ITEM_CHANGED
            | typeof DIALOG_OK_ID_CACHED
            | PromiseLike<DialogMessages>,
    ) => void;
    public constructor(officeApi: OfficeApi, remediationService?: RemediationService, schemaProvider?: SchemaProvider) {
        this.officeApi = officeApi;
        this.remediationService = remediationService;
        this.schemaProvider = schemaProvider;
    }

    public showForceClassificationDialog = async (): Promise<DialogMessages> => {
        return new Promise<DialogMessages>(async (resolve, reject) => {
            try {
                this.resolveCallback = resolve;
                this.persistMetadata();
                this.dialog = await this.getPreparedDialog(ServiceConstantsProvider.getForceClassificationUrl());
            } catch (err) {
                reject(err);
            }
        });
    };

    public showSuggestClassificationDialog = async (actions: Action[], suggestId: string): Promise<DialogMessages> => {
        return new Promise<DialogMessages>(async (resolve, reject) => {
            try {
                this.resolveCallback = resolve;
                this.persistMetadata();
                const suggestAction = actions.find((action) => action.id === suggestId);
                if (suggestAction) {
                    const reason = suggestAction.params.get('Reason') || 'Please review suggested classification';
                    LocalStorageService.save<string>(reason, 'POLICY_SUGGEST_PREAMBLE');
                    this.dialog = await this.getPreparedDialog(ServiceConstantsProvider.getSuggestClassificationUrl());
                } else {
                    reject('No suggest classification action.');
                }
            } catch (err) {
                reject(err);
            }
        });
    };

    public showAlertsDialog = async (actions: Action[], event: EventType): Promise<DialogMessages> => {
        return new Promise<DialogMessages>(async (resolve, reject) => {
            const alerts = actions.filter((action) => this.isUserVisibleAlert(action) || action.type === 'Deny');
            if (alerts.length === 0) {
                reject(`Unable to display alert dialog. No alerts given ${actions}`);
            }

            try {
                this.resolveCallback = resolve;
                this.persistMetadata();
                this.alerts = alerts;
                const serializedAlerts: SerializableAction[] = AlertFactory.serializeActions(alerts);
                LocalStorageService.save<SerializableAction[]>(serializedAlerts, 'POLICY_ALERTS');
                const alertsUrl =
                    event === 'OnSend'
                        ? ServiceConstantsProvider.getAlertsWithEnabledOkUrl()
                        : ServiceConstantsProvider.getAlertsWithDisabledOkUrl();
                this.dialog = await this.getPreparedDialog(alertsUrl);
            } catch (err) {
                reject(err);
            }
        });
    };

    public showFailOpenCloseAlertsDialog = async (mode: ALERT_MODE): Promise<DialogMessages> => {
        return new Promise<DialogMessages>(async (resolve) => {
            this.resolveCallback = resolve;
            this.dialog = await this.getPreparedDialog(
                ServiceConstantsProvider.getFailOpenCloseAlertWithEnabledOkUrl() + mode,
            );
        });
    };

    public closeDialog = (): void => {
        if (this.dialog) {
            this.dialog.close();
        }
    };

    private handleMessageReceived = async (
        arg: { message: string; origin: string | undefined } | { error: number },
    ): Promise<void> => {
        if ('message' in arg) {
            const dialogMessage = JSON.parse(arg.message) as OfficeDialogMessages;
            switch (dialogMessage.type) {
                case 'DialogOkIdCached':
                case 'DialogOk':
                    if (dialogMessage.metadata) {
                        this.schemaProvider?.select(dialogMessage.metadata);
                    }
                    this.handleCompleteDialog(dialogMessage.type);
                    break;
                case 'DialogCancel':
                case 'DialogOkItemChanged':
                    this.handleCompleteDialog(dialogMessage.type);
                    break;
                case 'DialogRemediate':
                    await this.handleRemediate(dialogMessage.actionIds);
                    break;
                case 'DialogRemediateAll':
                    await this.handleRemediateAll(dialogMessage.actionIds);
                    break;
            }
        } else {
            throw new Error('Unexpected object.');
        }
    };

    // To add switch statement on arg: { error: number } -> 12006 is dialog close event
    private handleEventReceived = (): void => {
        this.handleMessageReceived({ message: JSON.stringify({ type: DIALOG_CANCEL }), origin: undefined });
    };

    private handleCompleteDialog = (type: DialogMessages): void => {
        this.closeDialog();
        // Office JS throws error 'unable to show dialog'
        // if display dialog is called too soon after close.
        sleep(100).then(() => {
            if (this.resolveCallback) {
                this.resolveCallback(type);
            }
        });
    };

    private handleRemediate = async (actionIds?: string[]): Promise<void> => {
        await this.remediationService?.remediate(this.alerts.filter((alert) => actionIds?.includes(alert.id)));
    };

    private handleRemediateAll = async (actionIds?: string[]): Promise<void> => {
        this.closeDialog();
        await this.remediationService?.remediate(this.alerts.filter((alert) => actionIds?.includes(alert.id)));
        if (this.resolveCallback) {
            this.resolveCallback('DialogOkItemChanged');
        }
    };

    private persistMetadata = (): void => {
        const currentMetadata = this.schemaProvider?.getMetadata() || {};
        const originalMetadata = this.schemaProvider?.getOriginalMetadata() || {};
        LocalStorageService.save<MetadataObject>(currentMetadata, 'POLICY_DIALOG_CURRENT_METADATA');
        LocalStorageService.save<MetadataObject>(originalMetadata, 'POLICY_DIALOG_ORIGINAL_METADATA');
    };

    private getPreparedDialog = async (dialogUrl: string): Promise<Office.Dialog> => {
        const dialog = await this.officeApi.showDialogAsync(dialogUrl);
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, this.handleMessageReceived);
        dialog.addEventHandler(Office.EventType.DialogEventReceived, this.handleEventReceived);
        return dialog;
    };

    private isUserVisibleAlert = (action: Action): boolean => {
        const result =
            (action.type === 'Alert' ||
                action.type === 'ClassificationAlert' ||
                action.type === 'ContentAlert' ||
                action.type === 'RecipientAlert') &&
            action.params.get('Warn')?.toLowerCase() === 'true';

        return result;
    };
}
