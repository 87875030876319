import React, { useEffect } from 'react';
import { ALERT_MODE, AlertViewModel, ALERTS_OK_PARAM } from '../../store/alerts/types';
import PaneTitle from '../shared/PaneTitle';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import Alert from './Alert';
import ApplicationMessage from '../shared/ApplicationMessage';
import { AppState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
    loadAlerts as loadAlertsAction,
    completeAlerts as completeAlertsAction,
    remediateAlerts as remediateAlertsAction,
} from '../../store/alerts/actions';
import { connect } from 'react-redux';
import AlertsDialogDescription from './AlertsDialogDescription';
import AlertsCommandBarOutlook from './AlertsCommandBarOutlook';
import { DialogMessages } from 'titus-ts/dist/js/Util/Policy/PolicyEvaluationBaseState';
import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { DIALOG_REMEDIATE_TYPE } from '../../services/dialogService';
import { AnimationStyles, mergeStyles } from '@fluentui/react/lib/Styling';
import { useQuery } from '../shared/UrlUtilities';
import AlertsCommandBarDocument from './AlertsCommandBarDocument';
import { ApplicationSettings } from 'titus-ts/dist/js/Settings/ApplicationSettings';

type AlertsProps = {
    processing: boolean;
    processingMessage: string | null;
    error: string | null;
    mode: ALERT_MODE | undefined;
    alerts: AlertViewModel[];
    canRemediateAll: boolean;
    remediated: boolean;
    isHostOutlook: boolean;
    themeColor: string;
    appSettings: ApplicationSettings | null;
};

type AlertsDispatchProps = {
    loadAlerts: () => Promise<void>;
    completeAlerts: (message: DialogMessages) => Promise<void>;
    remediateAlerts: (actions: Action[], remediatetype: DIALOG_REMEDIATE_TYPE) => Promise<void>;
};

export const Alerts = (props: AlertsProps & AlertsDispatchProps) => {
    const {
        error,
        mode,
        alerts,
        canRemediateAll,
        loadAlerts,
        completeAlerts,
        remediateAlerts,
        remediated,
        isHostOutlook,
        themeColor,
        appSettings,
    } = props;
    const query = useQuery();
    const enableOk = query.get(ALERTS_OK_PARAM) === 'true';
    const validationUI = appSettings?.PoliciesValidationUi;

    useEffect(() => {
        loadAlerts();
    }, [loadAlerts]);

    const remediateAll = (remediatetype: DIALOG_REMEDIATE_TYPE): void => {
        const actions: Action[] = [];
        alerts.forEach((alert) => {
            if (alert.isRemediable) {
                actions.push(...alert.alerts);
            }
        });
        remediateAlerts(actions, remediatetype);
    };
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    height: 'calc(100% - 60px)',
                    overflowX: 'hidden',
                    paddingLeft: isHostOutlook ? '0px' : '5%',
                    paddingRight: isHostOutlook ? '0px' : '5%',
                    paddingTop: isHostOutlook ? '0px' : '2%',
                }}
            >
                <PaneTitle title={validationUI?.DialogTitle ?? 'Policy Alerts'} themeColor={themeColor} />
                {error && <ApplicationMessage message={error || ''} type="Error" />}
                <AlertsDialogDescription mode={mode} dialogSummaryText={validationUI?.DialogSummaryText} />
                <div
                    className={mergeStyles(AnimationStyles.fadeIn100, {
                        borderTop: '1px solid',
                        borderTopColor: NeutralColors.gray80,
                        borderBottom: '1px solid',
                        borderBottomColor: NeutralColors.gray80,
                    })}
                >
                    <div style={{ marginLeft: '8px' }}>
                        {alerts &&
                            alerts.map((alert, index) => (
                                <Alert alertViewModel={alert} remediateAlerts={remediateAlerts} key={'al' + index} />
                            ))}
                    </div>
                </div>
            </div>
            <div
                className={mergeStyles(AnimationStyles.fadeIn100, {
                    position: 'absolute',
                    bottom: 0,
                    width: isHostOutlook ? '100%' : '90%',
                    height: '50px',
                    marginLeft: isHostOutlook ? '0px' : '5%',
                    marginRight: isHostOutlook ? '0px' : '5%',
                    display: 'inherit',
                    justifyContent: 'flex-end',
                })}
            >
                {isHostOutlook && (
                    <AlertsCommandBarOutlook
                        mode={mode}
                        canRemediateAll={canRemediateAll}
                        completeAlerts={completeAlerts}
                        remediateAll={remediateAll}
                        remediated={remediated}
                        enableOk={enableOk}
                        appSettings={appSettings}
                    />
                )}
                {!isHostOutlook && <AlertsCommandBarDocument completeAlerts={completeAlerts} />}
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState): AlertsProps => ({
    processing: state.alertsReducer.processing,
    processingMessage: state.alertsReducer.processingMessage,
    alerts: state.alertsReducer.alerts,
    error: state.alertsReducer.error,
    mode: state.alertsReducer.mode,
    canRemediateAll: state.alertsReducer.canRemediateAll,
    remediated: state.alertsReducer.remediated,
    isHostOutlook: state.alertsReducer.isHostOutlook,
    themeColor: state.alertsReducer.themeColor,
    appSettings: state.alertsReducer.applicationSettings,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, undefined, AnyAction>) => ({
    loadAlerts: () => dispatch(loadAlertsAction()),
    completeAlerts: (message: DialogMessages) => dispatch(completeAlertsAction(message)),
    remediateAlerts: (actions: Action[], remediatetype: DIALOG_REMEDIATE_TYPE) =>
        dispatch(remediateAlertsAction(actions, remediatetype)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
