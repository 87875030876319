import Action from 'titus-ts/dist/js/Util/Actions/Action';
import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import { OfficeApi } from '../OfficeApi';
import ActionHandler from 'titus-ts/dist/js/Util/Actions/ActionHandler';
import FieldCodeService from 'titus-ts/dist/js/Util/Actions/FieldCodeService';

export default class OfficeCustomXHeaderHandler implements ActionHandler {
    private officeApi: OfficeApi;
    private fieldCodeService: FieldCodeService;

    constructor(officeApi: OfficeApi, fieldCodeService: FieldCodeService) {
        this.officeApi = officeApi;
        this.fieldCodeService = fieldCodeService;
    }

    public handle = async (actions: Action[]): Promise<void> => {
        const handles: Promise<void>[] = [];
        const updatedActions = this.fieldCodeService.process(actions, 'CustomXHeader');
        updatedActions.forEach((action): void => {
            const headerName = this.getHeaderName(action);
            const customHeader = this.getHeaderContent(action, headerName);
            handles.push(this.officeApi.setInternetHeadersAsync(customHeader));
        });

        await Promise.all(handles);
    };

    private getHeaderName = (action: Action): string => {
        let headerName: string = action.params.get('XHeaderName') as string;
        // Does the header contains 'x' in the front
        if (headerName && headerName.substring(0, 2) !== 'X-' && headerName.substring(0, 2) !== 'x-') {
            const headerX = 'X-';
            headerName = headerX.concat(headerName);
        }

        return headerName;
    };

    private getHeaderContent = (action: Action, headerName: string): DictionaryInterface<string> => {
        let headerContent: string = action.params.get('XHeaderContent') as string;
        const customHeader: DictionaryInterface<string> = {};
        if (headerContent.length > 900) {
            headerContent = headerContent.substring(0, 899);
        }
        customHeader[headerName] = headerContent;

        return customHeader;
    };
}
