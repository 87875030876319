import React, { useEffect, useState } from 'react';
import ProcessingIndicator from './ProcessingIndicator';
import ClassificationPaneViewModel from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/ClassificationPaneViewModel';
import { AppState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
    selectClassificationLoad,
    clearError,
    selectClassificationUpdateViewmodel,
    applyMetadata as applyMetadataAction,
    completeDialog as completeDialogAction,
} from '../../store/select-classification/actions';
import { connect } from 'react-redux';
import MetadataField from './MetadataField';
import CommandBar from './commands/CommandBar';
import ApplicationMessage, { ERROR } from '../shared/ApplicationMessage';
import { ApplicationSettings } from '../../../node_modules/titus-ts/dist/js/Settings/ApplicationSettings';
import Preamble from './Preamble';
import RequiredSelectionWarning from './RequiredSelectionWarning';
import { MetadataObject } from 'titus-ts/dist/js/Util/Metadata/MetadataObject';
import { extractMetadata } from '../../services/renderingService';
import PaneTitle from '../shared/PaneTitle';
import { SELECT_CLASSIFICATION_MODE_PARAM, SELECT_CLASSIFICATION_MODE } from '../../store/select-classification/types';
import { DialogMessages } from 'titus-ts/dist/js/Util/Policy/PolicyEvaluationBaseState';
import { AnimationStyles, mergeStyles } from '@fluentui/react/lib/Styling';
import { useQuery } from '../shared/UrlUtilities';
import { Pivot, PivotItem, IPivotItemProps, IButtonProps, IComponentAs, IconButton } from '@fluentui/react';
import TabConfiguration from 'titus-ts/dist/js/MetadataRenderer/Schema/TabConfiguration';

type SelectClassificationProps = {
    processing: boolean;
    processingMessage: string | null;
    classificationPaneViewModel: ClassificationPaneViewModel | null;
    error: string | null;
    appSettings: ApplicationSettings | null;
    suggestPreamble: string | null;
    themeColor: string;
};

type SelectClassificationDispatchProps = {
    selectClassificationLoad: (mode: SELECT_CLASSIFICATION_MODE) => Promise<void>;
    clearError: () => void;
    updateViewModel: (changedFieldName: string, changedFieldValue: string | string[]) => Promise<void>;
    applyMetadata: (metadata: MetadataObject) => Promise<void>;
    completeDialog: (message: DialogMessages, metadata: MetadataObject) => void;
};

export const SelectClassification = (props: SelectClassificationProps & SelectClassificationDispatchProps) => {
    const {
        processing,
        processingMessage,
        classificationPaneViewModel,
        error,
        appSettings,
        selectClassificationLoad,
        clearError,
        updateViewModel,
        applyMetadata,
        completeDialog,
        suggestPreamble,
        themeColor,
    } = props;

    const query = useQuery();
    const mode = query.get(SELECT_CLASSIFICATION_MODE_PARAM) as SELECT_CLASSIFICATION_MODE;

    useEffect(() => {
        selectClassificationLoad(mode);
    }, [selectClassificationLoad, mode]);

    const metadata = extractMetadata(classificationPaneViewModel);

    const hasForcedSelection =
        classificationPaneViewModel &&
        classificationPaneViewModel.fields.find((field) => field.forceSelection === true);

    const updateMetadata = (fieldName: string, fieldValue: string | string[]) => {
        updateViewModel(fieldName, fieldValue);
    };

    //collect tab info
    const viewModelFields = classificationPaneViewModel ? classificationPaneViewModel.fields : [];
    const viewModelTabConfig: TabConfiguration = classificationPaneViewModel
        ? classificationPaneViewModel.tabConfiguration
        : {
              enabled: false,
              tabPositions: {},
          };
    const emptyMandatoryFields = viewModelFields.filter(
        (field) => field.forceSelection && !field.selectedValue?.length,
    );
    const uniqueTabs = new Set(
        Object.entries(viewModelTabConfig.tabPositions)
            .filter(([, value]) => {
                return viewModelFields.some((obj) => obj.tabPageName === value.tabName);
            })
            .map(([, value]) => ({
                tabName: value.tabName,
                displayText: truncatePivotText(value.displayText),
                requiresValue: emptyMandatoryFields.some((obj) => obj.tabPageName === value.tabName),
            })),
    );

    //tab persistance
    const [currentTab, setSelectedTab] = useState<string>('');
    const handleTabPress = (item?: PivotItem) => {
        if (item) setSelectedTab(item.props.itemKey ? item.props.itemKey : '');
    };
    const [overflowRequiresAttention, setOverflowRequiresAttention] = useState<boolean>(false);

    type SelectClassificationTabsComponentProps = {
        viewModel: ClassificationPaneViewModel;
    };
    class SelectClassificationTabsComponent extends React.Component<SelectClassificationTabsComponentProps> {
        viewModel: ClassificationPaneViewModel;
        private overflowObserver: MutationObserver | null;

        constructor(props: SelectClassificationTabsComponentProps) {
            super(props);
            this.viewModel = props.viewModel;
            this.overflowObserver = null;
        }

        render() {
            return (
                <Pivot
                    id="schemaPivot"
                    overflowBehavior="menu"
                    selectedKey={currentTab}
                    onLinkClick={handleTabPress}
                    overflowButtonAs={this.customOverflowButton}
                >
                    {Array.from(uniqueTabs).map((tab) => (
                        <PivotItem
                            key={tab.tabName}
                            headerText={tab.displayText}
                            itemKey={tab.tabName}
                            id={tab.tabName}
                            itemProp={tab.requiresValue.toString()}
                            onRenderItemLink={customPivotItemRender}
                        >
                            {this.viewModel?.fields
                                .filter((field) => field.tabPageName === tab.tabName)
                                .map((field, index) => (
                                    <div key={'fld_div' + index} style={{ margin: '16px 0px' }}>
                                        <MetadataField
                                            field={field}
                                            onChange={(name, value) => {
                                                updateMetadata(name, value);
                                                setSelectedTab(tab.tabName);
                                            }}
                                            classificationDialogUiSettings={appSettings?.MetadataSelectionUi}
                                            onlineAddinSettings={appSettings?.OnlineAddinSettings}
                                        />
                                    </div>
                                ))}
                        </PivotItem>
                    ))}
                </Pivot>
            );
        }
        componentDidMount() {
            //monitor and update the required status of items hidden in the tab overflow.
            this.overflowObserver = new MutationObserver((mutationsList) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'data-is-overflowing') {
                        setOverflowRequiresAttention(this.overflowTabRequriesValue);
                    }
                }
            });
            const pivotElement = document.getElementById('schemaPivot');
            if (pivotElement) {
                this.overflowObserver.observe(pivotElement, { attributes: true, subtree: true });
            }
        }
        componentWillUnmount() {
            if (this.overflowObserver) {
                this.overflowObserver.disconnect();
            }
        }

        overflowTabRequriesValue() {
            const tabArray = Array.from(uniqueTabs);
            const pivotItems = Array.from(document.querySelectorAll('.ms-Pivot-link'));
            for (const tab of tabArray) {
                if (tab.requiresValue) {
                    const pivot = pivotItems.find((p) => p.getAttribute('name') === tab.displayText);
                    if (pivot?.getAttribute('data-is-overflowing') === 'true') return true;
                }
            }
            return false;
        }
        customOverflowButton: IComponentAs<IButtonProps> = (buttonProps) => {
            const { onClick, ...rest } = buttonProps;
            return (
                <IconButton
                    onClick={onClick}
                    {...rest}
                    styles={{
                        root: {
                            display: 'flex',
                            alignItems: 'center',
                        },
                    }}
                    data-testid="pivot-overflow-button"
                >
                    {overflowRequiresAttention && (
                        <span style={{ flex: '1 1 100%' }}>
                            <span style={{ flex: 'shrink', color: 'red', position: 'absolute', right: 0, top: 0 }}>
                                *
                            </span>
                        </span>
                    )}
                </IconButton>
            );
        };
    }

    const SelectClassificationComponent = ({
        classificationPaneViewModel,
    }: {
        classificationPaneViewModel: ClassificationPaneViewModel;
    }) => {
        //tab UI
        if (uniqueTabs.size >= 1) {
            return <SelectClassificationTabsComponent viewModel={classificationPaneViewModel} />;
        }
        //standard UI
        return (
            <div>
                {classificationPaneViewModel.fields.map((field, index) => (
                    <div key={'fld_div' + index} style={{ margin: '16px 0px' }}>
                        <MetadataField
                            field={field}
                            onChange={updateMetadata}
                            classificationDialogUiSettings={appSettings?.MetadataSelectionUi}
                            onlineAddinSettings={appSettings?.OnlineAddinSettings}
                        />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div style={{ margin: '0px 8px' }}>
            {error && <ApplicationMessage message={error} type={ERROR} onDismiss={clearError} />}
            {processing && (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        top: '0',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        backgroundColor: 'rgba(255,255,255, 0.5)',
                        zIndex: 2,
                        position: 'fixed',
                    }}
                >
                    <div
                        style={{
                            margin: '0',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <ProcessingIndicator message={processingMessage || ''} />
                    </div>
                </div>
            )}
            <PaneTitle
                title={appSettings?.MetadataSelectionUi?.DialogTitle || 'Classification'}
                themeColor={themeColor}
            />
            {classificationPaneViewModel && (
                <div
                    className={mergeStyles(AnimationStyles.scaleUpIn100, {
                        position: 'absolute',
                        overflow: 'auto',
                        width: '97%',
                        top: 38,
                        bottom: 48,
                    })}
                >
                    <Preamble
                        appSettings={appSettings}
                        mode={mode}
                        suggestPreamble={suggestPreamble}
                        schemaFieldsExist={classificationPaneViewModel.fields.length > 0}
                    />
                    <div style={{ margin: '16px 8px' }}>
                        <SelectClassificationComponent classificationPaneViewModel={classificationPaneViewModel} />
                    </div>
                    {hasForcedSelection && <RequiredSelectionWarning appSettings={appSettings} />}
                </div>
            )}
            {classificationPaneViewModel && (
                <div
                    className={mergeStyles(AnimationStyles.scaleUpIn100, {
                        position: 'fixed',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    })}
                >
                    <CommandBar
                        onPaneOk={() => applyMetadata(metadata)}
                        onDialogOk={() => completeDialog('DialogOkIdCached', metadata)}
                        onDialogCancel={() => completeDialog('DialogCancel', metadata)}
                        appSettings={appSettings || ({} as ApplicationSettings)}
                        okEnabled={classificationPaneViewModel.isValid && !processing}
                        mode={mode}
                    />
                </div>
            )}
        </div>
    );
};

function customPivotItemRender(
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
): JSX.Element | null {
    if (!link || !defaultRenderer) {
        return null;
    }
    if (link.itemProp === 'true') {
        return (
            <span style={{ flex: '0 1 100%' }}>
                {defaultRenderer({ ...link, itemIcon: undefined })}
                <span style={{ flex: 'shrink', color: 'red' }}> *</span>
            </span>
        );
    }
    return <span style={{ flex: '0 1 100%' }}> {defaultRenderer({ ...link })}</span>;
}

const truncatePivotText = (text: string) => {
    const headerTextLimit = 16;
    if (text.length >= headerTextLimit) {
        return `${text.substring(0, headerTextLimit - 1)}\u2026`;
    }
    return text;
};

const mapStateToProps = (state: AppState): SelectClassificationProps => ({
    processing: state.selectClassificationReducer.processing,
    processingMessage: state.selectClassificationReducer.processingMessage,
    classificationPaneViewModel: state.selectClassificationReducer.classificationPaneViewModel,
    error: state.selectClassificationReducer.error,
    appSettings: state.selectClassificationReducer.applicationSettings,
    suggestPreamble: state.selectClassificationReducer.suggestPreamble,
    themeColor: state.selectClassificationReducer.themeColor,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, undefined, AnyAction>) => ({
    selectClassificationLoad: (mode: SELECT_CLASSIFICATION_MODE) => dispatch(selectClassificationLoad(mode)),
    clearError: () => dispatch(clearError()),
    updateViewModel: (changedFieldName: string, changedFieldValue: string | string[]) =>
        dispatch(selectClassificationUpdateViewmodel(changedFieldName, changedFieldValue)),
    applyMetadata: (metadata: MetadataObject) => dispatch(applyMetadataAction(metadata)),
    completeDialog: (message: DialogMessages, metadata: MetadataObject) =>
        dispatch(completeDialogAction(message, metadata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectClassification);
