import ClassificationPaneViewModel from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/ClassificationPaneViewModel';
import ClassificationPresenter from 'titus-ts/dist/js/MetadataRenderer/MetadataPresenter/ClassificationPresenter';
import { MetadataObject } from 'titus-ts/dist/js/Util/Metadata/MetadataObject';

export class RenderingService {
    private presenter: ClassificationPresenter;

    public constructor(presenter: ClassificationPresenter) {
        this.presenter = presenter;
    }

    public getRenderingData = async (currentMetadata: MetadataObject): Promise<ClassificationPaneViewModel> => {
        const view = this.presenter.getView(currentMetadata);
        return Promise.resolve(view);
    };

    public getCurrentRenderingData = async (): Promise<ClassificationPaneViewModel> => {
        const view = this.presenter.getCurrentView();
        return Promise.resolve(view);
    };

    public getOriginalRenderingData = async (): Promise<ClassificationPaneViewModel> => {
        const view = this.presenter.getOriginalView();
        return Promise.resolve(view);
    };

    public transitionRendering = async (
        changedFieldName: string,
        changedFieldValue: string | string[],
    ): Promise<ClassificationPaneViewModel> => {
        const view = this.presenter.transitionView(changedFieldName, changedFieldValue);
        return Promise.resolve(view);
    };
}

export const extractMetadata = (viewModel: ClassificationPaneViewModel | null) => {
    const metadata: MetadataObject = {};
    if (viewModel) {
        viewModel.fields.forEach((f) => {
            if (f.selectedValue && f.selectedValue.length > 0) {
                metadata[f.name] = f.selectedValue;
            }
        });
    }

    return metadata;
};
