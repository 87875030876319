import MetadataConverter from 'titus-ts/dist/js/Util/Metadata/Converters';
import { MetadataObject } from 'titus-ts/dist/js/Util/Metadata/MetadataObject';
import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import { OfficeApi } from '../office/OfficeApi';
import MetadataService from './metadataService';

export const TITUS_3X_HEADER = 'x-tituslabs-classifications-30';
export const TITUS_4X_HEADER = 'x-titus-metadata-40';

export class OutlookMetadataService implements MetadataService {
    private officeApi: OfficeApi;

    public constructor(officeApi: OfficeApi) {
        this.officeApi = officeApi;
    }

    public getCurrentMetadata = async (fallback?: boolean): Promise<MetadataObject | undefined> => {
        let metadata: MetadataObject | undefined;
        const emailHeaders = await this.officeApi.getInternetHeadersAsync([TITUS_3X_HEADER, TITUS_4X_HEADER]);
        if (TITUS_3X_HEADER in emailHeaders || TITUS_4X_HEADER in emailHeaders) {
            metadata = this.convertHeadersToMetadata(emailHeaders);
        } else if (fallback) {
            metadata = await this.getOriginalMetadata();
        }

        return metadata;
    };

    public getOriginalMetadata = async (): Promise<MetadataObject | undefined> => {
        let emailHeaders: DictionaryInterface<string> = {};
        const conversationId = this.officeApi.getConversationId();
        if (conversationId) {
            emailHeaders = await this.officeApi.getInternetHeadersFromConversationAsync(conversationId, [
                TITUS_3X_HEADER,
                TITUS_4X_HEADER,
            ]);
        }
        const metadata = this.convertHeadersToMetadata(emailHeaders);

        return metadata;
    };

    public writeMetadata = async (metadata: MetadataObject): Promise<void> => {
        const header = MetadataConverter.get3xHeaders(metadata);
        await this.officeApi.setInternetHeadersAsync({ [TITUS_3X_HEADER]: header });
    };

    private convertHeadersToMetadata = (emailHeaders: DictionaryInterface<string>): MetadataObject | undefined => {
        let metadata: MetadataObject | undefined;
        if (emailHeaders && (TITUS_3X_HEADER in emailHeaders || TITUS_4X_HEADER in emailHeaders)) {
            if (emailHeaders[TITUS_3X_HEADER]) {
                metadata = MetadataConverter.getMetadataFrom3xHeaders(emailHeaders[TITUS_3X_HEADER]);
            } else {
                const decodedTitus4Header = atob(emailHeaders[TITUS_4X_HEADER]);
                metadata = MetadataConverter.get4xMetadata(decodedTitus4Header);
            }
        }

        return metadata;
    };
}
