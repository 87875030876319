import DialogService from './dialogService';
import { AppSettingsService } from './appSettingsService';
import { ALERT_MODE } from '../store/alerts/types';
import { TimeOutService } from './timeOutService';

export class FailOpenCloseService {
    private timeOutService: TimeOutService;
    private dialogService: DialogService;
    private appSettingsService: AppSettingsService | undefined;

    constructor(timeOutService: TimeOutService, dialogService: DialogService, appSettingsService?: AppSettingsService) {
        this.timeOutService = timeOutService;
        this.dialogService = dialogService;
        this.appSettingsService = appSettingsService;
    }

    public triggerFailOpenClose = async (): Promise<boolean> => {
        let result = true;
        const failOpenValue = await this.getFailOpenValue();
        result = await this.getDialogResponse(failOpenValue);
        return result;
    };

    private getFailOpenValue = async (): Promise<boolean> => {
        let result = true;
        try {
            if (this.appSettingsService) {
                const appSettings = await this.appSettingsService.getApplicationSettings();

                if (appSettings.TmcAddinSettings && appSettings.TmcAddinSettings.FailOpen != null) {
                    result = appSettings.TmcAddinSettings.FailOpen;
                }
            }
        } finally {
            return result;
        }
    };

    private getDialogResponse = async (failOpenValue: boolean): Promise<boolean> => {
        return new Promise<boolean>(async (resolve, reject) => {
            let result = failOpenValue;
            try {
                const mode: ALERT_MODE = result ? 'WARN' : 'PREVENT';
                this.timeOutService.subscribeTimeout(reject);
                const dialogResponse = await this.dialogService.showFailOpenCloseAlertsDialog(mode);

                switch (dialogResponse) {
                    case 'DialogOkIdCached':
                    case 'DialogOk':
                        result = true;
                        break;
                    case 'DialogCancel':
                        result = false;
                        break;
                }

                resolve(result);
            } catch (err) {
                reject(err);
            } finally {
                this.timeOutService.cancelTimeout();
            }
        });
    };
}
