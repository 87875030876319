import {
    Environment,
    Context,
    DocumentPolicyRequirementProps,
    OutlookPolicyRequirementProps,
} from './PolicyRequirementsTypes';
import { MetadataObjectInterface } from 'titus-ts/dist/js/Common/MetadataSystem/MetadataObjectInterface';
import DateTime from 'titus-ts/dist/js/Common/Util/DateTime';
import { MetadataObject } from 'titus-ts/dist/js/Util/Metadata/MetadataObject';
import PolicyRequirementsProvider from 'titus-ts/dist/js/Util/Network/PolicyServices/PolicyRequirementsProvider';
import { PolicyServiceApi } from 'titus-ts/dist/js/Util/Network/PolicyServices/PolicyServiceApi';
import PolicyRequirements from 'titus-ts/dist/js/Util/Network/PolicyServices/PolicyRequirements';
import { EventType } from 'titus-ts/dist/js/Util/Policy/EventType';
import { EmailUser } from 'titus-ts/dist/js/Mail/Recipient';
import AppService from '../../services/appService';
import { OfficeApi } from '../OfficeApi';
import SchemaProvider from 'titus-ts/dist/js/MetadataRenderer/Schema/SchemaProvider';
import PolicyRequirementFilter from './PolicyRequirementFilter';

export default abstract class OfficePolicyRequirementsProvider implements PolicyRequirementsProvider {
    protected officeApi: OfficeApi;
    protected schemaProvider: SchemaProvider;
    protected policyServiceAPI: PolicyServiceApi;
    protected productVersion = 'V1';
    private policyRequirements!: PolicyRequirements;

    public constructor(officeApi: OfficeApi, schemaProvider: SchemaProvider, policyServiceAPI: PolicyServiceApi) {
        this.officeApi = officeApi;
        this.schemaProvider = schemaProvider;
        this.policyServiceAPI = policyServiceAPI;
    }

    abstract getRequiredPropertiesJson: (eventType: EventType) => Promise<string>;

    protected getContextProperties = (
        eventType: EventType,
        appService: AppService,
        officeApi: OfficeApi,
        productVersion: string,
        sender?: EmailUser | undefined,
    ): Context => {
        let displayName: string | undefined;
        let domain: string | undefined;
        if (sender) {
            displayName = sender.DisplayName;
            domain = sender.Domain;
        } else {
            displayName = '';
            domain = '';
        }
        const context: Context = {
            Event: eventType,
            Product: appService.getProductName(),
            Version: productVersion,
            Platform: officeApi.getPlatform(),
            Username: displayName,
            UserDomainName: domain,
        };
        return context;
    };

    protected getEnviromentProperties = (sender?: EmailUser | undefined): Environment => {
        let displayName: string | undefined;
        let domain: string | undefined;
        if (sender) {
            displayName = sender.DisplayName;
            domain = sender.Domain;
        } else {
            displayName = '';
            domain = '';
        }
        const environment: Environment = {
            MachineName: '',
            OSVersion: navigator.platform,
            Date: DateTime.getEnvironmentDate(),
            Time: DateTime.getEnvironmentTime(),
            Now: DateTime.getEnvironmentNow(),
            Nowutc: DateTime.getEnvironmentNowutc(),
            Username: displayName,
            UserDomainName: domain,
        };
        return environment;
    };

    protected mapMetadata = (metadata: MetadataObject): MetadataObjectInterface | undefined => {
        if (Object.keys(metadata).length === 0) {
            return undefined;
        }
        const metadataResult: MetadataObjectInterface = {};
        for (const key in metadata) {
            const metadataValue = metadata[key];
            if (typeof metadataValue === 'string') {
                metadataResult[key] = {
                    $vals: [metadataValue],
                };
            } else if (metadataValue instanceof Array) {
                metadataResult[key] = {
                    $vals: metadataValue,
                };
            }
        }
        return metadataResult;
    };

    protected loadPolicyRequirements = async (policyServiceAPI: PolicyServiceApi): Promise<void> => {
        if (!this.policyRequirements) {
            this.policyRequirements = await policyServiceAPI.getPolicyRequirements();
        }
    };

    protected createPolicyEventRequiredFieldsJsonString = (
        actionName: EventType,
        requiredProperties: DocumentPolicyRequirementProps | OutlookPolicyRequirementProps,
    ): string => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let createdFields: any = {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const event: any = this.policyRequirements.events;
        if (event && event[actionName]) {
            const requiredPropertiesFilter = new PolicyRequirementFilter(event[actionName]);
            createdFields = requiredPropertiesFilter.filterObject(requiredProperties);
        }
        return JSON.stringify(createdFields);
    };
}
