import React from 'react';
import FieldNameShimmer from './FieldNameShimmer';
import FieldValueShimmer from './FieldValueShimmer';
import { ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react/lib/Shimmer';

type FieldShimmerProps = {
    fieldCount: number;
};

const FieldShimmer = ({ fieldCount }: FieldShimmerProps) => {
    const getFieldShimmers = (fieldCount: number) => {
        const shimmers: JSX.Element[] = [];
        for (let index = 0; index < fieldCount; index++) {
            shimmers.push(<FieldNameShimmer key={'fns' + index} />);
            shimmers.push(
                <ShimmerElementsGroup
                    key={'fnvs' + index}
                    shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 8 }]}
                />,
            );
            shimmers.push(<FieldValueShimmer key={'fvs' + index} />);
            shimmers.push(
                <ShimmerElementsGroup
                    key={'fsp' + index}
                    shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 32 }]}
                />,
            );
        }

        return shimmers;
    };
    return <div>{getFieldShimmers(fieldCount)}</div>;
};

export default FieldShimmer;
