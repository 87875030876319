import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import BuiltInProperties from 'titus-ts/dist/js/Document/BuiltInProperties';
import { AddinApi } from './AddinApi';

export class PowerPointApi extends AddinApi {
    readonly headerShapeName = 'TitusHeader';
    readonly footerShapeName = 'TitusFooter';

    public getBodyAsTextAsync = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            PowerPoint.run(async (context) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const data: string[] = [];
                const slides = context.presentation.slides;
                context.load(slides);
                await context.sync();

                if (!slides.items) reject('Presentation items can not be null or undefined');
                else {
                    for (const slide of slides.items) {
                        const shapes = slide.shapes;
                        shapes.load('items/textFrame/textRange/text');
                        await context.sync();

                        for (const shape of shapes.items) {
                            data.push(shape.textFrame.textRange.text);
                        }
                    }

                    resolve(data.join(' '));
                }
            });
        });
    };

    public getCustomProperties = (
        requestedCustomPropertiesNames: string[],
    ): Promise<DictionaryInterface<string | string[]>> => {
        return new Promise<DictionaryInterface<string | string[]>>((resolve, reject) => {
            PowerPoint.run(async (context) => {
                const tags = context.presentation.tags;
                const selectedTags: DictionaryInterface<string | string[]> = {};

                context.load(tags);
                await context.sync();

                if (!tags) reject('PowerPoint get Custom properties failed');
                else {
                    for (const item of tags.items) {
                        // PowerPoint uses upercase names for tags so convert both to upperCase
                        const tagName = item.key.toUpperCase();
                        const foundRequestedPropName = requestedCustomPropertiesNames.find(
                            (requestedPropName) => requestedPropName.toUpperCase() == tagName,
                        );

                        if (foundRequestedPropName) {
                            selectedTags[foundRequestedPropName] = item.value.split(';');
                        }
                    }
                }

                resolve(selectedTags);
            });
        });
    };

    public setCustomProperties(
        selectedCustomProperties: DictionaryInterface<string | string[]>,
        associatedCustomPropertiesNames: string[],
    ): Promise<void> {
        return new Promise<void>((resolve) => {
            PowerPoint.run(async (context) => {
                const propertyNames = Object.getOwnPropertyNames(selectedCustomProperties);
                const tags = context.presentation.tags;

                context.load(tags);
                await context.sync();

                for (const associatedCustomPropertiesName of associatedCustomPropertiesNames) {
                    tags.delete(associatedCustomPropertiesName);
                }
                await context.sync();

                for (const propertyName of propertyNames) {
                    const propValue = selectedCustomProperties[propertyName];
                    const valueString = typeof propValue == 'string' ? propValue : propValue.join(';');
                    context.presentation.tags.add(propertyName, valueString);
                }

                await context.sync();
                resolve();
            });
        });
    }

    // not supported
    public getProperties = (): Promise<BuiltInProperties> => {
        return new Promise<BuiltInProperties>((resolve) => {
            // reject('Not supported');
            const docProperties: BuiltInProperties = new BuiltInProperties();
            resolve(docProperties);
        });
    };

    public getHeaderContent = (): Promise<string> => {
        return new Promise((resolve) => {
            PowerPoint.run(async (context) => {
                const headers: string[] = [];

                // load slides collection so we can iterate through
                const presentation = context.presentation;
                presentation.load('slides');
                await presentation.context.sync();

                for (const slide of presentation.slides.items) {
                    headers.push(await this.getTextFromShapeByName(slide.slideMaster, this.headerShapeName));
                }

                resolve(headers[0]);
            });
        });
    };

    public getFooterContent = (): Promise<string> => {
        return new Promise((resolve) => {
            PowerPoint.run(async (context) => {
                const footers: string[] = [];

                // load slides collection so we can iterate through
                const presentation = context.presentation;
                presentation.load('slides');
                await presentation.context.sync();

                for (const slide of presentation.slides.items) {
                    footers.push(await this.getTextFromShapeByName(slide.slideMaster, this.footerShapeName));
                }

                resolve(footers[0]);
            });
        });
    };

    public setHeaderAndFooterAsync = async (location: 'header' | 'footer', headerFooterText: string): Promise<void> => {
        return new Promise((resolve) => {
            PowerPoint.run(async (context) => {
                const headerFooterName = location == 'header' ? this.headerShapeName : this.footerShapeName;
                const plainHeaderFooterText = this.getPlainTextFromHtml(headerFooterText);
                let slideHeight = 0;
                let slideWidth = 0;
                const processedSlideMasters = [] as string[];

                // load slides collection so we can iterate through
                const presentation = context.presentation;
                presentation.load('slides');
                await presentation.context.sync();

                for (const slide of presentation.slides.items) {
                    const slideMaster = slide.slideMaster;
                    slideMaster.load('id');
                    await slideMaster.context.sync();
                    const slideMasterId = slideMaster.id;
                    if (processedSlideMasters.includes(slideMasterId)) {
                        continue;
                    }

                    const headerFooterShape = await this.getShapeByName(slideMaster, headerFooterName);

                    if (headerFooterShape) {
                        // we have a header footer so check if its the same, if not update it
                        const currentHeaderFooterText = await this.getTextFromShape(headerFooterShape);

                        if (currentHeaderFooterText != plainHeaderFooterText) {
                            headerFooterShape.textFrame.textRange.text = plainHeaderFooterText;
                            await headerFooterShape.context.sync();
                        }
                    } else {
                        // we dont have a header/footer here so add one
                        const headerFooterShape = slideMaster.shapes.addTextBox(plainHeaderFooterText);

                        if (!slideHeight || !slideWidth) {
                            await slideMaster.context.sync();

                            // WORKAROUND the office JS API does not expose a slides height or width.
                            // WORKAROUND by default textbox inserts centered on the form so with its position and size we can work out the slides height and width and move it as needed
                            headerFooterShape.load(['left', 'top', 'width', 'height']);

                            await headerFooterShape.context.sync();

                            slideWidth = (headerFooterShape.left + headerFooterShape.width / 2) * 2;
                            slideHeight = (headerFooterShape.top + headerFooterShape.height / 2) * 2;
                        }

                        headerFooterShape.name = headerFooterName;
                        headerFooterShape.textFrame.verticalAlignment = 'MiddleCentered';
                        headerFooterShape.textFrame.textRange.paragraphFormat.horizontalAlignment = 'Center';
                        headerFooterShape.left = 0;
                        headerFooterShape.width = slideWidth;
                        headerFooterShape.top = location == 'header' ? 0 : slideHeight - 70;
                        headerFooterShape.height = 70;

                        await headerFooterShape.context.sync();
                    }

                    processedSlideMasters.push(slideMasterId);
                }

                resolve();
            });
        });
    };

    public getPlatform = (): string => {
        const platformType = this.getPlatformType();
        let platform: string;
        switch (platformType) {
            case 'Android':
                platform = 'PowerPointAndroid';
                break;
            case 'iOS':
            case 'Mac':
                platform = 'PowerPointIOS';
                break;
            case 'OfficeOnline':
                platform = 'PowerPointWebApp';
                break;
            default:
                platform = 'PowerPoint';
                break;
        }
        return platform;
    };

    private async getShapeByName(
        slide: PowerPoint.Slide | PowerPoint.SlideMaster | PowerPoint.SlideLayout,
        shapeName: string,
    ): Promise<PowerPoint.Shape | undefined> {
        try {
            // load the shapes collection so we can iterate through
            slide.load('shapes');
            await slide.context.sync();

            const shapes = slide.shapes;
            shapes.load('items/name');
            // load the name property of each shape

            for (const shape of shapes.items) {
                if (shape.name == shapeName) {
                    return shape;
                }
            }
        } catch {
            console.log('error loading shapes in slide');
        }

        return undefined;
    }

    private async getTextFromShape(shape: PowerPoint.Shape): Promise<string> {
        try {
            shape.load('textFrame/textRange/text');
            await shape.context.sync();

            return shape.textFrame.textRange.text;
        } catch {
            console.log('Error retrieving text from shape');
        }

        return '';
    }

    private async getTextFromShapeByName(
        slide: PowerPoint.Slide | PowerPoint.SlideMaster | PowerPoint.SlideLayout,
        shapeName: string,
    ): Promise<string> {
        const namedShape = await this.getShapeByName(slide, shapeName);

        return namedShape ? await this.getTextFromShape(namedShape) : '';
    }

    private getPlainTextFromHtml(html: string): string {
        const parser = new DOMParser();

        // Parse the HTML string into a DOM Document
        const doc = parser.parseFromString(html, 'text/html');

        return doc.body?.textContent || '';
    }
}
