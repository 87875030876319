import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

type ProcessingIndicatorProps = {
    message: string;
};

const ProcessingIndicator = (props: ProcessingIndicatorProps) => {
    const { message } = props;

    return (
        <div>
            <Spinner label={message} size={SpinnerSize.large} />
        </div>
    );
};

export default ProcessingIndicator;
