import React from 'react';
import { ApplicationSettings } from 'titus-ts/dist/js/Settings/ApplicationSettings';
import { SELECT_CLASSIFICATION_MODE } from '../../../store/select-classification/types';
import PaneCommandBar from './PaneCommandBar';
import DialogCommandBar from './DialogCommandBar';

type CommandBarProps = {
    onPaneOk: () => void;
    onDialogOk: () => void;
    onDialogCancel: () => void;
    appSettings: ApplicationSettings;
    okEnabled: boolean;
    mode: SELECT_CLASSIFICATION_MODE;
};

const CommandBar = (props: CommandBarProps) => {
    const { onPaneOk, onDialogOk, onDialogCancel, appSettings, okEnabled, mode } = props;

    const applyMetadataTextSetting = appSettings?.ClassificationTaskPane?.MetadataApplyButtonText;
    const applyMetadataTooltipSetting = appSettings?.ClassificationTaskPane?.MetadataApplyButtonTooltipText;

    return (
        <div style={{ margin: '8px 16px' }}>
            {!mode && (
                <PaneCommandBar
                    onOk={onPaneOk}
                    okText={applyMetadataTextSetting ? applyMetadataTextSetting : 'Apply'}
                    okTooltip={applyMetadataTooltipSetting ? applyMetadataTooltipSetting : undefined}
                    okEnabled={okEnabled}
                />
            )}
            {mode && (
                <DialogCommandBar
                    onOk={onDialogOk}
                    onCancel={onDialogCancel}
                    appSettings={appSettings}
                    okEnabled={okEnabled}
                />
            )}
        </div>
    );
};

export default CommandBar;
