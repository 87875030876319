import { OutlookApi } from './OutlookApi';
import { AttachmentOffice } from './AttachmentOffice';
import { EwsRequestGenerator } from './EwsRequestGenerator';
import { DictionaryInterface } from 'titus-ts/dist/js/Common/Util/DictionaryInterface';
import { Recipient, EmailUser } from 'titus-ts/dist/js/Mail/Recipient';
import { PlatformType } from './PlatformType';
import { WordApi } from './WordApi';
import { ExcelApi } from './ExcelApi';
import { AddinApi } from './AddinApi';
import { PowerPointApi } from './PowerPointApi';
import { MetadataObject } from 'titus-ts/dist/js/Util/Metadata/MetadataObject';
import { HostType } from './HostType';
import BuiltInProperties from 'titus-ts/dist/js/Office/BuiltInProperties';
import DocumentTypeEnum from 'titus-ts/dist/js/Office/DocumentTypeEnum';

export class OfficeApi {
    private outlookApi: OutlookApi;
    private ewsApi: EwsRequestGenerator;
    private itemId: string | undefined;
    private documentApi: WordApi | ExcelApi | PowerPointApi | undefined;
    private wordApi: WordApi;
    private excelApi: ExcelApi;
    private powerPointApi: PowerPointApi;
    private addinApi: AddinApi;
    private host: HostType | undefined;

    constructor(
        outlookApi?: OutlookApi,
        ewsApi?: EwsRequestGenerator,
        wordApi?: WordApi,
        excelApi?: ExcelApi,
        powerPointApi?: PowerPointApi,
        addinApi?: AddinApi,
    ) {
        this.ewsApi = ewsApi ? ewsApi : new EwsRequestGenerator();
        this.outlookApi = outlookApi ? outlookApi : new OutlookApi();
        this.excelApi = excelApi ? excelApi : new ExcelApi();
        this.wordApi = wordApi ? wordApi : new WordApi();
        this.powerPointApi = powerPointApi ? powerPointApi : new PowerPointApi();
        this.addinApi = addinApi ? addinApi : new AddinApi();
    }

    public getHost = (): HostType => {
        if (!this.host) {
            this.host = this.addinApi.getHost();
        }
        return this.host;
    };

    public setInternetHeadersAsync = async (headers: DictionaryInterface<string>): Promise<void> => {
        // Due to error trying to set message headers via OfficeJs when Outlook popout dialog is open
        // we have to use EWS for now and later check if OfficeJs functionality is fixed.
        if (!this.itemId) {
            await this.saveAsync();
        }
        if (this.itemId) {
            await this.outlookApi.setInternetHeaders(headers);
        }
    };

    public getPrimayBackgroundColor = (): string => {
        return this.addinApi.getthemeColor();
    };

    public getInternetHeadersAsync = async (headersToRequest: string[]): Promise<DictionaryInterface<string>> => {
        // Office js at the moment allows reading headers only in compose mode
        // Use EWS until this is available in both read and compose modes
        this.itemId = this.outlookApi.getItemId();
        if (!this.itemId) {
            await this.saveAsync();
        }
        let result: DictionaryInterface<string> = {};
        if (this.itemId) {
            result = await this.ewsApi.getInternetHeaders(this.itemId, headersToRequest);
        }

        return result;
    };

    public getInternetHeadersFromConversationAsync = async (
        conversationId: string,
        headersToRequest: string[],
    ): Promise<DictionaryInterface<string>> => {
        if (!this.itemId) {
            await this.saveAsync();
        }

        //const conversationId = this.getConversationId();
        if (this.itemId) {
            const result = await this.ewsApi.getMessageHeadersFromConversation(
                conversationId,
                headersToRequest,
                this.itemId,
            );

            return result;
        }

        throw 'Error Retrieving Item Info';
    };

    public getChangeKeyAsync = async (itemId: string): Promise<string> => {
        const result = await this.ewsApi.getChangeKey(itemId);
        return result;
    };

    public getToAsync = async (): Promise<Recipient[]> => {
        const result = await this.outlookApi.getToAsync();
        return result;
    };

    public getCcAsync = async (): Promise<Recipient[]> => {
        const result = await this.outlookApi.getCcAsync();
        return result;
    };

    public getBccAsync = async (): Promise<Recipient[]> => {
        const result = await this.outlookApi.getBccAsync();
        return result;
    };

    public setToAsync = async (emails: string[]): Promise<void> => {
        await this.outlookApi.setToAsync(emails);
    };

    public setCcAsync = async (emails: string[]): Promise<void> => {
        await this.outlookApi.setCcAsync(emails);
    };

    public setBccAsync = async (emails: string[]): Promise<void> => {
        await this.outlookApi.setBccAsync(emails);
    };

    public addToAsync = async (emails: string[]): Promise<void> => {
        await this.outlookApi.addToAsync(emails);
    };

    public addCcAsync = async (emails: string[]): Promise<void> => {
        await this.outlookApi.addCcAsync(emails);
    };

    public addBccAsync = async (emails: string[]): Promise<void> => {
        await this.outlookApi.addBccAsync(emails);
    };

    public saveAsync = async (): Promise<string> => {
        let result = '';
        if (this.getHost() === 'Outlook') {
            result = await this.outlookApi.saveAsync();
            this.itemId = result;
        }
        return result;
    };

    public getConversationId = (): string => {
        return this.outlookApi.getConversationId();
    };

    public getItemId = (): string => {
        return this.outlookApi.getItemId();
    };

    public getPlatformType = (): PlatformType | undefined => {
        return this.addinApi.getPlatformType();
    };

    public getBodyAsTextAsync = async (): Promise<string> => {
        if (this.getHost() === 'Outlook') return await this.outlookApi.getBodyAsTextAsync();
        else {
            return (await this.getDocumentApi()?.getBodyAsTextAsync()) || '';
        }
    };

    public getBodyAsHtmlAsync = async (): Promise<string> => {
        return await this.outlookApi.getBodyAsHtmlAsync();
    };

    public getBodyTypeAsync = async (): Promise<string> => {
        return await this.outlookApi.getBodyTypeAsync();
    };

    public setBodyAsync = async (newBody: string, bodyType: string): Promise<void> => {
        await this.outlookApi.setBodyAsync(newBody, bodyType);
    };

    public getSubjectAsync = async (): Promise<string> => {
        return await this.outlookApi.getSubjectAsync();
    };

    public setSubjectAsync = async (newSubject: string): Promise<void> => {
        await this.outlookApi.setSubjectAsync(newSubject);
    };

    public getPlatform = (): string => {
        if (this.getHost() === 'Outlook') return this.outlookApi.getPlatform();
        else return this.getDocumentApi()?.getPlatform();
    };

    public getAttachments = async (): Promise<AttachmentOffice[]> => {
        let attachments: AttachmentOffice[] = [];

        if (!this.itemId) {
            await this.saveAsync();
        }
        if (this.itemId) {
            attachments = await this.ewsApi.getAttachmentsAsync(this.itemId);
        }
        return attachments;
    };

    public getSenderAddress = (): string => {
        return this.outlookApi.getSenderAddress();
    };

    public getSenderDisplayName = (): string => {
        return this.outlookApi.getSenderDisplayName();
    };

    public getOriginalSender = (): Promise<EmailUser> => {
        const conversationId = this.getConversationId();
        return this.ewsApi.getOriginalSender(conversationId);
    };

    public showDialogAsync = (url: string): Promise<Office.Dialog> => {
        return this.outlookApi.showDialogAsync(url);
    };

    public messageParent = (message: string): void => {
        this.outlookApi.messageParent(message);
    };

    public onItemChanged = (callback: () => void): void => {
        if (this.getHost() === 'Outlook') {
            this.outlookApi.onItemChanged(callback);
        }
    };

    public getProperties = async (): Promise<BuiltInProperties> => {
        return await this.getDocumentApi()?.getProperties();
    };

    public getCustomProperties = (requestedCustomPropertiesNames: string[]): Promise<MetadataObject> | undefined => {
        return this.getDocumentApi()?.getCustomProperties(requestedCustomPropertiesNames);
    };

    public setCustomProperties = async (
        selectedCustomProperties: DictionaryInterface<string | string[]>,
        associatedCustomPropertiesNames: string[],
    ): Promise<void> => {
        await this.getDocumentApi()?.setCustomProperties(selectedCustomProperties, associatedCustomPropertiesNames);
    };

    public getHeaderContent = (): Promise<string> => {
        return this.getDocumentApi()?.getHeaderContent();
    };

    public getFooterContent = (): Promise<string> => {
        return this.getDocumentApi()?.getFooterContent();
    };

    public setHeaderAndFooterAsync = async (
        location: 'header' | 'footer',
        headerFooterText: string,
        titusPrefix: string,
    ): Promise<void> => {
        if (this.getDocumentApi() === this.wordApi) {
            await this.wordApi.setHeaderAndFooterAsync(location, headerFooterText, 'start', false, 'All', titusPrefix);
        } else if (this.getDocumentApi() === this.excelApi) {
            await this.excelApi.setHeaderAndFooterAsync(location, headerFooterText);
        } else if (this.getDocumentApi() === this.powerPointApi) {
            await this.powerPointApi.setHeaderAndFooterAsync(location, headerFooterText);
        }
    };

    public isItemTypeSupported = (): boolean => {
        return this.outlookApi.isMailBoxItemTypeSupported();
    };

    public getDocumentType = (): DocumentTypeEnum => {
        return this.getDocumentApi()?.getDocumentType();
    };

    public getFileName = (): Promise<string> => {
        return this.getDocumentApi()?.getFileName();
    };

    public getFilePath = (): Promise<string> => {
        return this.getDocumentApi()?.getFilePath();
    };

    private getDocumentApi = (): WordApi | ExcelApi | PowerPointApi => {
        if (!this.documentApi) {
            if (!this.host) this.host = this.getHost();
            if (this.host === 'Word') {
                this.documentApi = this.wordApi;
            } else if (this.host === 'Excel') {
                this.documentApi = this.excelApi;
            } else if (this.host === 'PowerPoint') {
                this.documentApi = this.powerPointApi;
            }
        }
        return this.documentApi as WordApi | ExcelApi;
    };
}
